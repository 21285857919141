import SignupForm from "../../../features/auth/SignupForm";
import AuthEntryLayout from "../../../layouts/AuthEntryLayout";

const PatientSignup = () => {
  return (
    <AuthEntryLayout>
      <SignupForm type="patient" />
    </AuthEntryLayout>
  );
};

export default PatientSignup;
