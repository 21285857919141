import { Box, Flex, Spacer, Text } from "@chakra-ui/react";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import Notifications from "../features/notifications";
import { ReactComponent as CCLogo } from "../images/CannabisClinicLogo1.svg";
import { ReactComponent as Appointments } from "../images/appointments.svg";
import { ReactComponent as Dispensary } from "../images/cartIcon.svg";
import { ReactComponent as Home } from "../images/home.svg";
import { ReactComponent as Information } from "../images/information.svg";
import { ReactComponent as Orders } from "../images/orders.svg";
import { ReactComponent as Account } from "../images/profile-icon.svg";
import { ReactComponent as Scripts } from "../images/scripts.svg";

const patientTabs = [
  {
    Logo: Home,
    label: "Home",
    path: "/dashboard",
  },
  {
    Logo: Dispensary,
    label: "Dispensary",
    path: "/dispensary",
  },
  {
    Logo: Orders,
    label: "My Orders",
    path: "/orders",
  },
  {
    Logo: Appointments,
    label: "Appointments",
    path: "/appointments",
  },
  {
    Logo: Scripts,
    label: "My Scripts",
    path: "/scripts",
  },

  {
    Logo: Information,
    label: "Information",
    path: "/information",
  },
  {
    Logo: Account,
    label: "",
    path: "/account",
  },
];

const NavbarTab: FC<{
  isSelected: boolean;
  Logo: any;
  path: string;
  label: string;
}> = ({ isSelected, Logo, path, label }) => {
  const navigate = useNavigate();
  return (
    <Box
      onClick={() => navigate(`/patient${path}`)}
      cursor="pointer"
      color={isSelected ? "cc.primary.500" : "cc.greyScale.300"}
      fontWeight="500"
    >
      <Flex gap="3" wrap="nowrap">
        <Logo color={isSelected ? "cc.primary.500" : "#656674"} width="22px" />
        {label.length > 0 && (
          <Text display={{ base: "none", xl: "block" }}>{label}</Text>
        )}
      </Flex>
      {isSelected && (
        <Box position="relative">
          <Box
            left="50%"
            right="50%"
            bottom="-3"
            position="absolute"
            backgroundColor="cc.primary.500"
            w="2"
            h="2"
            rounded="full"
          />
        </Box>
      )}
    </Box>
  );
};

const Navbar = () => {
  const current = window.location.pathname;
  return (
    <>
      <Flex
        pt={{ base: 5, xl: 14 }}
        position={{ base: "fixed", xl: "initial" }}
        bottom="0"
        // gap={{ base: 8, xl: 14 }}
        px="5"
        pb={{ base: 5, xl: 10 }}
        backgroundColor={{ base: "white", xl: "transparent" }}
        zIndex="100"
        maxW="8xl"
        mx="auto"
        alignItems="center"
      >
        <Box display={{ base: "none", lg: "inherit" }} mb="3">
          <CCLogo width="160" height="40" />
        </Box>
        <Spacer />
        <Flex w="6xl">
          {/* Patient Tabs */}
          {current.includes("patient") &&
            patientTabs.map(({ Logo, path, label }, i) => {
              const isSelected = current.includes(path);
              return (
                <>
                  <NavbarTab
                    key={i}
                    isSelected={isSelected}
                    Logo={Logo}
                    path={path}
                    label={label}
                  />
                  <Spacer />
                </>
              );
            })}
        </Flex>
        <Box display={{ base: "none", xl: "block" }}>
          <Notifications iconColor="#656674" />
        </Box>
      </Flex>
    </>
  );
};

export default Navbar;
