import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { FC, Fragment } from "react";

const testAppointment: any = {
  _id: 1,
  acuityId: 1,
  amountPaid: "1",
  appointmentTypeID: 1,
  calendar: "1",
  calendarID: 1,
  canClientCancel: false,
  canClientReschedule: false,
  certificate: "1",
  classID: 1,
  confirmationPage: "1",
  confirmed: false,
  date: "1",
  dateCreated: "1",
  datetime: new Date(),
  duration: "1",
  email: "1",
  endTime: "1",
  firstName: "1",
  id: "1",
  lastName: "1",
  location: "1",
  notes: "1",
  paid: "1",
  phone: "1",
  price: "1",
  scheduledBy: "1",
  time: "1",
  timezone: "1",
  type: "1",
};

const ViewAppointments: FC<{
  type: "previous" | "upcoming";
  appointments: any[] | undefined;
}> = ({ type, appointments }) => {
  return (
    <Flex
      p="5"
      mt="4"
      borderWidth="1px"
      rounded="2xl"
      flexDir="column"
      gap="3"
      overflowY="auto"
      h="80"
    >
      {appointments?.length ? (
        appointments?.map((appointment, i) => {
          const date = new Date(appointment.date);
          return (
            <Fragment key={i}>
              <Box
                textAlign="left"
                backgroundColor={
                  type === "previous" ? "cc.lightScale.400" : "#F4FBFC"
                }
                p="5"
                px="6"
                rounded="2xl"
                color={type === "previous" ? "cc.greyScale.200" : "none"}
              >
                <Text fontSize="md" fontWeight="500">
                  Meeting with {appointment.calendar}
                </Text>
                <Box my="2">
                  <Text as="span">
                    {date.toLocaleString("en-nz", {
                      weekday: "long",
                    })}
                  </Text>{" "}
                  <Text as="span" my="1">{`${date.toLocaleString("en-nz", {
                    month: "long",
                  })} ${date.getDate()}`}</Text>
                  {" - "}
                  <Text as="span">{appointment.time}</Text>
                </Box>
                <Button
                  fontSize="sm"
                  my="1"
                  variant="primaryDarkOutline"
                  isDisabled={type === "previous" ? true : false}
                  p="5"
                  onClick={() => {
                    window.open(appointment.confirmationPage, "_blank");
                  }}
                >
                  View Appointment
                </Button>
              </Box>
            </Fragment>
          );
        })
      ) : (
        <Box textAlign="left" p="1" rounded="2xl" color="cc.greyScale.200">
          No {type.charAt(0).toUpperCase() + type.slice(1, type.length) + " "}
          appointments to display
        </Box>
      )}
    </Flex>
  );
};

export default ViewAppointments;
