import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { Box, Collapse, Flex, Text, useDisclosure } from "@chakra-ui/react";
import { FC, useEffect } from "react";

interface FilterOptionProps {
  filterOption: any;
  selectedFilterOption: any;
  filterOptionChildren?: any[];
  updateSelectedFilterOption: React.Dispatch<React.SetStateAction<any>>;
}

const FilterOption: FC<FilterOptionProps> = ({
  filterOption,
  selectedFilterOption,
  filterOptionChildren,
  updateSelectedFilterOption,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (!isOpen) updateSelectedFilterOption(undefined);
  }, [isOpen]);

  return (
    <Box w="full">
      <Flex
        alignItems="center"
        w="full"
        color="cc.primary.500"
        fontWeight={filterOptionChildren && isOpen ? "600" : "400"}
        cursor="pointer"
        onClick={() => {
          if (!filterOptionChildren) {
            return updateSelectedFilterOption(filterOption);
          }

          if (isOpen) {
            onClose();
          } else {
            onOpen();
          }
        }}
        mb="1"
        borderBottom={filterOptionChildren && isOpen ? "1px" : ""}
        borderColor="cc.greyScale.100"
      >
        <Text
          mb="1"
          fontWeight={filterOption === selectedFilterOption ? "600" : "400"}
        >
          {filterOption?.name ?? filterOption.title}
          {filterOption?.value && filterOption.value}
        </Text>
        {filterOptionChildren &&
          (isOpen ? (
            <ChevronUpIcon ml="auto" />
          ) : (
            <ChevronDownIcon ml="auto" />
          ))}
      </Flex>

      {filterOptionChildren && (
        <Box
          mx="5"
          mt={filterOptionChildren && isOpen ? 1 : ""}
          mb={filterOptionChildren && isOpen ? 5 : ""}
        >
          <Collapse in={isOpen}>
            {filterOptionChildren.map((filterOptionChild, i) => {
              return (
                <Box
                  key={i}
                  color={
                    filterOptionChild === selectedFilterOption
                      ? "cc.primary.500"
                      : "black"
                  }
                  cursor="pointer"
                  fontWeight={
                    filterOptionChild === selectedFilterOption ? "600" : "400"
                  }
                  onClick={() => updateSelectedFilterOption(filterOptionChild)}
                  py="1"
                >
                  {filterOptionChild.name}
                </Box>
              );
            })}
          </Collapse>
        </Box>
      )}
    </Box>
  );
};

export default FilterOption;
