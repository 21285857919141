import { Box, Button, Flex, useDisclosure } from "@chakra-ui/react";
import { FC, useState } from "react";
import {
  AddToCartInput,
  Maybe,
  MedusaProductCategory,
  MedusaProductVariant,
  useAddToCartMutation,
} from "../../../../../../generated/graphql";

import AddToCartPopup from "../../../cart/AddToCartPopup";
import ProductDiscountSelect from "./ProductDiscountSelect";
import QuantitySelect from "./QuantitySelect";
import SubscribeSelect from "./SubscribeSelect";
import VapeConsent from "./VapeConsent";
import VariantSelect from "./VariantSelect";

const ProductPurchaseOptions: FC<{
  bulkPurchase: Maybe<boolean> | undefined;
  bulk_discount_1: Maybe<number> | undefined;
  bulk_discount_2: Maybe<number> | undefined;
  variants: MedusaProductVariant[];
  categories: MedusaProductCategory[];
  selectedVariantIndex: number;
  updateSelectedVariantIndex: React.Dispatch<React.SetStateAction<number>>;
  subscriptionSelected: boolean;
  updateSubscriptionSelected: (value: boolean) => void;
  quantity: number;
  updateQuantity: (value: number) => void;
  initialCalculatedPrice: number;
}> = ({
  bulkPurchase,
  bulk_discount_1,
  bulk_discount_2,
  variants,
  categories,
  selectedVariantIndex,
  updateSelectedVariantIndex,
  subscriptionSelected,
  updateSubscriptionSelected,
  quantity,
  updateQuantity,
  initialCalculatedPrice,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isConsentValid, setIsConsentValid] = useState(true);

  const [addToCart] = useAddToCartMutation({
    onCompleted: () => {
      onOpen();
    },
    onError: (error: any) => {
      return alert(error.message);
    },
  });

  const onAddToCart = ({ lineItems }: AddToCartInput) =>
    addToCart({
      variables: {
        input: {
          lineItems,
        },
      },
    });

  return (
    <>
      <Flex direction="column" gap="5" my="5">
        {/* Only displays variant selection if product has multiple variants */}
        <VariantSelect
          variants={variants}
          selectedVariantIndex={selectedVariantIndex}
          updateSelectedVariantIndex={updateSelectedVariantIndex}
        />
        {/* Only displays vape consent form if useIsThc returns true */}
        <VapeConsent
          categories={categories}
          updateIsConsentValid={setIsConsentValid}
        />
        {/* Only displays bulk discount select if the product.bulk_discount option is true */}
        <ProductDiscountSelect
          bulkPurchase={bulkPurchase}
          bulk_discount_1={bulk_discount_1}
          bulk_discount_2={bulk_discount_2}
          variants={variants}
          selectedVariantIndex={selectedVariantIndex}
          quantity={quantity}
          updateQuantity={updateQuantity}
        />
        {/* Only displays subscribe and save if useIsThc returns false AND handle includes CBD */}
        <Box w={{ base: "100%", xl: "48.5%" }}>
          <SubscribeSelect
            categories={categories}
            initialCalculatedPrice={initialCalculatedPrice}
            subscriptionSelected={subscriptionSelected}
            updateSubscriptionSelected={updateSubscriptionSelected}
          />
        </Box>
      </Flex>
      <Flex alignItems="center" gap={{ base: 3, xl: 5 }}>
        <Box w="40">
          <QuantitySelect quantity={quantity} updateQuantity={updateQuantity} />
        </Box>
        <Button
          isDisabled={!isConsentValid}
          variant="primaryDark"
          py={{ base: "20px", xl: "22px" }}
          px="12"
          rounded="xl"
          onClick={() => {
            onAddToCart({
              lineItems: [
                {
                  variant_id: variants[selectedVariantIndex].id,
                  quantity: quantity,
                  subscribe: subscriptionSelected,
                },
              ],
            });
          }}
        >
          Add to cart
        </Button>
      </Flex>
      <AddToCartPopup isOpen={isOpen} onClose={onClose} />
    </>
  );
};

export default ProductPurchaseOptions;
