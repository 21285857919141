import { ArrowDownIcon, ArrowUpIcon } from "@chakra-ui/icons";
import {
  Box,
  Divider,
  Flex,
  Link,
  ListItem,
  Text,
  UnorderedList,
  useDisclosure,
} from "@chakra-ui/react";
import { Maybe } from "graphql/jsutils/Maybe";
import { FC } from "react";
import {
  MedusaImage,
  MedusaProduct,
} from "../../../../../../generated/graphql";

const Tab: FC<{
  title: string;
  content: Maybe<string> | undefined;
  links: (MedusaImage | undefined)[] | undefined;
}> = ({ title, content, links }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box w="full" ml="auto" textAlign="left">
      <Flex
        cursor="pointer"
        alignItems="center"
        onClick={() => (isOpen ? onClose() : onOpen())}
      >
        <Text
          fontSize={{ base: "md", lg: "lg", xl: "lg" }}
          color={isOpen ? "cc.primary.500" : "cc.greyScale.200"}
        >
          {title}
        </Text>
        <Box ml="auto">
          {isOpen ? (
            <ArrowUpIcon boxSize="6" color="cc.greyScale.100" />
          ) : (
            <ArrowDownIcon boxSize="6" color="cc.greyScale.100" />
          )}
        </Box>
      </Flex>
      <Divider my="3" borderWidth="1px" />
      {isOpen &&
        (content?.includes(",") ? (
          <Box mb="7">
            <UnorderedList mt="5">
              {content.split(",")?.map((content: string, i: number) => {
                if (content.length)
                  return (
                    <ListItem my="3" key={i}>
                      {content}
                    </ListItem>
                  );
              })}
            </UnorderedList>
            <Box my="2">
              {links?.map((link, i) => {
                return (
                  <Box my="1">
                    <Link
                      key={i}
                      href={link?.url}
                      color="cc.primary.500"
                      textDecoration="underline"
                      target="_blank"
                    >
                      {link?.metadata.name ?? link?.url}
                    </Link>
                  </Box>
                );
              })}
            </Box>
          </Box>
        ) : (
          <Text mb="7">
            {content}
            <Box my="2">
              {links?.map((link, i) => {
                return (
                  <Box my="1">
                    <Link
                      key={i}
                      href={link?.url}
                      color="cc.primary.500"
                      textDecoration="underline"
                      target="_blank"
                    >
                      {link?.metadata.name ?? link?.url}
                    </Link>
                  </Box>
                );
              })}
            </Box>
          </Text>
        ))}
    </Box>
  );
};

const ProductInfo: FC<{ product: MedusaProduct }> = ({ product }) => {
  const links = product?.images?.map((file, i) => {
    if (file.metadata.type) return file;
  });

  const tabs = [
    {
      title: "Product Details",
      content: product?.product_details,
    },

    {
      title: "How to use",
      content: product?.directions,
    },
    {
      title: "Disclaimer",
      content: product?.disclaimer,
    },
    {
      title: "Product Resources",
      content: "Click the resources below to view more",
      links: links,
    },
  ];

  return (
    <Flex flexDir="column" gap="1" w="full" ml="auto" py="5" mt="3">
      {tabs.map((tab, i) => {
        return (
          <Tab
            key={i}
            title={tab.title}
            content={tab.content}
            links={tab.links}
          />
        );
      })}
    </Flex>
  );
};

export default ProductInfo;
