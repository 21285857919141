import { Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import LoadingAnimation from "../../../components/LoadingAnimation";
import DisplayPrescriptions from "../../../features/patients/prescriptions/DisplayPrescriptions";
import ReviewScriptSplashPage from "../../../features/patients/prescriptions/ReviewScriptSplashPage";
import { usePrescriptionsQuery } from "../../../generated/graphql";
import MainLayout from "../../../layouts/MainLayout";

const Scripts = () => {
  const { data, loading } = usePrescriptionsQuery();
  const [prescriptionToOrder, setPrescriptionToOrder] = useState<any>();

  useEffect(() => {
    if (!prescriptionToOrder) return;
  }, [prescriptionToOrder]);

  if (loading)
    return (
      <MainLayout>
        <LoadingAnimation />
      </MainLayout>
    );

  if (prescriptionToOrder)
    return <ReviewScriptSplashPage prescriptionToOrder={prescriptionToOrder} />;

  return (
    <MainLayout>
      <Text as="h1" textAlign="left">
        My Scripts
      </Text>
      <DisplayPrescriptions
        prescriptions={data?.prescriptions}
        updatePrescriptionToOrder={setPrescriptionToOrder}
      />
    </MainLayout>
  );
};

export default Scripts;
