import { Flex } from "@chakra-ui/react";
import { ReactComponent as FacebookLogo } from "../../../../../../images/facebookLogo.svg";
import { ReactComponent as TwitterLogo } from "../../../../../../images/twitterLogo.svg";

const SocialMediaLinks = () => {
  return (
    <Flex gap="5">
      <FacebookLogo
        cursor="pointer"
        onClick={() =>
          window.open(
            `https://www.facebook.com/sharer.php?src=sp&u=https://cannabisclinic.co.nz`
          )
        }
      />
      <TwitterLogo
        cursor="pointer"
        onClick={() =>
          window.open(
            `https://twitter.com/intent/tweet?text=Just had an amazing experience at the Cannabis Clinic! The staff were so friendly, understood my needs and recommended the perfect products for me. I highly recommend booking an appointment! #CannabisClinic #WellnessJourney https://cannabisclinic.co.nz&url=https://cannabisclinic.co.nz`
          )
        }
      />
    </Flex>
  );
};

export default SocialMediaLinks;
