import { ArrowBackIcon } from "@chakra-ui/icons";
import { Box, Button, Flex } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
// import { ReactComponent as CCLogo } from "../../../images/CannabisClinicLogo1.svg";
import Notifications from "../features/notifications";
import { ReactComponent as CartIcon } from "../images/cartIcon.svg";
import { ReactComponent as CCLogoNoText } from "../images/cclogo-notext.svg";

const ContainerNavbar = () => {
  const navigate = useNavigate();
  const isCartPage = window.location.pathname.includes("cart");
  const isDispensary = window.location.pathname.includes("dispensary");
  const showBackIcon =
    isCartPage || window.location.pathname.includes("dispensary/");

  return (
    <Box position="relative" mb={{ base: 10, xl: 10 }}>
      <Flex
        gap="3"
        alignItems="center"
        position={{ base: "inherit", xl: "absolute" }}
        right="0"
        w="100%"
      >
        <Box mr="auto">
          {showBackIcon ? (
            <ArrowBackIcon
              h="6"
              w="6"
              cursor="pointer"
              onClick={() => {
                isCartPage ? navigate(-1) : navigate("/patient/dispensary");
              }}
            />
          ) : (
            <Box display={{ base: "inherit", xl: "none" }}>
              <CCLogoNoText width="40" height="40" />
            </Box>
          )}
        </Box>
        <Box display={{ base: "block", xl: "none" }} ml="auto">
          <Notifications iconColor="#1F2329" />
        </Box>
        <Box
          display={{
            base: !isCartPage && isDispensary ? "inherit" : "none",
            xl: "none",
          }}
        >
          <CartIcon
            color="#1F2329"
            onClick={() => navigate("/patient/dispensary/cart")}
          />
        </Box>
        <Button
          ml="auto"
          display={{
            base: "none",
            xl: !isCartPage && isDispensary ? "inherit" : "none",
          }}
          variant="primaryDarkOutline"
          rightIcon={<CartIcon color="cc.primary.500" />}
          color="cc.primary.500"
          onClick={() => navigate("/patient/dispensary/cart")}
        >
          View Cart
        </Button>
      </Flex>
    </Box>
  );
};

export default ContainerNavbar;
