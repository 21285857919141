import { Box, Grid } from "@chakra-ui/react";
import LoadingAnimation from "../../../../../components/LoadingAnimation";
import {
  useProductFilterOptionsQuery,
  useProductsLazyQuery,
} from "../../../../../generated/graphql";
import ProductPreview from "../../../dispensary/product/ProductPreview";
import ProductSlider from "../../../dispensary/product/ProductSlider";

const DispensaryPreview = () => {
  const featuredBrands = ["florx", "cannabis clinic"];

  const [getProducts, { data, loading: isProductsLoading }] =
    useProductsLazyQuery();

  const { loading } = useProductFilterOptionsQuery({
    onCompleted: (res) => {
      const featuredBrandIds = [];
      for (const featuredBrand of featuredBrands) {
        const id = res.productFilterOptions.collections.find((c) =>
          c.title.toLowerCase().includes(featuredBrand)
        )?.id;

        if (!id) continue;
        featuredBrandIds.push(id);
      }

      getProducts({
        variables: {
          input: {
            limit: 6,
            selectedBrandIds: featuredBrandIds,
          },
        },
      });
    },
  });

  if (loading || isProductsLoading)
    return (
      <Box>
        <LoadingAnimation />
      </Box>
    );

  return (
    <Box>
      <Box display={{ base: "block", lg: "none" }} w="81vw">
        <ProductSlider
          products={data?.products.products ?? []}
          showButtons={false}
        />
      </Box>
      <Grid
        display={{ base: "none", lg: "grid" }}
        templateColumns={{
          base: "repeat(2, 1fr)",
          lg: "repeat(3, 1fr)",
          xl: "repeat(2, 1fr)",
        }}
        gap="5"
      >
        {data?.products.products?.map((product, i: number) => {
          return <ProductPreview key={i} product={product} />;
        })}
      </Grid>
    </Box>
  );
};

export default DispensaryPreview;
