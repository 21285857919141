import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import { Box, Button, Flex } from "@chakra-ui/react";
import html2canvas from "html2canvas";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import LoadingAnimation from "../../../components/LoadingAnimation";
import ConsentForm from "../../../features/patients/onboarding/ConsentForm";
import PatientIntakeForm from "../../../features/patients/onboarding/PatientIntakeForm";
import PatientPasswordForm from "../../../features/patients/onboarding/PatientPasswordForm";
import {
  ConfirmPatientSignupInput,
  useConfirmPatientSignupMutation,
} from "../../../generated/graphql";
import OnboardingLayout from "../../../layouts/OnboardingLayout";

const CompleteSignup = () => {
  const navigate = useNavigate();
  const ref = useRef(null);

  const [searchParams] = useSearchParams();
  const token = searchParams.get("tkn");
  const [step, setStep] = useState(0);

  const [patientAccountInfo, setPatientAccountInfo] = useState<any>({
    password: undefined,
    confirmPassword: undefined,
  });

  const [patientIntakeInfo, setPatientIntakeInfo] = useState<any>({
    ethnicity: undefined,
    gender: undefined,
    dob: undefined,
    streetAddress: undefined,
    suburb: undefined,
    region: undefined,
    city: undefined,
    postCode: undefined,
    medicalCondition: undefined,
    symptoms: undefined,
    cigarettes: undefined,
    standardDrinks: undefined,
    allergies: undefined,
    cardiovascularDisease: undefined,
    abnormalLiver: undefined,
    psychosisOrSchizophrenia: undefined,
    bipolar: undefined,
    substanceAbuse: undefined,
    selectedSubstanceAbuse: undefined,
    selfMedicationHistory: undefined,
    gramsPerDay: undefined,
    gpConsent: undefined,
    gpEmail: undefined,
    gpName: undefined,
    gpLocation: undefined,
  });

  const [consentForm, setConsentForm] = useState<any>({
    sideEffects: false,
    unapprovedMedicine: false,
    driving: false,
    medicationInteraction: false,
    qualityStandards: false,
    monitoring: false,
    signature: undefined,
    date: undefined,
  });

  const isPatientFormValid = () => {
    for (const fieldKey in patientIntakeInfo) {
      if (patientIntakeInfo[fieldKey] === undefined) {
        if (
          (fieldKey === "gramsPerDay" &&
            !patientIntakeInfo.selfMedicationHistory) ||
          (fieldKey === "selectedSubstanceAbuse" &&
            !patientIntakeInfo.substanceAbuse) ||
          (fieldKey === "gpEmail" && patientIntakeInfo.gpConsent === false)
        ) {
          // SKIP
        } else {
          return false;
        }
      }
    }

    return true;
  };

  const isConsentFormValid = () => {
    for (const fieldKey in consentForm) {
      if (!consentForm[fieldKey]) return false;
    }
    return true;
  };

  const handleClick = () => {
    if (
      step === 0 &&
      (!patientAccountInfo.password || !patientAccountInfo.confirmPassword)
    ) {
      return alert("Please fill out all required fields");
    }
    if (step === 1 && !isPatientFormValid()) {
      return alert("Please fill out all required patient fields");
    }
    if (step === 2 && !isConsentFormValid()) {
      return alert("Please fill out all required consent form fields");
    }
    if (step !== 2) return setStep(step + 1);
    submitForm();
  };

  const [submit, { loading, error, data }] = useConfirmPatientSignupMutation({
    onCompleted: async (data: any) => {
      localStorage.setItem("token", data?.confirmPatientSignup.token);
      navigate("/");
    },
    onError: (error: any) => {
      setStep(0);
      alert(
        "Something went wrong - please contact support@cannabisclinic.co.nz"
      );
    },
  });

  const screenshotConsentForm = () => {
    window.scrollTo(0, 0);
    return html2canvas(document.body, {
      scrollX: 0,
      scale: 1,
      onclone: (document, element) => {
        element.style.setProperty(
          "zoom",
          (0.9 / window.devicePixelRatio) * 100 + "%"
        );
        element.style.setProperty("width", "2000");
      },
    }).then((canvas) => {
      return canvas.toDataURL();
    });
  };

  const onSubmit = ({
    token,
    password,
    patientIntakeInfo,
    consentFormScreenshot,
  }: ConfirmPatientSignupInput) =>
    submit({
      variables: {
        input: {
          token,
          password,
          patientIntakeInfo,
          consentFormScreenshot,
        },
      },
    });

  const submitForm = async () => {
    if (!token) return navigate("/");
    const consentFormScreenshot = await screenshotConsentForm();

    onSubmit({
      token,
      password: patientAccountInfo.password,
      patientIntakeInfo: patientIntakeInfo,
      consentFormScreenshot,
    });
  };

  useEffect(() => {
    if (!token) navigate("/");
  }, []);

  const renderPatientSignupStep = (step: number) => {
    switch (step) {
      default:
        return (
          <PatientPasswordForm
            patientAccountInfo={patientAccountInfo}
            updatePatientAccountInfo={setPatientAccountInfo}
          />
        );
      case 1:
        return (
          <PatientIntakeForm
            patientIntakeInfo={patientIntakeInfo}
            updatePatientIntakeInfo={setPatientIntakeInfo}
          />
        );
      case 2:
        return (
          <ConsentForm
            consentForm={consentForm}
            updateConsentForm={setConsentForm}
          />
        );
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  if (loading)
    return (
      <OnboardingLayout>
        <LoadingAnimation />
      </OnboardingLayout>
    );

  return (
    <OnboardingLayout>
      <Box ref={ref}>{renderPatientSignupStep(step)}</Box>
      <Flex gap="10">
        {step > 0 && (
          <Button
            ml="auto"
            mt="4"
            variant="primaryDark"
            type="submit"
            onClick={() => setStep(step - 1)}
            leftIcon={<ArrowBackIcon />}
          >
            Back
          </Button>
        )}
        <Button
          mr="auto"
          mt="4"
          variant="primaryDark"
          type="submit"
          onClick={handleClick}
          rightIcon={<ArrowForwardIcon />}
        >
          {step !== 2 ? "Next" : "Submit"}
        </Button>
      </Flex>
    </OnboardingLayout>
  );
};

export default CompleteSignup;
