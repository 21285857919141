import { Box, Button, Flex, Grid } from "@chakra-ui/react";
import { FC, Fragment } from "react";
import { ReactComponent as Sunrise } from "../../../../../images/sunrise.svg";
import { ReactComponent as Sunset } from "../../../../../images/sunset.svg";

const AcuityAvailableTimes: FC<{
  availableTimes: any[];
  timeRange: "morning" | "afternoon";
  updateSelectedTime: React.Dispatch<React.SetStateAction<Date | undefined>>;
}> = ({ availableTimes, timeRange, updateSelectedTime }) => {
  const availableTimeRangeTimes = availableTimes?.filter((timeData: any) => {
    const time = new Date(timeData.time);
    const midday = new Date();
    midday.setHours(12);

    if (timeRange === "morning" && time.getHours() < midday.getHours())
      return timeData;
    if (timeRange === "afternoon" && time.getHours() > midday.getHours())
      return timeData;
  });

  if (!availableTimeRangeTimes.length) return null;

  return (
    <Box mb="5">
      <Flex alignItems="center" gap="5" fontWeight="600">
        {timeRange === "morning" ? (
          <>
            <Sunrise /> Morning
          </>
        ) : (
          <>
            <Sunset /> Afternoon
          </>
        )}
      </Flex>
      <Grid
        templateColumns={{
          base: "repeat(2, 1fr)",
          lg: "repeat(5, 1fr)",
        }}
        gap="5"
        mt="5"
      >
        {availableTimeRangeTimes.map((timeData: any, i: number) => {
          const time = new Date(timeData.time);
          const timestring = time.toTimeString().split(" ")[0];
          return (
            <Fragment key={i}>
              <Button
                variant="primaryDarkOutline"
                onClick={() => updateSelectedTime(time)}
                cursor="pointer"
                my="1"
                px={{ base: "5" }}
                w="full"
              >
                {timestring.substring(0, timestring.length - 3)}
                {timeRange === "morning" ? "AM" : "PM"}
              </Button>
            </Fragment>
          );
        })}
      </Grid>
    </Box>
  );
};

export default AcuityAvailableTimes;
