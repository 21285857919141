import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import { Flex, Text } from "@chakra-ui/react";
import { FC } from "react";

const QuantitySelect: FC<{
  size?: "sm" | "lg";
  quantity: number;
  updateQuantity: (value: number) => void;
}> = ({ size, quantity, updateQuantity }) => {
  const isSmall = size === "sm";

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      borderColor="cc.greyScale.100"
      borderWidth="1.5px"
      maxW="40"
      rounded="2xl"
      py={{ base: 2.5, xl: 3 }}
      gap={{ base: isSmall ? 2.5 : 5, lg: isSmall ? 2.5 : 5 }}
    >
      <Flex
        cursor="pointer"
        w="full"
        pl="5"
        onClick={() => {
          if (quantity > 1) updateQuantity(quantity - 1);
        }}
      >
        <MinusIcon color="cc.greyScale.200" w="3" mx="auto" />
      </Flex>
      <Text minW={isSmall ? 5 : 0}>{quantity}</Text>
      <Flex
        cursor="pointer"
        w="full"
        pr="5"
        onClick={() => {
          updateQuantity(quantity + 1);
        }}
      >
        <AddIcon color="cc.greyScale.200" w="3" cursor="pointer" mx="auto" />
      </Flex>
    </Flex>
  );
};

export default QuantitySelect;
