import { Box, Divider, Flex, Spacer, Text } from "@chakra-ui/react";
import { FC, useEffect, useState } from "react";
import { ScriptCartItem } from "../../../../../generated/graphql";
import ProductDiscountSelect from "../../../dispensary/product/ProductDetails/components/ProductDiscountSelect";
import QuantitySelect from "../../../dispensary/product/ProductDetails/components/QuantitySelect";
import SubscribeSelect from "../../../dispensary/product/ProductDetails/components/SubscribeSelect";
import ProductPreview from "../../../dispensary/product/ProductPreview";

const ReviewScriptCartItem: FC<{
  scriptCartItem: ScriptCartItem;
  updateScriptCartItem: (
    id: string,
    variant_id: string,
    quantity: number,
    subscribe: boolean
  ) => void;
}> = ({ scriptCartItem, updateScriptCartItem }) => {
  const [quantity, setQuantity] = useState(scriptCartItem.lineItem.quantity);
  const [subscriptionSelected, setSubscriptionSelected] = useState(
    scriptCartItem.lineItem.metadata?.subscribe ?? false
  );

  const total = scriptCartItem.lineItem.total / 100;
  const price =
    parseInt(
      scriptCartItem.product.variants.find(
        (v) => v.id === scriptCartItem.lineItem.variant_id
      )?.calculated_price ?? "0"
    ) / 100;

  const updateSubscriptionSelected = (value: boolean) => {
    if (value) setQuantity(1);
    setSubscriptionSelected(value);
  };

  const updateQuantity = (value: number) => {
    if (value > 1) setSubscriptionSelected(false);
    setQuantity(value);
  };

  useEffect(() => {
    updateScriptCartItem(
      scriptCartItem.lineItem.id,
      scriptCartItem.lineItem.variant_id,
      quantity,
      subscriptionSelected
    );
  }, [quantity, subscriptionSelected]);

  return (
    <Box>
      <ProductPreview
        product={scriptCartItem.product}
        isCartReviewPage={true}
      />
      <Flex direction="column" gap="3" my="2">
        <SubscribeSelect
          size="sm"
          categories={scriptCartItem.product.categories}
          initialCalculatedPrice={price}
          subscriptionSelected={subscriptionSelected}
          updateSubscriptionSelected={updateSubscriptionSelected}
        />
        {/* Only 1 variant coming from backend as it's their already selected one */}
        <ProductDiscountSelect
          size="sm"
          bulkPurchase={scriptCartItem.product.bulk_purchase}
          bulk_discount_1={scriptCartItem.product.bulk_discount_1}
          bulk_discount_2={scriptCartItem.product.bulk_discount_1}
          variants={scriptCartItem.product.variants}
          selectedVariantIndex={0}
          quantity={quantity}
          updateQuantity={updateQuantity}
        />
        <Box w="50%" pr="1">
          <QuantitySelect
            size="sm"
            quantity={quantity}
            updateQuantity={updateQuantity}
          />
        </Box>
        <Divider />
        <Flex>
          <Text>Subtotal</Text>
          <Spacer />
          <Text fontWeight="semibold">${total.toFixed(2)}</Text>
        </Flex>
      </Flex>
    </Box>
  );
};

export default ReviewScriptCartItem;
