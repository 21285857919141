import { Box } from "@chakra-ui/react";
import Lottie from "react-lottie";
import * as checkAnimationData from "../images/checkAnimation.json";

const AddToCartAnimation = () => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
  };

  const checkAnimation = {
    ...defaultOptions,
    animationData: JSON.parse(JSON.stringify(checkAnimationData)),
  };

  return (
    <Box>
      <Lottie options={checkAnimation} height={150} width={150} />
    </Box>
  );
};

export default AddToCartAnimation;
