import { Box, Flex, Text } from "@chakra-ui/react";

import { Maybe } from "graphql/jsutils/Maybe";
import { FC, ReactNode } from "react";
import { MedusaProduct } from "../../../../../../generated/graphql";
import useCalculateCost from "../../../../../../hooks/patient/dispensary/useCalculateCost";
import { ReactComponent as PreOrderIcon } from "../../../../../../images/pre-order-icon.svg";

const ProductHeader: FC<{
  children: ReactNode | ReactNode[];
  isComingSoon: boolean;
  product: MedusaProduct;
  isInitiallyDiscounted: boolean | undefined;
  originalPrice: number | undefined;
  initialCalculatedPrice: number | undefined;
}> = ({
  children,
  isComingSoon,
  product,
  isInitiallyDiscounted,
  originalPrice,
  initialCalculatedPrice,
}) => {
  const cost = useCalculateCost(
    product?.average_dose,
    product?.total_size,
    product?.unit,
    product?.variants[0].calculated_price,
    product?.categories[0].handle
  );

  const withBreaks = (text?: Maybe<string> | undefined) => {
    if (!text) return;
    const strings = text?.split("\n");
    return strings?.map((string, i) => {
      return (
        <Text key={i}>
          {string}
          <br />
        </Text>
      );
    });
  };

  return (
    <Box textAlign="left" w="full">
      {(product.pre_order || isComingSoon) && (
        <Flex
          backgroundColor={isComingSoon ? "cc.greyScale.200" : "cc.primary.500"}
          color="white"
          justifyContent="center"
          alignItems="center"
          px="3"
          py="2"
          rounded="xl"
          borderWidth="2px"
          borderColor={isComingSoon ? "#9B9BA6" : "#367ABF"}
          fontWeight="600"
          fontSize="sm"
          maxW="36"
          mb="5"
        >
          {isComingSoon ? "Coming Soon" : "Pre Order"}
        </Flex>
      )}

      <Text as="h2">
        {product.title}
        {product.variants.length === 1 && ` - ${product.variants[0].title}`}
      </Text>
      <Flex direction="column" my="5">
        <Text>{product.subtitle}</Text>
        <Text>{withBreaks(product.description)}</Text>
      </Flex>
      {product.pre_order && (
        <Flex gap="3" mb="7" alignItems={{ base: "initil", lg: "center" }}>
          <Box>
            <PreOrderIcon width="28" />
          </Box>
          This product is on preorder, shipment will take 2-3 weeks and may be
          shipped separately
        </Flex>
      )}

      <Text
        fontSize={{ base: "lg", xl: "xl" }}
        fontWeight="600"
        color={isInitiallyDiscounted && originalPrice ? "red" : "black"}
      >
        {!isInitiallyDiscounted ? (
          <Text as="span">${originalPrice?.toFixed(2)}</Text>
        ) : (
          <>
            <Text color="cc.greyScale.100" as="s" fontWeight="500">
              ${originalPrice?.toFixed(2)}
            </Text>{" "}
            ${initialCalculatedPrice?.toFixed(2)}{" "}
            {originalPrice && initialCalculatedPrice && (
              <Text as="span">
                (
                {(
                  100 *
                  Math.abs(
                    (originalPrice - initialCalculatedPrice) /
                      ((originalPrice + initialCalculatedPrice) / 2)
                  )
                ).toFixed(0)}
                {"% "}
                off)
              </Text>
            )}
          </>
        )}
        <Text as="span" fontWeight="400" fontSize="sm">
          {" "}
          incl GST
        </Text>
      </Text>
      <Text fontSize="sm" color="cc.greyScale.200">
        {cost}
      </Text>
      {children}
    </Box>
  );
};

export default ProductHeader;
