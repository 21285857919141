import { Box, Flex } from "@chakra-ui/react";
import { FC, ReactNode } from "react";
import ContainerNavbar from "../components/ContainerNavbar";
import Navbar from "../components/Navbar";

interface MainLayoutProps {
  hideNavbar?: boolean;
  children: ReactNode | ReactNode[];
}

const MainLayout: FC<MainLayoutProps> = ({ hideNavbar, children }) => {
  return (
    <Box backgroundColor="#F6F6F8" minH="100vh" h="100%">
      {!hideNavbar && <Navbar />}
      <Flex flexDir="column" mx="5" alignItems="center">
        <Flex
          my={{ base: "10", xl: "0" }}
          flexDir="column"
          maxW="8xl"
          w="full"
          textAlign="center"
          backgroundColor="white"
          px={{ base: 5, md: 14 }}
          py={{ base: 5, md: 14 }}
          rounded="20px"
          minH="80vh"
        >
          <ContainerNavbar />
          {children}
        </Flex>
      </Flex>
    </Box>
  );
};

export default MainLayout;
