import { Box, Flex } from "@chakra-ui/react";
import { Dispatch, FC, SetStateAction } from "react";

const OrderFilterSelect: FC<{
  ordersCategory: "incomplete" | "complete";
  updateOrdersCategory: Dispatch<SetStateAction<"incomplete" | "complete">>;
}> = ({ ordersCategory, updateOrdersCategory }) => {
  return (
    <Flex ml="auto" gap={{ base: 4, xl: 8 }} mt={{ base: 8, xl: 2 }} mb="2">
      {["incomplete", "complete"].map((ordersCategoryOption: any) => {
        return (
          <Box
            cursor="pointer"
            color={
              ordersCategory === ordersCategoryOption
                ? "cc.primary.500"
                : "cc.greyScale.200"
            }
            fontWeight={
              ordersCategory === ordersCategoryOption ? "semibold" : "normal"
            }
            onClick={() => updateOrdersCategory(ordersCategoryOption)}
          >
            {ordersCategoryOption.substring(0, 1).toUpperCase() +
              ordersCategoryOption.substring(1, ordersCategoryOption.length)}
            <Box
              mt="1"
              mx="auto"
              backgroundColor="cc.primary.500"
              w="2"
              h="2"
              rounded="full"
              display={
                ordersCategory === ordersCategoryOption ? "block" : "none"
              }
            />
          </Box>
        );
      })}
    </Flex>
  );
};

export default OrderFilterSelect;
