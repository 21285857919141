import {
  Box,
  Button,
  Divider,
  Flex,
  Image,
  Spacer,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { FC, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Prescription } from "../../../generated/graphql";
import productPlaceholder from "../../../images/productPlaceholder.png";

const ViewPrescriptionDetails: FC<{ prescription: Prescription }> = ({
  prescription,
}) => {
  const navigate = useNavigate();
  const ref = useRef<HTMLDivElement>(null);
  const linkProduct = useBreakpointValue({ base: true, lg: false });

  useEffect(() => {
    if (!ref.current) return;
    ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
  }, [ref]);

  return (
    <Box ref={ref} pb="4">
      <Box
        bgColor="cc.lightScale.200"
        flexDir="column"
        borderRadius="2xl"
        mt="6"
        py="5"
        px={{ base: 3, lg: 6 }}
        textAlign="left"
      >
        {prescription.prescriptionItems.map((prescriptionItem, i) => {
          return (
            <>
              <Flex
                onClick={
                  linkProduct
                    ? () =>
                        navigate(
                          `/patient/dispensary/${prescriptionItem.handle}`
                        )
                    : undefined
                }
                cursor={{ base: "pointer", xl: "default" }}
              >
                <Image
                  src={prescriptionItem.thumbnail ?? productPlaceholder}
                  w="28"
                  h="28"
                  objectFit="cover"
                />
                <Flex
                  w="full"
                  flexDir={{ base: "column", lg: "row" }}
                  alignItems={{ base: "inherit", lg: "center" }}
                  fontSize={{ base: "xs", lg: "inherit" }}
                  lineHeight={{ base: "1.3", lg: "inherit" }}
                  gap={{ base: 2, xl: 0 }}
                >
                  <Box w={{ base: "100%", lg: "40%" }}>
                    <Text fontWeight="semibold">
                      Rx: {prescriptionItem.title} -{" "}
                      {prescriptionItem.nzUlmCode}
                    </Text>
                    <Text
                      color="cc.primary.500"
                      fontWeight="normal"
                      mt="1"
                      fontSize={{ base: "xs", lg: "sm" }}
                    >
                      {prescriptionItem.generatedDosageDesc}
                    </Text>
                  </Box>
                  <Spacer display={{ base: "none", xl: "inherit" }} />
                  <Flex
                    flexDir={{ base: "row", lg: "column" }}
                    textAlign={{ base: "left", lg: "center" }}
                    gap="2"
                  >
                    <Text>Quantity:</Text>
                    <Text fontWeight="semibold">
                      {prescriptionItem.quantity}
                    </Text>
                  </Flex>
                  <Spacer display={{ base: "none", xl: "inherit" }} />
                  <Text lineHeight="1.5">
                    Total Price:{" "}
                    {prescriptionItem.totalCalculatedPrice !==
                      prescriptionItem.totalOriginalPrice && (
                      <Text as="s" color="cc.greyScale.200">
                        $
                        {(prescriptionItem.totalOriginalPrice / 100).toFixed(2)}
                      </Text>
                    )}
                    <Text as="span" fontWeight="semibold">
                      {" "}
                      $
                      {(prescriptionItem.totalCalculatedPrice / 100).toFixed(2)}
                    </Text>
                  </Text>
                  <Spacer display={{ base: "none", xl: "inherit" }} />
                  <Box
                    ml="auto"
                    display={{ base: "none", lg: "block" }}
                    textAlign="center"
                  >
                    <Button
                      variant="primaryDarkOutline"
                      onClick={() =>
                        navigate(
                          `/patient/dispensary/${prescriptionItem.handle}`
                        )
                      }
                    >
                      View Product
                    </Button>
                  </Box>
                </Flex>
              </Flex>{" "}
              {i + 1 < prescription.prescriptionItems.length && (
                <Divider my="3" borderColor="cc.greyScale.100" />
              )}
            </>
          );
        })}
      </Box>
    </Box>
  );
};

export default ViewPrescriptionDetails;
