import { Box, Checkbox, Divider, Flex, Text, Textarea } from "@chakra-ui/react";
import { FC, useEffect, useState } from "react";
import { MedusaProductCategory } from "../../../../../../generated/graphql";
import useIsProductThc from "../../../../../../hooks/patient/dispensary/useIsProductThc";

const consentMessage = `1) I acknowledge I have been prescribed the medicinal cannabis flower
to be used by the approved oral route (tea consumption).

2) I have been advised that this product does not meet (has not been
assessed for) the minimum quality standards for use by vaporisation.
The above mentioned quality standards differ in between routes of
administration, chiefly in the acceptance criteria for microbiological
quality.

3) I acknowledge that if I proceed to use the medicinal cannabis
flower via the unapproved route of vaporisation, that I do so of my
own accord and have been given an opportunity to discuss this with my
doctor and ask any questions.`;

const VapeConsent: FC<{
  categories: MedusaProductCategory[];
  updateIsConsentValid: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ categories, updateIsConsentValid }) => {
  const isProductThc = useIsProductThc(categories);

  const [isVaporiseIntent, setIsVaporiseIntent] = useState(false);
  const [isUnderstandRisks, setIsUnderstandRisks] = useState(false);

  useEffect(() => {
    if (isVaporiseIntent && !isUnderstandRisks)
      return updateIsConsentValid(false);
    return updateIsConsentValid(true);
  }, [isVaporiseIntent, isUnderstandRisks]);

  if (!isProductThc) return null;

  return (
    <Flex flexDir="column" gap="4" my="2">
      <Flex gap="2" cursor="pointer">
        <Checkbox
          isChecked={isVaporiseIntent}
          onChange={() => setIsVaporiseIntent(!isVaporiseIntent)}
        >
          <Text>I intend to vaporise my THC flower</Text>
        </Checkbox>
      </Flex>

      {isVaporiseIntent && (
        <>
          <Divider mb="4px" />
          <Box>
            <Textarea h="40" defaultValue={consentMessage} />
          </Box>
          <Flex gap="2">
            <Checkbox
              isChecked={isUnderstandRisks}
              onChange={() => setIsUnderstandRisks(!isUnderstandRisks)}
            >
              <Text>
                I declare to understand all the associated risks with vaporising
                my THC flower
              </Text>
            </Checkbox>
          </Flex>
        </>
      )}
    </Flex>
  );
};

export default VapeConsent;
