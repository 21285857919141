import { Divider, Flex } from "@chakra-ui/react";
import { FC, Fragment } from "react";
import {
  MedusaLineItem,
  MedusaLineItemInput,
} from "../../../../generated/graphql";
import CartItem from "./CartItem";

const CartItems: FC<{
  lineItems: MedusaLineItem[] | undefined;
  updateLineItem: (
    id: string,
    variant_id: string,
    quantity: number
  ) => void;
  removeFromCart: (lineItem: MedusaLineItemInput) => void;
}> = ({ lineItems, updateLineItem, removeFromCart }) => {
  return (
    <Flex
      flexDir="column"
      my={{ base: 5, lg: 10 }}
      gap={{ base: 4, lg: 8 }}
      borderWidth={{ base: "1px", lg: "0px" }}
      py={{ base: 5, lg: 0 }}
      pl={{ base: 1, lg: 0 }}
      pr={{ base: 3, lg: 0 }}
      rounded="2xl"
      maxH={{ base: "40vh", lg: "none" }}
      overflowY="auto"
      overflowX="hidden"
    >
      {lineItems && lineItems.length > 0
        ? lineItems.map((lineItem, i) => {
            return (
              <Fragment key={i}>
                <CartItem
                  lineItem={lineItem}
                  updateLineItem={updateLineItem}
                  removeFromCart={removeFromCart}
                />
                {i + 1 < lineItems.length && (
                  <Divider borderWidth={{ base: "0.5px", lg: "1px" }} />
                )}
              </Fragment>
            );
          })
        : "No Products in Cart"}
    </Flex>
  );
};

export default CartItems;
