import { Box, Flex } from "@chakra-ui/react";
import { FC } from "react";
import { FulfillmentOrder } from "../../../generated/graphql";
import DisplayOrder from "./DisplayOrder";

const DisplayOrders: FC<{
  fulfillmentOrders: any;
  ordersCategory: "incomplete" | "complete";
}> = ({ fulfillmentOrders, ordersCategory }) => {
  const incompleteStatus = ["pending", "processing", "shipped"];
  const completeStatus = ["complete"];

  const categoryFilteredOrders: FulfillmentOrder[] = fulfillmentOrders.filter(
    (fo: FulfillmentOrder) =>
      (incompleteStatus.includes(fo.status.toLowerCase()) &&
        ordersCategory === "incomplete") ||
      (completeStatus.includes(fo.status.toLowerCase()) &&
        ordersCategory === "complete")
  );

  return (
    <Flex flexDir="column" gap="7" mb="5" mt="5">
      {categoryFilteredOrders.length && fulfillmentOrders ? (
        categoryFilteredOrders.map((categoryFilteredOrder, i) => {
          return (
            <DisplayOrder key={i} fulfillmentOrder={categoryFilteredOrder} />
          );
        })
      ) : (
        <Box as="h6" my="3" color="cc.primary.500">
          No Orders to display!
        </Box>
      )}
    </Flex>
  );
};

export default DisplayOrders;
