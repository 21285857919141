import { Box, Text } from "@chakra-ui/react";
import { FC, useState } from "react";
import { useProductFilterOptionsQuery } from "../../../generated/graphql";
import DispensaryFilter from "./DispensaryFilter";

interface DispensaryFiltersProps {
  selectedCategory: any;
  updateSelectedCategory: React.Dispatch<React.SetStateAction<any>>;
  selectedBrand: any;
  updateSelectedBrand: React.Dispatch<React.SetStateAction<any>>;
  selectedType: any;
  updateSelectedType: React.Dispatch<React.SetStateAction<any>>;
}

const DispensaryFilters: FC<DispensaryFiltersProps> = ({
  selectedCategory,
  updateSelectedCategory,
  selectedBrand,
  updateSelectedBrand,
  selectedType,
  updateSelectedType,
}) => {
  const { data } = useProductFilterOptionsQuery();
  const [isAllFiltersClosed, setIsAllFiltersClosed] = useState(true);

  return (
    <Box textAlign="left" w="full">
      <Text
        mb="6"
        fontWeight="600"
        cursor="pointer"
        _hover={{
          textDecoration: "underline",
        }}
        onClick={() => setIsAllFiltersClosed(true)}
      >
        Shop all
      </Text>
      <DispensaryFilter
        type="categories"
        isAllFiltersClosed={isAllFiltersClosed}
        updateIsAllFiltersClosed={setIsAllFiltersClosed}
        filterOptions={data?.productFilterOptions.product_categories}
        selectedFilterOption={selectedCategory}
        updateSelectedFilterOption={updateSelectedCategory}
      />
      <DispensaryFilter
        type="brand"
        isAllFiltersClosed={isAllFiltersClosed}
        updateIsAllFiltersClosed={setIsAllFiltersClosed}
        filterOptions={data?.productFilterOptions.collections}
        selectedFilterOption={selectedBrand}
        updateSelectedFilterOption={updateSelectedBrand}
      />
      <DispensaryFilter
        type="type"
        isAllFiltersClosed={isAllFiltersClosed}
        updateIsAllFiltersClosed={setIsAllFiltersClosed}
        filterOptions={data?.productFilterOptions.product_types}
        selectedFilterOption={selectedType}
        updateSelectedFilterOption={updateSelectedType}
      />
    </Box>
  );
};

export default DispensaryFilters;
