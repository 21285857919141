import {
  Box,
  Button,
  Divider,
  Flex,
  Spacer,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { FC, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  FulfillmentOrder,
  useAddToCartMutation,
} from "../../../generated/graphql";
import AddToCartPopup from "../dispensary/cart/AddToCartPopup";
import OrderItem from "./OrderItem";

const ViewOrderDetails: FC<{ fulfillmentOrder: FulfillmentOrder }> = ({
  fulfillmentOrder,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [addToCart] = useAddToCartMutation({
    onCompleted: async () => {
      onOpen();
      await new Promise((resolve) => setTimeout(resolve, 1500));
      navigate("/patient/dispensary/cart");
    },
    onError: async (err) => {
      onOpen();
      await new Promise((resolve) => setTimeout(resolve, 1500));
      alert(err);
      navigate("/patient/dispensary/cart");
    },
  });

  const handleReOrder = () => {
    const parentLineItems = fulfillmentOrder.fulfillmentOrderItems.map(
      (foi) => {
        return { quantity: foi.quantity, variant_id: foi.productVariantId };
      }
    );
    const childLineItems = fulfillmentOrder.childOrders
      .map((co) =>
        co.fulfillmentOrderItems.map((foi) => {
          return {
            quantity: foi.quantity,
            variant_id: foi.productVariantId,
          };
        })
      )
      .flat();
    const allOrderItems = parentLineItems.concat(childLineItems);

    addToCart({
      variables: {
        input: {
          lineItems: allOrderItems,
        },
      },
    });
  };

  useEffect(() => {
    if (!ref.current) return;
    ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
  }, [ref]);

  return (
    <Box ref={ref} pb="4">
      <Flex
        flexDir="column"
        borderRadius="2xl"
        borderWidth="1px"
        borderColor="cc.greyScale.100"
        mx="4"
        mt="4"
        py="5"
        px="6"
        textAlign="left"
      >
        <Box>
          <Text as="h6" fontWeight="semibold" mb="4">
            Initial Order
          </Text>
          <Flex flexDir="column" gap="3">
            {fulfillmentOrder.fulfillmentOrderItems.map((item, i) => {
              return <OrderItem key={i} item={item} />;
            })}
          </Flex>
          <Divider my="5" />
        </Box>
        {fulfillmentOrder.childOrders.map((childOrder, i) => {
          const date = new Date(childOrder.fulfillmentDate);
          return (
            <Box>
              <Text as="h6" fontWeight="medium" mb="4">
                {childOrder.type}
                <Text fontSize="sm" color="cc.red">
                  Expected Release: {date.toLocaleDateString("en-NZ")}
                </Text>
              </Text>
              <Flex flexDir="column" gap="3">
                {childOrder.fulfillmentOrderItems.map((item, j) => {
                  return <OrderItem key={i} item={item} />;
                })}
                <Divider my="5" />
              </Flex>
            </Box>
          );
        })}
        <Flex my="5" textAlign="left" gap="2">
          <Box>
            <Text as="h6" fontWeight="semibold" mb="4">
              Shipping Address:
            </Text>
            <Text>
              {fulfillmentOrder.addressDetails.shippingAddress.address_1}
              {fulfillmentOrder.addressDetails.shippingAddress.address_2 &&
                `,${fulfillmentOrder.addressDetails.shippingAddress.address_2}`}
            </Text>
            <Text>{fulfillmentOrder.addressDetails.shippingAddress.city}</Text>
            <Text>
              {fulfillmentOrder.addressDetails.shippingAddress.company}
            </Text>
            <Text>
              {fulfillmentOrder.addressDetails.shippingAddress.postal_code}
            </Text>
          </Box>
          <Spacer />
          <Box>
            <Text as="h6" fontWeight="semibold" mb="4">
              Billing Address:
            </Text>
            <Text>
              {fulfillmentOrder.addressDetails.billingAddress.address_1}
              {fulfillmentOrder.addressDetails.billingAddress.address_2 &&
                `,${fulfillmentOrder.addressDetails.billingAddress.address_2}`}
            </Text>
            <Text>{fulfillmentOrder.addressDetails.billingAddress.city}</Text>
            <Text>
              {fulfillmentOrder.addressDetails.billingAddress.company}
            </Text>
            <Text>
              {fulfillmentOrder.addressDetails.billingAddress.postal_code}
            </Text>
          </Box>
          <Spacer />
        </Flex>
        <Button
          onClick={() => handleReOrder()}
          variant="primaryDark"
          py="6"
          fontSize="sm"
          mr="auto"
        >
          Re-Order
        </Button>
      </Flex>
      <AddToCartPopup isOpen={isOpen} onClose={onClose} />
    </Box>
  );
};

export default ViewOrderDetails;
