import { Flex } from "@chakra-ui/react";
import { FC, useEffect, useState } from "react";
import { MedusaProduct, useUserQuery } from "../../../../../generated/graphql";

import useGetTotalStockQuantity from "../../../../../hooks/patient/dispensary/useGetTotalStockQuantity";
import useIsProductThc from "../../../../../hooks/patient/dispensary/useIsProductThc";
import ProductComingSoon from "./components/ProductComingSoon";
import ProductHeader from "./components/ProductHeader";
import ProductImage from "./components/ProductImage";
import ProductInfo from "./components/ProductInfo";
import ProductPurchaseOptions from "./components/ProductPurchaseOptions";
import RequestThcProduct from "./components/RequestThcProduct";
import SocialMediaLinks from "./components/SocialMediaLinks";

const ProductDetails: FC<{
  product: MedusaProduct;
}> = ({ product }) => {
  const { data: userData } = useUserQuery();
  const { id, variants, categories } = product;
  const isComingSoon = useGetTotalStockQuantity(variants) === 0;

  const isPatientPrescribedThc = userData?.user.patient.isPrescribedThc;
  const isProductThc = useIsProductThc(product.categories);

  const [originalPrice, setOriginalPrice] = useState<number>(0);
  const [initialCalculatedPrice, setInitialCalculatedPrice] =
    useState<number>(0);
  const [isInitiallyDiscounted, setIsInitiallyDiscounted] = useState<boolean>();

  const [selectedVariantIndex, setSelectedVariantIndex] = useState<number>(0);
  const [quantity, setQuantity] = useState(1);

  const [selectedDiscount, setSelectedDiscount] = useState<number>();
  const [subscriptionSelected, setSubscriptionSelected] =
    useState<boolean>(false);

  const calculatePricing = () => {
    if (
      !variants[selectedVariantIndex].calculated_price ||
      !variants[selectedVariantIndex].original_price
    )
      return;
    const calculatedPrice =
      parseInt(variants[selectedVariantIndex]?.calculated_price ?? "0") / 100;
    const originalPrice =
      parseInt(variants[selectedVariantIndex].original_price ?? "0") / 100;

    setOriginalPrice(originalPrice);
    setInitialCalculatedPrice(calculatedPrice);
    setIsInitiallyDiscounted(originalPrice > calculatedPrice ? true : false);
  };

  const updateSubscriptionSelected = (value: boolean) => {
    if (value) setQuantity(1);
    setSubscriptionSelected(value);
  };

  const updateQuantity = (value: number) => {
    if (value > 1 && subscriptionSelected) {
      setSubscriptionSelected(false);
    }
    setQuantity(value);
  };

  const handleDiscountSelection = () => {
    if (!product.bulk_purchase) return;
    if (quantity < 2) setSelectedDiscount(0);
    if (quantity === 2 && product.bulk_discount_1)
      setSelectedDiscount(product.bulk_discount_1);
    if (quantity >= 3 && product.bulk_discount_2)
      setSelectedDiscount(product.bulk_discount_2);
  };

  useEffect(() => {
    handleDiscountSelection();
  }, [quantity]);

  useEffect(() => {
    calculatePricing();
  }, [selectedDiscount, selectedVariantIndex]);

  return (
    <Flex flexDir={{ base: "column", xl: "row" }} gap="10" mt="20">
      <ProductImage />
      <ProductHeader
        product={product}
        originalPrice={originalPrice}
        initialCalculatedPrice={initialCalculatedPrice}
        isInitiallyDiscounted={isInitiallyDiscounted}
        isComingSoon={isComingSoon}
      >
        {/* If the product is out of stock OR patient isn't prescribed THC then don't show the product purchase options */}
        {isComingSoon ? (
          <ProductComingSoon productId={id} />
        ) : !isPatientPrescribedThc && isProductThc ? (
          <RequestThcProduct productId={id} />
        ) : (
          <ProductPurchaseOptions
            bulkPurchase={product?.bulk_purchase}
            bulk_discount_1={product?.bulk_discount_1}
            bulk_discount_2={product?.bulk_discount_2}
            variants={variants}
            categories={categories}
            selectedVariantIndex={selectedVariantIndex}
            updateSelectedVariantIndex={setSelectedVariantIndex}
            subscriptionSelected={subscriptionSelected}
            updateSubscriptionSelected={updateSubscriptionSelected}
            quantity={quantity}
            updateQuantity={updateQuantity}
            initialCalculatedPrice={initialCalculatedPrice}
          />
        )}
        <ProductInfo product={product} />
        <SocialMediaLinks />
      </ProductHeader>
    </Flex>
  );
};

export default ProductDetails;
