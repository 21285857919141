import { ChevronLeftIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, Text } from "@chakra-ui/react";

import { FC } from "react";
import "swiper/css";

const SelectAppointmentType: FC<{
  appointmentTypes: any;
  selectedCategory: any;
  selectedAppointmentType: any;
  updateSelectedAppointmentType: any;
  updateSelectedCalendar: any;
  updateSelectedTime: any;
}> = ({
  appointmentTypes,
  selectedAppointmentType,
  selectedCategory,
  updateSelectedAppointmentType,
  updateSelectedCalendar,
  updateSelectedTime,
}) => {
  if (!selectedCategory) return null;

  return (
    <Box mt="8">
      <Text textAlign="left" fontWeight="500">
        I would like to schedule...
      </Text>
      <Flex flexDir="column" gap="8" mt="5">
        {appointmentTypes?.map((appointmentType: any, i: number) => {
          const isAppointmentSelected =
            selectedAppointmentType &&
            selectedAppointmentType.id === appointmentType.id;

          if (
            !appointmentType.private &&
            (!selectedAppointmentType ||
              (selectedAppointmentType && isAppointmentSelected))
          )
            return (
              <Flex
                key={i}
                onClick={() => updateSelectedAppointmentType(appointmentType)}
                cursor="pointer"
                flexDir={{
                  base: "column",
                  md: "row",
                }}
                py="8"
                px="10"
                rounded="20px"
                border="2px"
                borderColor="cc.greyScale.100"
                textAlign="left"
              >
                <Box>
                  <Text fontWeight="600">{appointmentType.name}</Text>
                  <Text fontWeight="400" my="2">
                    ${appointmentType.price}
                  </Text>
                  {appointmentType.description && (
                    <Text fontWeight="400" color="cc.greyScale.200">
                      *{appointmentType.description}
                    </Text>
                  )}
                </Box>
                <Box
                  ml="auto"
                  mr={{ base: "auto", md: "0" }}
                  my="auto"
                  mt={{ base: "4", md: "auto" }}
                >
                  {isAppointmentSelected ? (
                    <Button w="32" mx="auto" mt="auto" variant="primaryDark">
                      Selected
                    </Button>
                  ) : (
                    <Button
                      w="32"
                      mx="auto"
                      mt="auto"
                      variant="primaryDarkOutline"
                    >
                      Book Now
                    </Button>
                  )}
                </Box>
              </Flex>
            );
        })}{" "}
        <Box mr="auto">
          <Button
            mr="auto"
            variant="primaryLightOutline"
            cursor="pointer"
            onClick={() => {
              updateSelectedAppointmentType(null);
              updateSelectedCalendar(null);
              updateSelectedTime(undefined);
            }}
          >
            <ChevronLeftIcon boxSize="6" />
            View Appointments
          </Button>
        </Box>
      </Flex>
    </Box>
  );
};

export default SelectAppointmentType;
