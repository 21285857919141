import { ArrowForwardIcon } from "@chakra-ui/icons";
import { Box, Button, FormLabel, Input, Text } from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  RequestResetPasswordInput,
  useRequestResetPasswordMutation,
} from "../generated/graphql";
import AuthLayout from "../layouts/AuthFlowLayout";

const RequestResetPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  const [requestResetPassword] = useRequestResetPasswordMutation({
    onCompleted: async (data) => {
      localStorage.removeItem("token");
      navigate("/");
    },
    onError: (error) => {
      alert(error);
    },
  });

  const onRequestResetPassword = ({ email }: RequestResetPasswordInput) =>
    requestResetPassword({
      variables: {
        input: {
          email,
        },
      },
    });

  return (
    <AuthLayout>
      <Text as="h3">Request Password Reset</Text>
      <Text my="5" fontSize="sm" textAlign="left" color="cc.greyScale.300">
        Enter your email below, if an account with this email exists, a reset
        password link will be emailed to you.
      </Text>
      <form>
        <Box>
          <Box>
            <FormLabel>Email</FormLabel>
            <Input type="email" onChange={(e) => setEmail(e.target.value)} />
          </Box>
          <Button
            my="7"
            variant="primaryLight"
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              onRequestResetPassword({ email });
            }}
            rightIcon={<ArrowForwardIcon />}
          >
            Submit
          </Button>
        </Box>
      </form>
    </AuthLayout>
  );
};

export default RequestResetPassword;
