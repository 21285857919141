import { Flex, Spacer } from "@chakra-ui/react";

import AppointmentsPreview from "./components/AppointmentsPreview";
import DispensaryPreview from "./components/DispensaryPreview";
import OrdersPreview from "./components/OrdersPreview";
import PreviewContainer from "./components/PreviewContainer";

const DashboardPreviews = () => {
  return (
    <Flex direction={{ base: "column", xl: "row" }} w="full" gap="5">
      <Flex maxW={{ base: "100%", xl: "55%" }}>
        <PreviewContainer type="dispensary">
          <Flex
            my={{ base: 0, lg: 5 }}
            px={{ base: 0, lg: 5 }}
            overflowY={{ base: "initial", lg: "auto" }}
            w="full"
          >
            <DispensaryPreview />
          </Flex>
        </PreviewContainer>
      </Flex>
      <Flex w="full" direction="column" gap={{ base: 8, lg: 8 }}>
        <PreviewContainer type="orders">
          <Flex
            borderWidth="1px"
            borderRadius="2xl"
            p="5"
            mt="4"
            overflowY="auto"
            w="full"
          >
            <OrdersPreview />
          </Flex>
        </PreviewContainer>
        <Spacer />
        <PreviewContainer type="appointments">
          <Flex overflowY="auto" w="full">
            <AppointmentsPreview />
          </Flex>
        </PreviewContainer>
      </Flex>
    </Flex>
  );
};

export default DashboardPreviews;
