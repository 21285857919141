import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
};

export type AcuityAppointment = {
  __typename?: 'AcuityAppointment';
  _id: Scalars['Float'];
  amountPaid: Scalars['String'];
  appointmentTypeID: Scalars['Float'];
  calendar: Scalars['String'];
  calendarID: Scalars['Float'];
  canClientCancel: Scalars['Boolean'];
  canClientReschedule: Scalars['Boolean'];
  certificate: Scalars['String'];
  classID: Scalars['Float'];
  confirmationPage: Scalars['String'];
  date: Scalars['String'];
  dateCreated: Scalars['String'];
  datetime: Scalars['DateTime'];
  duration: Scalars['String'];
  email: Scalars['String'];
  endTime: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  location: Scalars['String'];
  notes: Scalars['String'];
  paid: Scalars['String'];
  phone: Scalars['String'];
  price: Scalars['String'];
  scheduledBy: Scalars['String'];
  time: Scalars['String'];
  timezone: Scalars['String'];
  type: Scalars['String'];
};

export type AcuityAppointmentCategories = {
  __typename?: 'AcuityAppointmentCategories';
  name: Scalars['String'];
};

export type AcuityAppointmentFormData = {
  __typename?: 'AcuityAppointmentFormData';
  appointmentCategories: Array<AcuityAppointmentCategories>;
  appointmentTypes: Array<AcuityAppointmentType>;
  calendars: Array<AcuityCalendar>;
};

export type AcuityAppointmentType = {
  __typename?: 'AcuityAppointmentType';
  active: Scalars['Boolean'];
  addonIDs: Array<Scalars['String']>;
  calendarIDs: Array<Scalars['Float']>;
  category: Scalars['String'];
  classSize?: Maybe<Scalars['String']>;
  color: Scalars['String'];
  description: Scalars['String'];
  duration: Scalars['Float'];
  formIDs: Array<Scalars['String']>;
  id: Scalars['Float'];
  image: Scalars['String'];
  name: Scalars['String'];
  paddingAfter: Scalars['Float'];
  paddingBefore: Scalars['Float'];
  price: Scalars['String'];
  private: Scalars['Boolean'];
  schedulingUrl: Scalars['String'];
  type: Scalars['String'];
};

export type AcuityAppointmentsInput = {
  maxDate?: InputMaybe<Scalars['DateTime']>;
  minDate?: InputMaybe<Scalars['DateTime']>;
};

export type AcuityAvailableAppointmentDays = {
  __typename?: 'AcuityAvailableAppointmentDays';
  date: Scalars['String'];
  times: Array<AcuityAvailableAppointmentTimes>;
};

export type AcuityAvailableAppointmentTimes = {
  __typename?: 'AcuityAvailableAppointmentTimes';
  time: Scalars['String'];
};

export type AcuityAvailableDaysInput = {
  appointmentTypeId: Scalars['Float'];
  calendarId?: InputMaybe<Scalars['Float']>;
  month: Scalars['DateTime'];
};

export type AcuityAvailableTimesInput = {
  appointmentTypeId: Scalars['Float'];
  calendarId: Scalars['Float'];
  date: Scalars['String'];
};

export type AcuityCalendar = {
  __typename?: 'AcuityCalendar';
  description: Scalars['String'];
  email: Scalars['String'];
  id: Scalars['Float'];
  location: Scalars['String'];
  name: Scalars['String'];
  replyTo: Scalars['String'];
  timezone: Scalars['String'];
};

export type AddToCartInput = {
  lineItems: Array<MedusaLineItemInput>;
};

export type AddToNotifyProductListInput = {
  customerEmail: Scalars['String'];
  productId: Scalars['String'];
};

export type AddressDetails = {
  __typename?: 'AddressDetails';
  billingAddress: MedusaOrderAddress;
  shippingAddress: MedusaOrderAddress;
};

export type ApplyCouponInput = {
  couponCode: Scalars['String'];
};

export type Appointment = {
  __typename?: 'Appointment';
  _id: Scalars['Float'];
  acuityId: Scalars['Float'];
  amountPaid: Scalars['String'];
  appointmentTypeID: Scalars['Float'];
  calendar: Scalars['String'];
  calendarID: Scalars['Float'];
  canClientCancel: Scalars['Boolean'];
  canClientReschedule: Scalars['Boolean'];
  certificate: Scalars['String'];
  classID: Scalars['Float'];
  confirmationPage: Scalars['String'];
  confirmed: Scalars['Boolean'];
  date: Scalars['String'];
  dateCreated: Scalars['String'];
  datetime: Scalars['DateTime'];
  duration: Scalars['String'];
  email: Scalars['String'];
  endTime: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['String'];
  lastName: Scalars['String'];
  location: Scalars['String'];
  notes: Scalars['String'];
  paid: Scalars['String'];
  phone: Scalars['String'];
  price: Scalars['String'];
  scheduledBy: Scalars['String'];
  time: Scalars['String'];
  timezone: Scalars['String'];
  type: Scalars['String'];
  user: User;
};

export type AuthPayload = {
  __typename?: 'AuthPayload';
  token: Scalars['String'];
  user: User;
};

export type BasicInfo = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type CompleteCheckoutInput = {
  billingAddress: MedusaAddress;
  shippingAddress: MedusaAddress;
};

export type ConfirmAcuityAppointmentInput = {
  id: Scalars['String'];
};

export type ConfirmPatientSignupInput = {
  consentFormScreenshot: Scalars['String'];
  password: Scalars['String'];
  patientIntakeInfo: PatientIntakeInfo;
  token: Scalars['String'];
};

export type CreateAcuityAppointmentInput = {
  selectedAppointmentTypeId: Scalars['Float'];
  selectedBookingTime: Scalars['DateTime'];
};

export type CreateInitialAcuityAppointmentInput = {
  basicInfo: BasicInfo;
  selectedAppointmentTypeId: Scalars['Float'];
  selectedBookingTime: Scalars['DateTime'];
};

export type CreatePaymentSessionInput = {
  providerId: Scalars['String'];
};

export type Doctor = {
  __typename?: 'Doctor';
  id: Scalars['String'];
  user: User;
};

export type FulfillmentOrder = {
  __typename?: 'FulfillmentOrder';
  addressDetails: AddressDetails;
  childOrders: Array<FulfillmentOrder>;
  fulfillmentDate?: Maybe<Scalars['DateTime']>;
  fulfillmentOrderItems: Array<FulfillmentOrderItem>;
  id: Scalars['String'];
  medusaOrderId: Scalars['String'];
  parentOrderId?: Maybe<Scalars['String']>;
  shippingNo: Scalars['String'];
  status: Scalars['String'];
  totalPrice: Scalars['Float'];
  type: Scalars['String'];
};

export type FulfillmentOrderItem = {
  __typename?: 'FulfillmentOrderItem';
  handle: Scalars['String'];
  id: Scalars['String'];
  isInPrescription: Scalars['Boolean'];
  maximumDosageFlag: Scalars['Boolean'];
  price: Scalars['Float'];
  productVariantId: Scalars['String'];
  quantity: Scalars['Float'];
  thumbnail?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export enum FulfillmentWarningType {
  CartMaximumDosage = 'CART_MAXIMUM_DOSAGE',
  CartMaximumDosageSplit = 'CART_MAXIMUM_DOSAGE_SPLIT',
  PendingMaximumDosage = 'PENDING_MAXIMUM_DOSAGE',
  PendingMaximumDosageSplit = 'PENDING_MAXIMUM_DOSAGE_SPLIT',
  Prescription = 'PRESCRIPTION'
}

export type FulfillmentWarnings = {
  __typename?: 'FulfillmentWarnings';
  title: Scalars['String'];
  type: FulfillmentWarningType;
};

export type LineItemMetaData = {
  __typename?: 'LineItemMetaData';
  pre_order?: Maybe<Scalars['Boolean']>;
  subscribe?: Maybe<Scalars['Boolean']>;
};

export type LoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  role?: InputMaybe<Scalars['String']>;
};

export type MedusaAddress = {
  city: Scalars['String'];
  company: Scalars['String'];
  country: Scalars['String'];
  postCode: Scalars['String'];
  region: Scalars['String'];
  streetAddress: Scalars['String'];
  suburb: Scalars['String'];
};

export type MedusaBaseProductCategory = {
  __typename?: 'MedusaBaseProductCategory';
  handle: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
};

export type MedusaCart = {
  __typename?: 'MedusaCart';
  discounts: Array<MedusaDiscount>;
  id: Scalars['String'];
  items: Array<MedusaLineItem>;
  payment_session: MedusaPaymentSession;
  subtotal: Scalars['Float'];
  total: Scalars['Float'];
};

export type MedusaDiscount = {
  __typename?: 'MedusaDiscount';
  code: Scalars['String'];
};

export type MedusaImage = {
  __typename?: 'MedusaImage';
  metadata: MedusaImageMetadata;
  url: Scalars['String'];
};

export type MedusaImageMetadata = {
  __typename?: 'MedusaImageMetadata';
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type MedusaLineItem = {
  __typename?: 'MedusaLineItem';
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  metadata?: Maybe<LineItemMetaData>;
  original_total: Scalars['Float'];
  quantity: Scalars['Float'];
  subtotal: Scalars['Float'];
  thumbnail?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  total: Scalars['Float'];
  unit_price: Scalars['Float'];
  variant_id: Scalars['String'];
};

export type MedusaLineItemInput = {
  id?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['Float']>;
  subscribe?: InputMaybe<Scalars['Boolean']>;
  variant_id?: InputMaybe<Scalars['String']>;
};

export type MedusaOrderAddress = {
  __typename?: 'MedusaOrderAddress';
  address_1?: Maybe<Scalars['String']>;
  address_2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
};

export type MedusaPaymentSession = {
  __typename?: 'MedusaPaymentSession';
  data: MedusaPaymentSessionData;
  provider_id: Scalars['String'];
};

export type MedusaPaymentSessionData = {
  __typename?: 'MedusaPaymentSessionData';
  url: Scalars['String'];
};

export type MedusaProduct = {
  __typename?: 'MedusaProduct';
  average_dose?: Maybe<Scalars['Float']>;
  bulk_discount_1?: Maybe<Scalars['Float']>;
  bulk_discount_2?: Maybe<Scalars['Float']>;
  bulk_purchase?: Maybe<Scalars['Boolean']>;
  categories: Array<MedusaProductCategory>;
  collection_id?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  directions?: Maybe<Scalars['String']>;
  disclaimer?: Maybe<Scalars['String']>;
  discountable: Scalars['Boolean'];
  handle: Scalars['String'];
  id: Scalars['String'];
  images?: Maybe<Array<MedusaImage>>;
  pre_order?: Maybe<Scalars['Boolean']>;
  product_details?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  subtitle?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  total_size?: Maybe<Scalars['Float']>;
  type_id?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  variants: Array<MedusaProductVariant>;
};

export type MedusaProductCategory = {
  __typename?: 'MedusaProductCategory';
  category_children?: Maybe<Array<MedusaBaseProductCategory>>;
  handle: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  parent_category?: Maybe<MedusaBaseProductCategory>;
};

export type MedusaProductCollection = {
  __typename?: 'MedusaProductCollection';
  handle: Scalars['String'];
  id: Scalars['String'];
  title: Scalars['String'];
};

export type MedusaProductFilterOptions = {
  __typename?: 'MedusaProductFilterOptions';
  collections: Array<MedusaProductCollection>;
  product_categories: Array<MedusaProductCategory>;
  product_types: Array<MedusaProductType>;
};

export type MedusaProductInput = {
  handle?: InputMaybe<Scalars['String']>;
};

export type MedusaProductRes = {
  __typename?: 'MedusaProductRes';
  count: Scalars['Float'];
  limit: Scalars['Float'];
  offset: Scalars['Float'];
  products?: Maybe<Array<MedusaProduct>>;
};

export type MedusaProductType = {
  __typename?: 'MedusaProductType';
  id: Scalars['String'];
  value: Scalars['String'];
};

export type MedusaProductVariant = {
  __typename?: 'MedusaProductVariant';
  calculated_price?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  inventory_quantity: Scalars['Float'];
  original_price?: Maybe<Scalars['String']>;
  prices: Array<MoneyAmount>;
  product_id: Scalars['String'];
  purchasable?: Maybe<Scalars['Boolean']>;
  title: Scalars['String'];
};

export type MoneyAmount = {
  __typename?: 'MoneyAmount';
  amount: Scalars['Float'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Add item(s) to current user's cart. */
  addToCart: Scalars['Boolean'];
  /** Adds current user to a specific products stock notification list. */
  addToNotifyProductList: NotifyProduct;
  /** Apply a coupon to current user's cart. */
  applyCoupon: Scalars['Boolean'];
  /** Complete checkout for current user. */
  completeCheckout: Scalars['Boolean'];
  /** Confirms a created un-confirmed Acuity appointment within our database. This is used as part of notifying users of upcoming appointments, and allows for staff to consolidate un-confirmed appointments */
  confirmAcuityAppointment: Appointment;
  /** Confirm patient's sign up. */
  confirmPatientSignup: AuthPayload;
  /** Creates an appointment within Acuity for the current user. */
  createAcuityAppointment: AcuityAppointment;
  /** Create initial Acuity appointment and sign up patient at the same time. */
  createInitialAcuityAppointment: User;
  /** Create payment session for checkout. */
  createPaymentSession: MedusaCart;
  login: AuthPayload;
  /** Marks all current user's notifications as read. */
  markAllNotificationsRead: Scalars['Boolean'];
  /** Remove item(s) from current user's cart. */
  removeFromCart: Scalars['Boolean'];
  /** Request password reset. */
  requestResetPassword: AuthPayload;
  /** Request THC product access for current user. */
  requestThcProductAccess: Scalars['Boolean'];
  /** Update user password. */
  resetPassword: AuthPayload;
  /** Sign up patient. */
  signupPatient: User;
  /** Update current user's patient address. */
  updateAddress: Patient;
  /** Update item(s) in current user's cart. */
  updateLineItem: Scalars['Boolean'];
};


export type MutationAddToCartArgs = {
  input: AddToCartInput;
};


export type MutationAddToNotifyProductListArgs = {
  input: AddToNotifyProductListInput;
};


export type MutationApplyCouponArgs = {
  input: ApplyCouponInput;
};


export type MutationCompleteCheckoutArgs = {
  input: CompleteCheckoutInput;
};


export type MutationConfirmAcuityAppointmentArgs = {
  input: ConfirmAcuityAppointmentInput;
};


export type MutationConfirmPatientSignupArgs = {
  input: ConfirmPatientSignupInput;
};


export type MutationCreateAcuityAppointmentArgs = {
  input: CreateAcuityAppointmentInput;
};


export type MutationCreateInitialAcuityAppointmentArgs = {
  input: CreateInitialAcuityAppointmentInput;
};


export type MutationCreatePaymentSessionArgs = {
  input: CreatePaymentSessionInput;
};


export type MutationLoginArgs = {
  input: LoginInput;
};


export type MutationRemoveFromCartArgs = {
  input: RemoveFromCartInput;
};


export type MutationRequestResetPasswordArgs = {
  input: RequestResetPasswordInput;
};


export type MutationRequestThcProductAccessArgs = {
  input: RequestThcProductAccessInput;
};


export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};


export type MutationSignupPatientArgs = {
  input: SignupPatientInput;
};


export type MutationUpdateAddressArgs = {
  input: UpdatePatientInput;
};


export type MutationUpdateLineItemArgs = {
  input: UpdateLineItemInput;
};

export type Notification = {
  __typename?: 'Notification';
  appointment?: Maybe<Appointment>;
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['String'];
  isRead: Scalars['Boolean'];
  link: Scalars['String'];
  type: Scalars['String'];
  user: User;
};

export type NotifyProduct = {
  __typename?: 'NotifyProduct';
  customerEmails: Array<Scalars['String']>;
  id: Scalars['String'];
  productId: Scalars['String'];
};

export type Patient = {
  __typename?: 'Patient';
  abnormalLiver: Scalars['Boolean'];
  allergies: Scalars['Boolean'];
  bipolar: Scalars['Boolean'];
  cardiovascularDisease: Scalars['Boolean'];
  cigarettes: Scalars['Boolean'];
  city: Scalars['String'];
  clinikoDoctorContactId?: Maybe<Scalars['String']>;
  clinikoId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  dob: Scalars['DateTime'];
  ethnicity: Scalars['String'];
  followUpDate?: Maybe<Scalars['DateTime']>;
  followUpInterval?: Maybe<Scalars['Float']>;
  gender: Scalars['String'];
  gpConsent: Scalars['Boolean'];
  gpEmail?: Maybe<Scalars['String']>;
  gpLocation: Scalars['String'];
  gpName: Scalars['String'];
  gramsPerDay?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isPrescribedThc?: Maybe<Scalars['Boolean']>;
  medicalCondition: Scalars['String'];
  nhi?: Maybe<Scalars['String']>;
  postCode: Scalars['String'];
  psychosisOrSchizophrenia: Scalars['Boolean'];
  region: Scalars['String'];
  selectedSubstanceAbuse?: Maybe<Scalars['String']>;
  selfMedicationHistory: Scalars['Boolean'];
  standardDrinks: Scalars['String'];
  streetAddress: Scalars['String'];
  substanceAbuse: Scalars['Boolean'];
  suburb: Scalars['String'];
  symptoms: Scalars['String'];
};

export type PatientIntakeInfo = {
  abnormalLiver: Scalars['Boolean'];
  allergies: Scalars['Boolean'];
  bipolar: Scalars['Boolean'];
  cardiovascularDisease: Scalars['Boolean'];
  cigarettes: Scalars['Boolean'];
  city: Scalars['String'];
  clinikoDoctorContactId?: InputMaybe<Scalars['String']>;
  customerId?: InputMaybe<Scalars['String']>;
  dob: Scalars['DateTime'];
  ethnicity: Scalars['String'];
  followUpDate?: InputMaybe<Scalars['DateTime']>;
  followUpInterval?: InputMaybe<Scalars['Float']>;
  gender: Scalars['String'];
  gpConsent: Scalars['Boolean'];
  gpEmail?: InputMaybe<Scalars['String']>;
  gpLocation: Scalars['String'];
  gpName: Scalars['String'];
  gramsPerDay?: InputMaybe<Scalars['String']>;
  isPrescribedThc?: InputMaybe<Scalars['Boolean']>;
  medicalCondition: Scalars['String'];
  nhi?: InputMaybe<Scalars['String']>;
  postCode: Scalars['String'];
  psychosisOrSchizophrenia: Scalars['Boolean'];
  region: Scalars['String'];
  selectedSubstanceAbuse?: InputMaybe<Scalars['String']>;
  selfMedicationHistory: Scalars['Boolean'];
  standardDrinks: Scalars['String'];
  streetAddress: Scalars['String'];
  substanceAbuse: Scalars['Boolean'];
  suburb: Scalars['String'];
  symptoms: Scalars['String'];
};

export type Prescription = {
  __typename?: 'Prescription';
  createdAt: Scalars['DateTime'];
  doctor: Doctor;
  id: Scalars['String'];
  itemQuantity: Scalars['Float'];
  patient: Patient;
  prescriptionItems: Array<PrescriptionItem>;
  totalQuantity: Scalars['Float'];
};

export type PrescriptionItem = {
  __typename?: 'PrescriptionItem';
  generatedDosageDesc?: Maybe<Scalars['String']>;
  handle: Scalars['String'];
  id: Scalars['String'];
  maximumDosage: Scalars['Float'];
  nzUlmCode: Scalars['String'];
  productVariantId: Scalars['String'];
  quantity: Scalars['Float'];
  thumbnail?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  totalCalculatedPrice: Scalars['Float'];
  totalOriginalPrice: Scalars['Float'];
  unit: Scalars['String'];
};

export type ProductFilterInput = {
  cost?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  price?: InputMaybe<Scalars['String']>;
  selectedBrandIds?: InputMaybe<Array<Scalars['String']>>;
  selectedCategoryIds?: InputMaybe<Array<Scalars['String']>>;
  selectedTypeIds?: InputMaybe<Array<Scalars['String']>>;
};

export type Query = {
  __typename?: 'Query';
  /** Fetches Acuity form data required for the front-end to display as part of a custom appointment booking form. */
  acuityAppointmentFormData: AcuityAppointmentFormData;
  /** Fetches existing Acuity appointments the current user has access to. */
  acuityAppointments: Array<AcuityAppointment>;
  /** Fetches Acuity available days, used as part of the booking form. */
  acuityAvailableAppointmentDays: Array<AcuityAvailableAppointmentDays>;
  acuityAvailableAppointmentTimes: Array<AcuityAvailableAppointmentTimes>;
  /** Fetch product alternatives for a single product. */
  alternativeProducts: MedusaProductRes;
  /** Fetch the current user's cart, if no active existing cart then creates a new cart instance. */
  cart: MedusaCart;
  /** Fetches fulfillment orders the current user has access to. */
  fulfillmentOrders: Array<FulfillmentOrder>;
  /** Fetches fulfillment warnings based on the current user's cart items. */
  fulfillmentWarnings: Array<FulfillmentWarnings>;
  /** Fetch if current user has any unread notifications. */
  isUnreadNotifications: Scalars['Boolean'];
  /** Fetch current user's notifications. */
  notifications: Array<Notification>;
  /** Fetch current user's patient data. */
  patient: Patient;
  /** Fetch current user's prescription(s) */
  prescriptions: Array<Prescription>;
  /** Fetch a single product */
  product: MedusaProduct;
  /** Fetch product filtering options. */
  productFilterOptions: MedusaProductFilterOptions;
  /** Fetch products, with optional product filtering input. */
  products: MedusaProductRes;
  /** Fetch product recommendations for a single product. */
  recommendedProducts: MedusaProductRes;
  /** Fetch items in the current user's cart, that are in a specific script (For review). */
  scriptCartItems: Array<ScriptCartItem>;
  /** Fetches upcoming fulfillment order releases for the current user. */
  upcomingReleases: Array<FulfillmentOrder>;
  /** Fetch current user. */
  user: User;
};


export type QueryAcuityAppointmentsArgs = {
  input: AcuityAppointmentsInput;
};


export type QueryAcuityAvailableAppointmentDaysArgs = {
  input: AcuityAvailableDaysInput;
};


export type QueryAcuityAvailableAppointmentTimesArgs = {
  input: AcuityAvailableTimesInput;
};


export type QueryAlternativeProductsArgs = {
  input: MedusaProductInput;
};


export type QueryProductArgs = {
  input: MedusaProductInput;
};


export type QueryProductsArgs = {
  input: ProductFilterInput;
};


export type QueryScriptCartItemsArgs = {
  input: ScriptCartItemsInput;
};

export type RemoveFromCartInput = {
  lineItems: Array<MedusaLineItemInput>;
};

export type RequestResetPasswordInput = {
  email: Scalars['String'];
};

export type RequestThcProductAccessInput = {
  productId: Scalars['String'];
};

export type ResetPasswordInput = {
  password: Scalars['String'];
  token: Scalars['String'];
};

export type ScriptCartItem = {
  __typename?: 'ScriptCartItem';
  lineItem: MedusaLineItem;
  product: MedusaProduct;
};

export type ScriptCartItemsInput = {
  variantIds: Array<Scalars['String']>;
};

export type SignupPatientInput = {
  basicInfo: BasicInfo;
};

export type UpdateLineItemInput = {
  lineItem: MedusaLineItemInput;
};

export type UpdatePatientInput = {
  city: Scalars['String'];
  postCode: Scalars['String'];
  region: Scalars['String'];
  streetAddress: Scalars['String'];
  suburb: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  doctor: Doctor;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  patient: Patient;
  phoneNumber: Scalars['String'];
  role: Scalars['String'];
};

export type UserFieldsFragment = { __typename?: 'User', id: string, email: string, firstName?: string | null, lastName?: string | null, phoneNumber: string, role: string };

export type ProductFieldsFragment = { __typename?: 'MedusaProduct', id: string, title: string, subtitle?: string | null, status: string, description?: string | null, handle: string, discountable: boolean, thumbnail?: string | null, collection_id?: string | null, type_id?: string | null, product_details?: string | null, directions?: string | null, disclaimer?: string | null, pre_order?: boolean | null, average_dose?: number | null, total_size?: number | null, unit?: string | null, bulk_purchase?: boolean | null, bulk_discount_1?: number | null, bulk_discount_2?: number | null, images?: Array<{ __typename?: 'MedusaImage', url: string, metadata: { __typename?: 'MedusaImageMetadata', name?: string | null, type?: string | null } }> | null, variants: Array<{ __typename?: 'MedusaProductVariant', id: string, title: string, product_id: string, purchasable?: boolean | null, original_price?: string | null, calculated_price?: string | null, inventory_quantity: number, prices: Array<{ __typename?: 'MoneyAmount', amount: number }> }>, categories: Array<{ __typename?: 'MedusaProductCategory', id: string, name: string, handle: string }> };

export type LineItemFieldsFragment = { __typename?: 'MedusaLineItem', id: string, quantity: number, title: string, description?: string | null, thumbnail?: string | null, unit_price: number, subtotal: number, total: number, original_total: number, variant_id: string, metadata?: { __typename?: 'LineItemMetaData', subscribe?: boolean | null, pre_order?: boolean | null } | null };

export type FulfillmentOrderItemFieldsFragment = { __typename?: 'FulfillmentOrderItem', id: string, thumbnail?: string | null, title: string, price: number, handle: string, productVariantId: string, quantity: number, isInPrescription: boolean, maximumDosageFlag: boolean };

export type FulfillmentOrderFieldsFragment = { __typename?: 'FulfillmentOrder', id: string, medusaOrderId: string, parentOrderId?: string | null, totalPrice: number, shippingNo: string, type: string, status: string, fulfillmentDate?: any | null, addressDetails: { __typename?: 'AddressDetails', shippingAddress: { __typename?: 'MedusaOrderAddress', address_1?: string | null, address_2?: string | null, company?: string | null, city?: string | null, country_code?: string | null, postal_code?: string | null }, billingAddress: { __typename?: 'MedusaOrderAddress', address_1?: string | null, address_2?: string | null, company?: string | null, city?: string | null, country_code?: string | null, postal_code?: string | null } }, fulfillmentOrderItems: Array<{ __typename?: 'FulfillmentOrderItem', id: string, thumbnail?: string | null, title: string, price: number, handle: string, productVariantId: string, quantity: number, isInPrescription: boolean, maximumDosageFlag: boolean }>, childOrders: Array<{ __typename?: 'FulfillmentOrder', id: string, medusaOrderId: string, parentOrderId?: string | null, totalPrice: number, shippingNo: string, type: string, status: string, fulfillmentDate?: any | null, fulfillmentOrderItems: Array<{ __typename?: 'FulfillmentOrderItem', id: string, thumbnail?: string | null, title: string, price: number, handle: string, productVariantId: string, quantity: number, isInPrescription: boolean, maximumDosageFlag: boolean }> }> };

export type LoginMutationVariables = Exact<{
  input: LoginInput;
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'AuthPayload', token: string, user: { __typename?: 'User', id: string, email: string, firstName?: string | null, lastName?: string | null, phoneNumber: string, role: string } } };

export type RequestResetPasswordMutationVariables = Exact<{
  input: RequestResetPasswordInput;
}>;


export type RequestResetPasswordMutation = { __typename?: 'Mutation', requestResetPassword: { __typename?: 'AuthPayload', user: { __typename?: 'User', id: string, email: string, firstName?: string | null, lastName?: string | null, phoneNumber: string, role: string } } };

export type ResetPasswordMutationVariables = Exact<{
  input: ResetPasswordInput;
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', resetPassword: { __typename?: 'AuthPayload', user: { __typename?: 'User', id: string, email: string, firstName?: string | null, lastName?: string | null, phoneNumber: string, role: string } } };

export type CreateInitialAcuityAppointmentMutationVariables = Exact<{
  input: CreateInitialAcuityAppointmentInput;
}>;


export type CreateInitialAcuityAppointmentMutation = { __typename?: 'Mutation', createInitialAcuityAppointment: { __typename?: 'User', firstName?: string | null, email: string } };

export type CreateAcuityAppointmentMutationVariables = Exact<{
  input: CreateAcuityAppointmentInput;
}>;


export type CreateAcuityAppointmentMutation = { __typename?: 'Mutation', createAcuityAppointment: { __typename?: 'AcuityAppointment', confirmationPage: string } };

export type SignupPatientMutationVariables = Exact<{
  input: SignupPatientInput;
}>;


export type SignupPatientMutation = { __typename?: 'Mutation', signupPatient: { __typename?: 'User', firstName?: string | null, email: string } };

export type ConfirmPatientSignupMutationVariables = Exact<{
  input: ConfirmPatientSignupInput;
}>;


export type ConfirmPatientSignupMutation = { __typename?: 'Mutation', confirmPatientSignup: { __typename?: 'AuthPayload', token: string } };

export type MarkAllNotificationsReadMutationVariables = Exact<{ [key: string]: never; }>;


export type MarkAllNotificationsReadMutation = { __typename?: 'Mutation', markAllNotificationsRead: boolean };

export type ConfirmAcuityAppointmentMutationVariables = Exact<{
  input: ConfirmAcuityAppointmentInput;
}>;


export type ConfirmAcuityAppointmentMutation = { __typename?: 'Mutation', confirmAcuityAppointment: { __typename?: 'Appointment', confirmed: boolean } };

export type AddToCartMutationVariables = Exact<{
  input: AddToCartInput;
}>;


export type AddToCartMutation = { __typename?: 'Mutation', addToCart: boolean };

export type RemoveFromCartMutationVariables = Exact<{
  input: RemoveFromCartInput;
}>;


export type RemoveFromCartMutation = { __typename?: 'Mutation', removeFromCart: boolean };

export type UpdateLineItemMutationVariables = Exact<{
  input: UpdateLineItemInput;
}>;


export type UpdateLineItemMutation = { __typename?: 'Mutation', updateLineItem: boolean };

export type AddToNotifyProductListMutationVariables = Exact<{
  input: AddToNotifyProductListInput;
}>;


export type AddToNotifyProductListMutation = { __typename?: 'Mutation', addToNotifyProductList: { __typename?: 'NotifyProduct', id: string, productId: string } };

export type RequestThcProductAccessMutationVariables = Exact<{
  input: RequestThcProductAccessInput;
}>;


export type RequestThcProductAccessMutation = { __typename?: 'Mutation', requestThcProductAccess: boolean };

export type ApplyCouponMutationVariables = Exact<{
  input: ApplyCouponInput;
}>;


export type ApplyCouponMutation = { __typename?: 'Mutation', applyCoupon: boolean };

export type CreatePaymentSessionMutationVariables = Exact<{
  input: CreatePaymentSessionInput;
}>;


export type CreatePaymentSessionMutation = { __typename?: 'Mutation', createPaymentSession: { __typename?: 'MedusaCart', payment_session: { __typename?: 'MedusaPaymentSession', provider_id: string, data: { __typename?: 'MedusaPaymentSessionData', url: string } } } };

export type UpdateAddressMutationVariables = Exact<{
  input: UpdatePatientInput;
}>;


export type UpdateAddressMutation = { __typename?: 'Mutation', updateAddress: { __typename?: 'Patient', id: string } };

export type CompleteCheckoutMutationVariables = Exact<{
  input: CompleteCheckoutInput;
}>;


export type CompleteCheckoutMutation = { __typename?: 'Mutation', completeCheckout: boolean };

export type AcuityAppointmentFormDataQueryVariables = Exact<{ [key: string]: never; }>;


export type AcuityAppointmentFormDataQuery = { __typename?: 'Query', acuityAppointmentFormData: { __typename?: 'AcuityAppointmentFormData', appointmentCategories: Array<{ __typename?: 'AcuityAppointmentCategories', name: string }>, appointmentTypes: Array<{ __typename?: 'AcuityAppointmentType', id: number, name: string, active: boolean, description: string, duration: number, price: string, color: string, private: boolean, type: string, schedulingUrl: string, image: string, classSize?: string | null, paddingAfter: number, paddingBefore: number, calendarIDs: Array<number>, addonIDs: Array<string>, formIDs: Array<string> }>, calendars: Array<{ __typename?: 'AcuityCalendar', id: number, name: string, email: string, replyTo: string, description: string, location: string, timezone: string }> } };

export type AcuityAvailableAppointmentDaysQueryVariables = Exact<{
  input: AcuityAvailableDaysInput;
}>;


export type AcuityAvailableAppointmentDaysQuery = { __typename?: 'Query', acuityAvailableAppointmentDays: Array<{ __typename?: 'AcuityAvailableAppointmentDays', date: string, times: Array<{ __typename?: 'AcuityAvailableAppointmentTimes', time: string }> }> };

export type AcuityAppointmentsQueryVariables = Exact<{
  input: AcuityAppointmentsInput;
}>;


export type AcuityAppointmentsQuery = { __typename?: 'Query', acuityAppointments: Array<{ __typename?: 'AcuityAppointment', date: string, time: string, type: string, confirmationPage: string, calendar: string }> };

export type IsUnreadNotificationsQueryVariables = Exact<{ [key: string]: never; }>;


export type IsUnreadNotificationsQuery = { __typename?: 'Query', isUnreadNotifications: boolean };

export type NotificationsQueryVariables = Exact<{ [key: string]: never; }>;


export type NotificationsQuery = { __typename?: 'Query', notifications: Array<{ __typename?: 'Notification', id: string, description: string, isRead: boolean, link: string, createdAt: any, appointment?: { __typename?: 'Appointment', id: string, confirmed: boolean } | null }> };

export type ProductsQueryVariables = Exact<{
  input: ProductFilterInput;
}>;


export type ProductsQuery = { __typename?: 'Query', products: { __typename?: 'MedusaProductRes', count: number, limit: number, offset: number, products?: Array<{ __typename?: 'MedusaProduct', id: string, title: string, subtitle?: string | null, status: string, description?: string | null, handle: string, discountable: boolean, thumbnail?: string | null, collection_id?: string | null, type_id?: string | null, product_details?: string | null, directions?: string | null, disclaimer?: string | null, pre_order?: boolean | null, average_dose?: number | null, total_size?: number | null, unit?: string | null, bulk_purchase?: boolean | null, bulk_discount_1?: number | null, bulk_discount_2?: number | null, images?: Array<{ __typename?: 'MedusaImage', url: string, metadata: { __typename?: 'MedusaImageMetadata', name?: string | null, type?: string | null } }> | null, variants: Array<{ __typename?: 'MedusaProductVariant', id: string, title: string, product_id: string, purchasable?: boolean | null, original_price?: string | null, calculated_price?: string | null, inventory_quantity: number, prices: Array<{ __typename?: 'MoneyAmount', amount: number }> }>, categories: Array<{ __typename?: 'MedusaProductCategory', id: string, name: string, handle: string }> }> | null } };

export type ProductQueryVariables = Exact<{
  input: MedusaProductInput;
}>;


export type ProductQuery = { __typename?: 'Query', product: { __typename?: 'MedusaProduct', id: string, title: string, subtitle?: string | null, status: string, description?: string | null, handle: string, discountable: boolean, thumbnail?: string | null, collection_id?: string | null, type_id?: string | null, product_details?: string | null, directions?: string | null, disclaimer?: string | null, pre_order?: boolean | null, average_dose?: number | null, total_size?: number | null, unit?: string | null, bulk_purchase?: boolean | null, bulk_discount_1?: number | null, bulk_discount_2?: number | null, images?: Array<{ __typename?: 'MedusaImage', url: string, metadata: { __typename?: 'MedusaImageMetadata', name?: string | null, type?: string | null } }> | null, variants: Array<{ __typename?: 'MedusaProductVariant', id: string, title: string, product_id: string, purchasable?: boolean | null, original_price?: string | null, calculated_price?: string | null, inventory_quantity: number, prices: Array<{ __typename?: 'MoneyAmount', amount: number }> }>, categories: Array<{ __typename?: 'MedusaProductCategory', id: string, name: string, handle: string }> } };

export type AlternativeProductsQueryVariables = Exact<{
  input: MedusaProductInput;
}>;


export type AlternativeProductsQuery = { __typename?: 'Query', alternativeProducts: { __typename?: 'MedusaProductRes', count: number, limit: number, offset: number, products?: Array<{ __typename?: 'MedusaProduct', id: string, title: string, subtitle?: string | null, status: string, description?: string | null, handle: string, discountable: boolean, thumbnail?: string | null, collection_id?: string | null, type_id?: string | null, product_details?: string | null, directions?: string | null, disclaimer?: string | null, pre_order?: boolean | null, average_dose?: number | null, total_size?: number | null, unit?: string | null, bulk_purchase?: boolean | null, bulk_discount_1?: number | null, bulk_discount_2?: number | null, images?: Array<{ __typename?: 'MedusaImage', url: string, metadata: { __typename?: 'MedusaImageMetadata', name?: string | null, type?: string | null } }> | null, variants: Array<{ __typename?: 'MedusaProductVariant', id: string, title: string, product_id: string, purchasable?: boolean | null, original_price?: string | null, calculated_price?: string | null, inventory_quantity: number, prices: Array<{ __typename?: 'MoneyAmount', amount: number }> }>, categories: Array<{ __typename?: 'MedusaProductCategory', id: string, name: string, handle: string }> }> | null } };

export type RecommendedProductsQueryVariables = Exact<{ [key: string]: never; }>;


export type RecommendedProductsQuery = { __typename?: 'Query', recommendedProducts: { __typename?: 'MedusaProductRes', count: number, limit: number, offset: number, products?: Array<{ __typename?: 'MedusaProduct', id: string, title: string, subtitle?: string | null, status: string, description?: string | null, handle: string, discountable: boolean, thumbnail?: string | null, collection_id?: string | null, type_id?: string | null, product_details?: string | null, directions?: string | null, disclaimer?: string | null, pre_order?: boolean | null, average_dose?: number | null, total_size?: number | null, unit?: string | null, bulk_purchase?: boolean | null, bulk_discount_1?: number | null, bulk_discount_2?: number | null, images?: Array<{ __typename?: 'MedusaImage', url: string, metadata: { __typename?: 'MedusaImageMetadata', name?: string | null, type?: string | null } }> | null, variants: Array<{ __typename?: 'MedusaProductVariant', id: string, title: string, product_id: string, purchasable?: boolean | null, original_price?: string | null, calculated_price?: string | null, inventory_quantity: number, prices: Array<{ __typename?: 'MoneyAmount', amount: number }> }>, categories: Array<{ __typename?: 'MedusaProductCategory', id: string, name: string, handle: string }> }> | null } };

export type ProductFilterOptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type ProductFilterOptionsQuery = { __typename?: 'Query', productFilterOptions: { __typename?: 'MedusaProductFilterOptions', collections: Array<{ __typename?: 'MedusaProductCollection', id: string, title: string, handle: string }>, product_categories: Array<{ __typename?: 'MedusaProductCategory', id: string, name: string, handle: string, parent_category?: { __typename?: 'MedusaBaseProductCategory', id: string, name: string, handle: string } | null, category_children?: Array<{ __typename?: 'MedusaBaseProductCategory', id: string, name: string, handle: string }> | null }>, product_types: Array<{ __typename?: 'MedusaProductType', id: string, value: string }> } };

export type CartQueryVariables = Exact<{ [key: string]: never; }>;


export type CartQuery = { __typename?: 'Query', cart: { __typename?: 'MedusaCart', id: string, total: number, subtotal: number, items: Array<{ __typename?: 'MedusaLineItem', id: string, quantity: number, title: string, description?: string | null, thumbnail?: string | null, unit_price: number, subtotal: number, total: number, original_total: number, variant_id: string, metadata?: { __typename?: 'LineItemMetaData', subscribe?: boolean | null, pre_order?: boolean | null } | null }>, discounts: Array<{ __typename?: 'MedusaDiscount', code: string }> } };

export type UserQueryVariables = Exact<{ [key: string]: never; }>;


export type UserQuery = { __typename?: 'Query', user: { __typename?: 'User', id: string, email: string, phoneNumber: string, firstName?: string | null, lastName?: string | null, patient: { __typename?: 'Patient', id: string, isPrescribedThc?: boolean | null, streetAddress: string, suburb: string, region: string, city: string, postCode: string, dob: any, gender: string } } };

export type PatientQueryVariables = Exact<{ [key: string]: never; }>;


export type PatientQuery = { __typename?: 'Query', patient: { __typename?: 'Patient', id: string, isPrescribedThc?: boolean | null, followUpInterval?: number | null, followUpDate?: any | null } };

export type FulfillmentOrdersQueryVariables = Exact<{ [key: string]: never; }>;


export type FulfillmentOrdersQuery = { __typename?: 'Query', fulfillmentOrders: Array<{ __typename?: 'FulfillmentOrder', id: string, medusaOrderId: string, parentOrderId?: string | null, totalPrice: number, shippingNo: string, type: string, status: string, fulfillmentDate?: any | null, addressDetails: { __typename?: 'AddressDetails', shippingAddress: { __typename?: 'MedusaOrderAddress', address_1?: string | null, address_2?: string | null, company?: string | null, city?: string | null, country_code?: string | null, postal_code?: string | null }, billingAddress: { __typename?: 'MedusaOrderAddress', address_1?: string | null, address_2?: string | null, company?: string | null, city?: string | null, country_code?: string | null, postal_code?: string | null } }, fulfillmentOrderItems: Array<{ __typename?: 'FulfillmentOrderItem', id: string, thumbnail?: string | null, title: string, price: number, handle: string, productVariantId: string, quantity: number, isInPrescription: boolean, maximumDosageFlag: boolean }>, childOrders: Array<{ __typename?: 'FulfillmentOrder', id: string, medusaOrderId: string, parentOrderId?: string | null, totalPrice: number, shippingNo: string, type: string, status: string, fulfillmentDate?: any | null, fulfillmentOrderItems: Array<{ __typename?: 'FulfillmentOrderItem', id: string, thumbnail?: string | null, title: string, price: number, handle: string, productVariantId: string, quantity: number, isInPrescription: boolean, maximumDosageFlag: boolean }> }> }> };

export type UpcomingReleasesQueryVariables = Exact<{ [key: string]: never; }>;


export type UpcomingReleasesQuery = { __typename?: 'Query', upcomingReleases: Array<{ __typename?: 'FulfillmentOrder', id: string, medusaOrderId: string, parentOrderId?: string | null, totalPrice: number, shippingNo: string, type: string, status: string, fulfillmentDate?: any | null, addressDetails: { __typename?: 'AddressDetails', shippingAddress: { __typename?: 'MedusaOrderAddress', address_1?: string | null, address_2?: string | null, company?: string | null, city?: string | null, country_code?: string | null, postal_code?: string | null }, billingAddress: { __typename?: 'MedusaOrderAddress', address_1?: string | null, address_2?: string | null, company?: string | null, city?: string | null, country_code?: string | null, postal_code?: string | null } }, fulfillmentOrderItems: Array<{ __typename?: 'FulfillmentOrderItem', id: string, thumbnail?: string | null, title: string, price: number, handle: string, productVariantId: string, quantity: number, isInPrescription: boolean, maximumDosageFlag: boolean }>, childOrders: Array<{ __typename?: 'FulfillmentOrder', id: string, medusaOrderId: string, parentOrderId?: string | null, totalPrice: number, shippingNo: string, type: string, status: string, fulfillmentDate?: any | null, fulfillmentOrderItems: Array<{ __typename?: 'FulfillmentOrderItem', id: string, thumbnail?: string | null, title: string, price: number, handle: string, productVariantId: string, quantity: number, isInPrescription: boolean, maximumDosageFlag: boolean }> }> }> };

export type FulfillmentWarningsQueryVariables = Exact<{ [key: string]: never; }>;


export type FulfillmentWarningsQuery = { __typename?: 'Query', fulfillmentWarnings: Array<{ __typename?: 'FulfillmentWarnings', title: string, type: FulfillmentWarningType }> };

export type PrescriptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type PrescriptionsQuery = { __typename?: 'Query', prescriptions: Array<{ __typename?: 'Prescription', id: string, itemQuantity: number, createdAt: any, doctor: { __typename?: 'Doctor', user: { __typename?: 'User', email: string, phoneNumber: string, firstName?: string | null, lastName?: string | null } }, prescriptionItems: Array<{ __typename?: 'PrescriptionItem', productVariantId: string, title: string, generatedDosageDesc?: string | null, nzUlmCode: string, quantity: number, unit: string, maximumDosage: number, thumbnail?: string | null, handle: string, totalCalculatedPrice: number, totalOriginalPrice: number }> }> };

export type ScriptCartItemsQueryVariables = Exact<{
  input: ScriptCartItemsInput;
}>;


export type ScriptCartItemsQuery = { __typename?: 'Query', scriptCartItems: Array<{ __typename?: 'ScriptCartItem', lineItem: { __typename?: 'MedusaLineItem', id: string, quantity: number, title: string, description?: string | null, thumbnail?: string | null, unit_price: number, subtotal: number, total: number, original_total: number, variant_id: string, metadata?: { __typename?: 'LineItemMetaData', subscribe?: boolean | null, pre_order?: boolean | null } | null }, product: { __typename?: 'MedusaProduct', id: string, title: string, subtitle?: string | null, status: string, description?: string | null, handle: string, discountable: boolean, thumbnail?: string | null, collection_id?: string | null, type_id?: string | null, product_details?: string | null, directions?: string | null, disclaimer?: string | null, pre_order?: boolean | null, average_dose?: number | null, total_size?: number | null, unit?: string | null, bulk_purchase?: boolean | null, bulk_discount_1?: number | null, bulk_discount_2?: number | null, images?: Array<{ __typename?: 'MedusaImage', url: string, metadata: { __typename?: 'MedusaImageMetadata', name?: string | null, type?: string | null } }> | null, variants: Array<{ __typename?: 'MedusaProductVariant', id: string, title: string, product_id: string, purchasable?: boolean | null, original_price?: string | null, calculated_price?: string | null, inventory_quantity: number, prices: Array<{ __typename?: 'MoneyAmount', amount: number }> }>, categories: Array<{ __typename?: 'MedusaProductCategory', id: string, name: string, handle: string }> } }> };

export const UserFieldsFragmentDoc = gql`
    fragment UserFields on User {
  id
  email
  firstName
  lastName
  phoneNumber
  role
}
    `;
export const ProductFieldsFragmentDoc = gql`
    fragment ProductFields on MedusaProduct {
  id
  title
  subtitle
  status
  description
  handle
  discountable
  thumbnail
  images {
    url
    metadata {
      name
      type
    }
  }
  collection_id
  type_id
  product_details
  directions
  disclaimer
  variants {
    id
    title
    product_id
    purchasable
    original_price
    calculated_price
    inventory_quantity
    prices {
      amount
    }
  }
  categories {
    id
    name
    handle
  }
  pre_order
  average_dose
  total_size
  unit
  bulk_purchase
  bulk_discount_1
  bulk_discount_2
}
    `;
export const LineItemFieldsFragmentDoc = gql`
    fragment LineItemFields on MedusaLineItem {
  id
  quantity
  title
  description
  thumbnail
  unit_price
  subtotal
  total
  original_total
  variant_id
  metadata {
    subscribe
    pre_order
  }
}
    `;
export const FulfillmentOrderItemFieldsFragmentDoc = gql`
    fragment FulfillmentOrderItemFields on FulfillmentOrderItem {
  id
  thumbnail
  title
  price
  handle
  productVariantId
  quantity
  isInPrescription
  maximumDosageFlag
}
    `;
export const FulfillmentOrderFieldsFragmentDoc = gql`
    fragment FulfillmentOrderFields on FulfillmentOrder {
  id
  medusaOrderId
  parentOrderId
  totalPrice
  shippingNo
  type
  status
  fulfillmentDate
  addressDetails {
    shippingAddress {
      address_1
      address_2
      company
      city
      country_code
      postal_code
    }
    billingAddress {
      address_1
      address_2
      company
      city
      country_code
      postal_code
    }
  }
  fulfillmentOrderItems {
    ...FulfillmentOrderItemFields
  }
  childOrders {
    id
    medusaOrderId
    parentOrderId
    totalPrice
    shippingNo
    type
    status
    fulfillmentDate
    fulfillmentOrderItems {
      ...FulfillmentOrderItemFields
    }
  }
}
    ${FulfillmentOrderItemFieldsFragmentDoc}`;
export const LoginDocument = gql`
    mutation Login($input: LoginInput!) {
  login(input: $input) {
    token
    user {
      ...UserFields
    }
  }
}
    ${UserFieldsFragmentDoc}`;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const RequestResetPasswordDocument = gql`
    mutation RequestResetPassword($input: RequestResetPasswordInput!) {
  requestResetPassword(input: $input) {
    user {
      ...UserFields
    }
  }
}
    ${UserFieldsFragmentDoc}`;
export type RequestResetPasswordMutationFn = Apollo.MutationFunction<RequestResetPasswordMutation, RequestResetPasswordMutationVariables>;

/**
 * __useRequestResetPasswordMutation__
 *
 * To run a mutation, you first call `useRequestResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestResetPasswordMutation, { data, loading, error }] = useRequestResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<RequestResetPasswordMutation, RequestResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestResetPasswordMutation, RequestResetPasswordMutationVariables>(RequestResetPasswordDocument, options);
      }
export type RequestResetPasswordMutationHookResult = ReturnType<typeof useRequestResetPasswordMutation>;
export type RequestResetPasswordMutationResult = Apollo.MutationResult<RequestResetPasswordMutation>;
export type RequestResetPasswordMutationOptions = Apollo.BaseMutationOptions<RequestResetPasswordMutation, RequestResetPasswordMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($input: ResetPasswordInput!) {
  resetPassword(input: $input) {
    user {
      ...UserFields
    }
  }
}
    ${UserFieldsFragmentDoc}`;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const CreateInitialAcuityAppointmentDocument = gql`
    mutation CreateInitialAcuityAppointment($input: CreateInitialAcuityAppointmentInput!) {
  createInitialAcuityAppointment(input: $input) {
    firstName
    email
  }
}
    `;
export type CreateInitialAcuityAppointmentMutationFn = Apollo.MutationFunction<CreateInitialAcuityAppointmentMutation, CreateInitialAcuityAppointmentMutationVariables>;

/**
 * __useCreateInitialAcuityAppointmentMutation__
 *
 * To run a mutation, you first call `useCreateInitialAcuityAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInitialAcuityAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInitialAcuityAppointmentMutation, { data, loading, error }] = useCreateInitialAcuityAppointmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInitialAcuityAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<CreateInitialAcuityAppointmentMutation, CreateInitialAcuityAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInitialAcuityAppointmentMutation, CreateInitialAcuityAppointmentMutationVariables>(CreateInitialAcuityAppointmentDocument, options);
      }
export type CreateInitialAcuityAppointmentMutationHookResult = ReturnType<typeof useCreateInitialAcuityAppointmentMutation>;
export type CreateInitialAcuityAppointmentMutationResult = Apollo.MutationResult<CreateInitialAcuityAppointmentMutation>;
export type CreateInitialAcuityAppointmentMutationOptions = Apollo.BaseMutationOptions<CreateInitialAcuityAppointmentMutation, CreateInitialAcuityAppointmentMutationVariables>;
export const CreateAcuityAppointmentDocument = gql`
    mutation CreateAcuityAppointment($input: CreateAcuityAppointmentInput!) {
  createAcuityAppointment(input: $input) {
    confirmationPage
  }
}
    `;
export type CreateAcuityAppointmentMutationFn = Apollo.MutationFunction<CreateAcuityAppointmentMutation, CreateAcuityAppointmentMutationVariables>;

/**
 * __useCreateAcuityAppointmentMutation__
 *
 * To run a mutation, you first call `useCreateAcuityAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAcuityAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAcuityAppointmentMutation, { data, loading, error }] = useCreateAcuityAppointmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAcuityAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<CreateAcuityAppointmentMutation, CreateAcuityAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAcuityAppointmentMutation, CreateAcuityAppointmentMutationVariables>(CreateAcuityAppointmentDocument, options);
      }
export type CreateAcuityAppointmentMutationHookResult = ReturnType<typeof useCreateAcuityAppointmentMutation>;
export type CreateAcuityAppointmentMutationResult = Apollo.MutationResult<CreateAcuityAppointmentMutation>;
export type CreateAcuityAppointmentMutationOptions = Apollo.BaseMutationOptions<CreateAcuityAppointmentMutation, CreateAcuityAppointmentMutationVariables>;
export const SignupPatientDocument = gql`
    mutation SignupPatient($input: SignupPatientInput!) {
  signupPatient(input: $input) {
    firstName
    email
  }
}
    `;
export type SignupPatientMutationFn = Apollo.MutationFunction<SignupPatientMutation, SignupPatientMutationVariables>;

/**
 * __useSignupPatientMutation__
 *
 * To run a mutation, you first call `useSignupPatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupPatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupPatientMutation, { data, loading, error }] = useSignupPatientMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignupPatientMutation(baseOptions?: Apollo.MutationHookOptions<SignupPatientMutation, SignupPatientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignupPatientMutation, SignupPatientMutationVariables>(SignupPatientDocument, options);
      }
export type SignupPatientMutationHookResult = ReturnType<typeof useSignupPatientMutation>;
export type SignupPatientMutationResult = Apollo.MutationResult<SignupPatientMutation>;
export type SignupPatientMutationOptions = Apollo.BaseMutationOptions<SignupPatientMutation, SignupPatientMutationVariables>;
export const ConfirmPatientSignupDocument = gql`
    mutation ConfirmPatientSignup($input: ConfirmPatientSignupInput!) {
  confirmPatientSignup(input: $input) {
    token
  }
}
    `;
export type ConfirmPatientSignupMutationFn = Apollo.MutationFunction<ConfirmPatientSignupMutation, ConfirmPatientSignupMutationVariables>;

/**
 * __useConfirmPatientSignupMutation__
 *
 * To run a mutation, you first call `useConfirmPatientSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmPatientSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmPatientSignupMutation, { data, loading, error }] = useConfirmPatientSignupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfirmPatientSignupMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmPatientSignupMutation, ConfirmPatientSignupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfirmPatientSignupMutation, ConfirmPatientSignupMutationVariables>(ConfirmPatientSignupDocument, options);
      }
export type ConfirmPatientSignupMutationHookResult = ReturnType<typeof useConfirmPatientSignupMutation>;
export type ConfirmPatientSignupMutationResult = Apollo.MutationResult<ConfirmPatientSignupMutation>;
export type ConfirmPatientSignupMutationOptions = Apollo.BaseMutationOptions<ConfirmPatientSignupMutation, ConfirmPatientSignupMutationVariables>;
export const MarkAllNotificationsReadDocument = gql`
    mutation MarkAllNotificationsRead {
  markAllNotificationsRead
}
    `;
export type MarkAllNotificationsReadMutationFn = Apollo.MutationFunction<MarkAllNotificationsReadMutation, MarkAllNotificationsReadMutationVariables>;

/**
 * __useMarkAllNotificationsReadMutation__
 *
 * To run a mutation, you first call `useMarkAllNotificationsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAllNotificationsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAllNotificationsReadMutation, { data, loading, error }] = useMarkAllNotificationsReadMutation({
 *   variables: {
 *   },
 * });
 */
export function useMarkAllNotificationsReadMutation(baseOptions?: Apollo.MutationHookOptions<MarkAllNotificationsReadMutation, MarkAllNotificationsReadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkAllNotificationsReadMutation, MarkAllNotificationsReadMutationVariables>(MarkAllNotificationsReadDocument, options);
      }
export type MarkAllNotificationsReadMutationHookResult = ReturnType<typeof useMarkAllNotificationsReadMutation>;
export type MarkAllNotificationsReadMutationResult = Apollo.MutationResult<MarkAllNotificationsReadMutation>;
export type MarkAllNotificationsReadMutationOptions = Apollo.BaseMutationOptions<MarkAllNotificationsReadMutation, MarkAllNotificationsReadMutationVariables>;
export const ConfirmAcuityAppointmentDocument = gql`
    mutation ConfirmAcuityAppointment($input: ConfirmAcuityAppointmentInput!) {
  confirmAcuityAppointment(input: $input) {
    confirmed
  }
}
    `;
export type ConfirmAcuityAppointmentMutationFn = Apollo.MutationFunction<ConfirmAcuityAppointmentMutation, ConfirmAcuityAppointmentMutationVariables>;

/**
 * __useConfirmAcuityAppointmentMutation__
 *
 * To run a mutation, you first call `useConfirmAcuityAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmAcuityAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmAcuityAppointmentMutation, { data, loading, error }] = useConfirmAcuityAppointmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfirmAcuityAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmAcuityAppointmentMutation, ConfirmAcuityAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfirmAcuityAppointmentMutation, ConfirmAcuityAppointmentMutationVariables>(ConfirmAcuityAppointmentDocument, options);
      }
export type ConfirmAcuityAppointmentMutationHookResult = ReturnType<typeof useConfirmAcuityAppointmentMutation>;
export type ConfirmAcuityAppointmentMutationResult = Apollo.MutationResult<ConfirmAcuityAppointmentMutation>;
export type ConfirmAcuityAppointmentMutationOptions = Apollo.BaseMutationOptions<ConfirmAcuityAppointmentMutation, ConfirmAcuityAppointmentMutationVariables>;
export const AddToCartDocument = gql`
    mutation AddToCart($input: AddToCartInput!) {
  addToCart(input: $input)
}
    `;
export type AddToCartMutationFn = Apollo.MutationFunction<AddToCartMutation, AddToCartMutationVariables>;

/**
 * __useAddToCartMutation__
 *
 * To run a mutation, you first call `useAddToCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddToCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addToCartMutation, { data, loading, error }] = useAddToCartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddToCartMutation(baseOptions?: Apollo.MutationHookOptions<AddToCartMutation, AddToCartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddToCartMutation, AddToCartMutationVariables>(AddToCartDocument, options);
      }
export type AddToCartMutationHookResult = ReturnType<typeof useAddToCartMutation>;
export type AddToCartMutationResult = Apollo.MutationResult<AddToCartMutation>;
export type AddToCartMutationOptions = Apollo.BaseMutationOptions<AddToCartMutation, AddToCartMutationVariables>;
export const RemoveFromCartDocument = gql`
    mutation RemoveFromCart($input: RemoveFromCartInput!) {
  removeFromCart(input: $input)
}
    `;
export type RemoveFromCartMutationFn = Apollo.MutationFunction<RemoveFromCartMutation, RemoveFromCartMutationVariables>;

/**
 * __useRemoveFromCartMutation__
 *
 * To run a mutation, you first call `useRemoveFromCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFromCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFromCartMutation, { data, loading, error }] = useRemoveFromCartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveFromCartMutation(baseOptions?: Apollo.MutationHookOptions<RemoveFromCartMutation, RemoveFromCartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveFromCartMutation, RemoveFromCartMutationVariables>(RemoveFromCartDocument, options);
      }
export type RemoveFromCartMutationHookResult = ReturnType<typeof useRemoveFromCartMutation>;
export type RemoveFromCartMutationResult = Apollo.MutationResult<RemoveFromCartMutation>;
export type RemoveFromCartMutationOptions = Apollo.BaseMutationOptions<RemoveFromCartMutation, RemoveFromCartMutationVariables>;
export const UpdateLineItemDocument = gql`
    mutation UpdateLineItem($input: UpdateLineItemInput!) {
  updateLineItem(input: $input)
}
    `;
export type UpdateLineItemMutationFn = Apollo.MutationFunction<UpdateLineItemMutation, UpdateLineItemMutationVariables>;

/**
 * __useUpdateLineItemMutation__
 *
 * To run a mutation, you first call `useUpdateLineItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLineItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLineItemMutation, { data, loading, error }] = useUpdateLineItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLineItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLineItemMutation, UpdateLineItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLineItemMutation, UpdateLineItemMutationVariables>(UpdateLineItemDocument, options);
      }
export type UpdateLineItemMutationHookResult = ReturnType<typeof useUpdateLineItemMutation>;
export type UpdateLineItemMutationResult = Apollo.MutationResult<UpdateLineItemMutation>;
export type UpdateLineItemMutationOptions = Apollo.BaseMutationOptions<UpdateLineItemMutation, UpdateLineItemMutationVariables>;
export const AddToNotifyProductListDocument = gql`
    mutation AddToNotifyProductList($input: AddToNotifyProductListInput!) {
  addToNotifyProductList(input: $input) {
    id
    productId
  }
}
    `;
export type AddToNotifyProductListMutationFn = Apollo.MutationFunction<AddToNotifyProductListMutation, AddToNotifyProductListMutationVariables>;

/**
 * __useAddToNotifyProductListMutation__
 *
 * To run a mutation, you first call `useAddToNotifyProductListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddToNotifyProductListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addToNotifyProductListMutation, { data, loading, error }] = useAddToNotifyProductListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddToNotifyProductListMutation(baseOptions?: Apollo.MutationHookOptions<AddToNotifyProductListMutation, AddToNotifyProductListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddToNotifyProductListMutation, AddToNotifyProductListMutationVariables>(AddToNotifyProductListDocument, options);
      }
export type AddToNotifyProductListMutationHookResult = ReturnType<typeof useAddToNotifyProductListMutation>;
export type AddToNotifyProductListMutationResult = Apollo.MutationResult<AddToNotifyProductListMutation>;
export type AddToNotifyProductListMutationOptions = Apollo.BaseMutationOptions<AddToNotifyProductListMutation, AddToNotifyProductListMutationVariables>;
export const RequestThcProductAccessDocument = gql`
    mutation RequestThcProductAccess($input: RequestThcProductAccessInput!) {
  requestThcProductAccess(input: $input)
}
    `;
export type RequestThcProductAccessMutationFn = Apollo.MutationFunction<RequestThcProductAccessMutation, RequestThcProductAccessMutationVariables>;

/**
 * __useRequestThcProductAccessMutation__
 *
 * To run a mutation, you first call `useRequestThcProductAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestThcProductAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestThcProductAccessMutation, { data, loading, error }] = useRequestThcProductAccessMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestThcProductAccessMutation(baseOptions?: Apollo.MutationHookOptions<RequestThcProductAccessMutation, RequestThcProductAccessMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestThcProductAccessMutation, RequestThcProductAccessMutationVariables>(RequestThcProductAccessDocument, options);
      }
export type RequestThcProductAccessMutationHookResult = ReturnType<typeof useRequestThcProductAccessMutation>;
export type RequestThcProductAccessMutationResult = Apollo.MutationResult<RequestThcProductAccessMutation>;
export type RequestThcProductAccessMutationOptions = Apollo.BaseMutationOptions<RequestThcProductAccessMutation, RequestThcProductAccessMutationVariables>;
export const ApplyCouponDocument = gql`
    mutation ApplyCoupon($input: ApplyCouponInput!) {
  applyCoupon(input: $input)
}
    `;
export type ApplyCouponMutationFn = Apollo.MutationFunction<ApplyCouponMutation, ApplyCouponMutationVariables>;

/**
 * __useApplyCouponMutation__
 *
 * To run a mutation, you first call `useApplyCouponMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyCouponMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyCouponMutation, { data, loading, error }] = useApplyCouponMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApplyCouponMutation(baseOptions?: Apollo.MutationHookOptions<ApplyCouponMutation, ApplyCouponMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApplyCouponMutation, ApplyCouponMutationVariables>(ApplyCouponDocument, options);
      }
export type ApplyCouponMutationHookResult = ReturnType<typeof useApplyCouponMutation>;
export type ApplyCouponMutationResult = Apollo.MutationResult<ApplyCouponMutation>;
export type ApplyCouponMutationOptions = Apollo.BaseMutationOptions<ApplyCouponMutation, ApplyCouponMutationVariables>;
export const CreatePaymentSessionDocument = gql`
    mutation CreatePaymentSession($input: CreatePaymentSessionInput!) {
  createPaymentSession(input: $input) {
    payment_session {
      provider_id
      data {
        url
      }
    }
  }
}
    `;
export type CreatePaymentSessionMutationFn = Apollo.MutationFunction<CreatePaymentSessionMutation, CreatePaymentSessionMutationVariables>;

/**
 * __useCreatePaymentSessionMutation__
 *
 * To run a mutation, you first call `useCreatePaymentSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentSessionMutation, { data, loading, error }] = useCreatePaymentSessionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePaymentSessionMutation(baseOptions?: Apollo.MutationHookOptions<CreatePaymentSessionMutation, CreatePaymentSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePaymentSessionMutation, CreatePaymentSessionMutationVariables>(CreatePaymentSessionDocument, options);
      }
export type CreatePaymentSessionMutationHookResult = ReturnType<typeof useCreatePaymentSessionMutation>;
export type CreatePaymentSessionMutationResult = Apollo.MutationResult<CreatePaymentSessionMutation>;
export type CreatePaymentSessionMutationOptions = Apollo.BaseMutationOptions<CreatePaymentSessionMutation, CreatePaymentSessionMutationVariables>;
export const UpdateAddressDocument = gql`
    mutation UpdateAddress($input: UpdatePatientInput!) {
  updateAddress(input: $input) {
    id
  }
}
    `;
export type UpdateAddressMutationFn = Apollo.MutationFunction<UpdateAddressMutation, UpdateAddressMutationVariables>;

/**
 * __useUpdateAddressMutation__
 *
 * To run a mutation, you first call `useUpdateAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAddressMutation, { data, loading, error }] = useUpdateAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAddressMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAddressMutation, UpdateAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAddressMutation, UpdateAddressMutationVariables>(UpdateAddressDocument, options);
      }
export type UpdateAddressMutationHookResult = ReturnType<typeof useUpdateAddressMutation>;
export type UpdateAddressMutationResult = Apollo.MutationResult<UpdateAddressMutation>;
export type UpdateAddressMutationOptions = Apollo.BaseMutationOptions<UpdateAddressMutation, UpdateAddressMutationVariables>;
export const CompleteCheckoutDocument = gql`
    mutation CompleteCheckout($input: CompleteCheckoutInput!) {
  completeCheckout(input: $input)
}
    `;
export type CompleteCheckoutMutationFn = Apollo.MutationFunction<CompleteCheckoutMutation, CompleteCheckoutMutationVariables>;

/**
 * __useCompleteCheckoutMutation__
 *
 * To run a mutation, you first call `useCompleteCheckoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteCheckoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeCheckoutMutation, { data, loading, error }] = useCompleteCheckoutMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompleteCheckoutMutation(baseOptions?: Apollo.MutationHookOptions<CompleteCheckoutMutation, CompleteCheckoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteCheckoutMutation, CompleteCheckoutMutationVariables>(CompleteCheckoutDocument, options);
      }
export type CompleteCheckoutMutationHookResult = ReturnType<typeof useCompleteCheckoutMutation>;
export type CompleteCheckoutMutationResult = Apollo.MutationResult<CompleteCheckoutMutation>;
export type CompleteCheckoutMutationOptions = Apollo.BaseMutationOptions<CompleteCheckoutMutation, CompleteCheckoutMutationVariables>;
export const AcuityAppointmentFormDataDocument = gql`
    query AcuityAppointmentFormData {
  acuityAppointmentFormData {
    appointmentCategories {
      name
    }
    appointmentTypes {
      id
      name
      active
      description
      duration
      price
      color
      private
      type
      schedulingUrl
      image
      classSize
      paddingAfter
      paddingBefore
      calendarIDs
      addonIDs
      formIDs
    }
    calendars {
      id
      name
      email
      replyTo
      description
      location
      timezone
    }
  }
}
    `;

/**
 * __useAcuityAppointmentFormDataQuery__
 *
 * To run a query within a React component, call `useAcuityAppointmentFormDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useAcuityAppointmentFormDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAcuityAppointmentFormDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useAcuityAppointmentFormDataQuery(baseOptions?: Apollo.QueryHookOptions<AcuityAppointmentFormDataQuery, AcuityAppointmentFormDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AcuityAppointmentFormDataQuery, AcuityAppointmentFormDataQueryVariables>(AcuityAppointmentFormDataDocument, options);
      }
export function useAcuityAppointmentFormDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AcuityAppointmentFormDataQuery, AcuityAppointmentFormDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AcuityAppointmentFormDataQuery, AcuityAppointmentFormDataQueryVariables>(AcuityAppointmentFormDataDocument, options);
        }
export type AcuityAppointmentFormDataQueryHookResult = ReturnType<typeof useAcuityAppointmentFormDataQuery>;
export type AcuityAppointmentFormDataLazyQueryHookResult = ReturnType<typeof useAcuityAppointmentFormDataLazyQuery>;
export type AcuityAppointmentFormDataQueryResult = Apollo.QueryResult<AcuityAppointmentFormDataQuery, AcuityAppointmentFormDataQueryVariables>;
export const AcuityAvailableAppointmentDaysDocument = gql`
    query AcuityAvailableAppointmentDays($input: AcuityAvailableDaysInput!) {
  acuityAvailableAppointmentDays(input: $input) {
    date
    times {
      time
    }
  }
}
    `;

/**
 * __useAcuityAvailableAppointmentDaysQuery__
 *
 * To run a query within a React component, call `useAcuityAvailableAppointmentDaysQuery` and pass it any options that fit your needs.
 * When your component renders, `useAcuityAvailableAppointmentDaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAcuityAvailableAppointmentDaysQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcuityAvailableAppointmentDaysQuery(baseOptions: Apollo.QueryHookOptions<AcuityAvailableAppointmentDaysQuery, AcuityAvailableAppointmentDaysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AcuityAvailableAppointmentDaysQuery, AcuityAvailableAppointmentDaysQueryVariables>(AcuityAvailableAppointmentDaysDocument, options);
      }
export function useAcuityAvailableAppointmentDaysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AcuityAvailableAppointmentDaysQuery, AcuityAvailableAppointmentDaysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AcuityAvailableAppointmentDaysQuery, AcuityAvailableAppointmentDaysQueryVariables>(AcuityAvailableAppointmentDaysDocument, options);
        }
export type AcuityAvailableAppointmentDaysQueryHookResult = ReturnType<typeof useAcuityAvailableAppointmentDaysQuery>;
export type AcuityAvailableAppointmentDaysLazyQueryHookResult = ReturnType<typeof useAcuityAvailableAppointmentDaysLazyQuery>;
export type AcuityAvailableAppointmentDaysQueryResult = Apollo.QueryResult<AcuityAvailableAppointmentDaysQuery, AcuityAvailableAppointmentDaysQueryVariables>;
export const AcuityAppointmentsDocument = gql`
    query AcuityAppointments($input: AcuityAppointmentsInput!) {
  acuityAppointments(input: $input) {
    date
    time
    type
    confirmationPage
    calendar
  }
}
    `;

/**
 * __useAcuityAppointmentsQuery__
 *
 * To run a query within a React component, call `useAcuityAppointmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAcuityAppointmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAcuityAppointmentsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcuityAppointmentsQuery(baseOptions: Apollo.QueryHookOptions<AcuityAppointmentsQuery, AcuityAppointmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AcuityAppointmentsQuery, AcuityAppointmentsQueryVariables>(AcuityAppointmentsDocument, options);
      }
export function useAcuityAppointmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AcuityAppointmentsQuery, AcuityAppointmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AcuityAppointmentsQuery, AcuityAppointmentsQueryVariables>(AcuityAppointmentsDocument, options);
        }
export type AcuityAppointmentsQueryHookResult = ReturnType<typeof useAcuityAppointmentsQuery>;
export type AcuityAppointmentsLazyQueryHookResult = ReturnType<typeof useAcuityAppointmentsLazyQuery>;
export type AcuityAppointmentsQueryResult = Apollo.QueryResult<AcuityAppointmentsQuery, AcuityAppointmentsQueryVariables>;
export const IsUnreadNotificationsDocument = gql`
    query isUnreadNotifications {
  isUnreadNotifications
}
    `;

/**
 * __useIsUnreadNotificationsQuery__
 *
 * To run a query within a React component, call `useIsUnreadNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsUnreadNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsUnreadNotificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useIsUnreadNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<IsUnreadNotificationsQuery, IsUnreadNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsUnreadNotificationsQuery, IsUnreadNotificationsQueryVariables>(IsUnreadNotificationsDocument, options);
      }
export function useIsUnreadNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsUnreadNotificationsQuery, IsUnreadNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsUnreadNotificationsQuery, IsUnreadNotificationsQueryVariables>(IsUnreadNotificationsDocument, options);
        }
export type IsUnreadNotificationsQueryHookResult = ReturnType<typeof useIsUnreadNotificationsQuery>;
export type IsUnreadNotificationsLazyQueryHookResult = ReturnType<typeof useIsUnreadNotificationsLazyQuery>;
export type IsUnreadNotificationsQueryResult = Apollo.QueryResult<IsUnreadNotificationsQuery, IsUnreadNotificationsQueryVariables>;
export const NotificationsDocument = gql`
    query notifications {
  notifications {
    id
    description
    isRead
    link
    createdAt
    appointment {
      id
      confirmed
    }
  }
}
    `;

/**
 * __useNotificationsQuery__
 *
 * To run a query within a React component, call `useNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options);
      }
export function useNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options);
        }
export type NotificationsQueryHookResult = ReturnType<typeof useNotificationsQuery>;
export type NotificationsLazyQueryHookResult = ReturnType<typeof useNotificationsLazyQuery>;
export type NotificationsQueryResult = Apollo.QueryResult<NotificationsQuery, NotificationsQueryVariables>;
export const ProductsDocument = gql`
    query products($input: ProductFilterInput!) {
  products(input: $input) {
    count
    limit
    offset
    products {
      ...ProductFields
    }
  }
}
    ${ProductFieldsFragmentDoc}`;

/**
 * __useProductsQuery__
 *
 * To run a query within a React component, call `useProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProductsQuery(baseOptions: Apollo.QueryHookOptions<ProductsQuery, ProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, options);
      }
export function useProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductsQuery, ProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, options);
        }
export type ProductsQueryHookResult = ReturnType<typeof useProductsQuery>;
export type ProductsLazyQueryHookResult = ReturnType<typeof useProductsLazyQuery>;
export type ProductsQueryResult = Apollo.QueryResult<ProductsQuery, ProductsQueryVariables>;
export const ProductDocument = gql`
    query product($input: MedusaProductInput!) {
  product(input: $input) {
    ...ProductFields
  }
}
    ${ProductFieldsFragmentDoc}`;

/**
 * __useProductQuery__
 *
 * To run a query within a React component, call `useProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProductQuery(baseOptions: Apollo.QueryHookOptions<ProductQuery, ProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductQuery, ProductQueryVariables>(ProductDocument, options);
      }
export function useProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductQuery, ProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductQuery, ProductQueryVariables>(ProductDocument, options);
        }
export type ProductQueryHookResult = ReturnType<typeof useProductQuery>;
export type ProductLazyQueryHookResult = ReturnType<typeof useProductLazyQuery>;
export type ProductQueryResult = Apollo.QueryResult<ProductQuery, ProductQueryVariables>;
export const AlternativeProductsDocument = gql`
    query alternativeProducts($input: MedusaProductInput!) {
  alternativeProducts(input: $input) {
    count
    limit
    offset
    products {
      ...ProductFields
    }
  }
}
    ${ProductFieldsFragmentDoc}`;

/**
 * __useAlternativeProductsQuery__
 *
 * To run a query within a React component, call `useAlternativeProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAlternativeProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAlternativeProductsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAlternativeProductsQuery(baseOptions: Apollo.QueryHookOptions<AlternativeProductsQuery, AlternativeProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AlternativeProductsQuery, AlternativeProductsQueryVariables>(AlternativeProductsDocument, options);
      }
export function useAlternativeProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AlternativeProductsQuery, AlternativeProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AlternativeProductsQuery, AlternativeProductsQueryVariables>(AlternativeProductsDocument, options);
        }
export type AlternativeProductsQueryHookResult = ReturnType<typeof useAlternativeProductsQuery>;
export type AlternativeProductsLazyQueryHookResult = ReturnType<typeof useAlternativeProductsLazyQuery>;
export type AlternativeProductsQueryResult = Apollo.QueryResult<AlternativeProductsQuery, AlternativeProductsQueryVariables>;
export const RecommendedProductsDocument = gql`
    query recommendedProducts {
  recommendedProducts {
    count
    limit
    offset
    products {
      ...ProductFields
    }
  }
}
    ${ProductFieldsFragmentDoc}`;

/**
 * __useRecommendedProductsQuery__
 *
 * To run a query within a React component, call `useRecommendedProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecommendedProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecommendedProductsQuery({
 *   variables: {
 *   },
 * });
 */
export function useRecommendedProductsQuery(baseOptions?: Apollo.QueryHookOptions<RecommendedProductsQuery, RecommendedProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RecommendedProductsQuery, RecommendedProductsQueryVariables>(RecommendedProductsDocument, options);
      }
export function useRecommendedProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RecommendedProductsQuery, RecommendedProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RecommendedProductsQuery, RecommendedProductsQueryVariables>(RecommendedProductsDocument, options);
        }
export type RecommendedProductsQueryHookResult = ReturnType<typeof useRecommendedProductsQuery>;
export type RecommendedProductsLazyQueryHookResult = ReturnType<typeof useRecommendedProductsLazyQuery>;
export type RecommendedProductsQueryResult = Apollo.QueryResult<RecommendedProductsQuery, RecommendedProductsQueryVariables>;
export const ProductFilterOptionsDocument = gql`
    query productFilterOptions {
  productFilterOptions {
    collections {
      id
      title
      handle
    }
    product_categories {
      id
      name
      handle
      parent_category {
        id
        name
        handle
      }
      category_children {
        id
        name
        handle
      }
    }
    product_types {
      id
      value
    }
  }
}
    `;

/**
 * __useProductFilterOptionsQuery__
 *
 * To run a query within a React component, call `useProductFilterOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductFilterOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductFilterOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useProductFilterOptionsQuery(baseOptions?: Apollo.QueryHookOptions<ProductFilterOptionsQuery, ProductFilterOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductFilterOptionsQuery, ProductFilterOptionsQueryVariables>(ProductFilterOptionsDocument, options);
      }
export function useProductFilterOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductFilterOptionsQuery, ProductFilterOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductFilterOptionsQuery, ProductFilterOptionsQueryVariables>(ProductFilterOptionsDocument, options);
        }
export type ProductFilterOptionsQueryHookResult = ReturnType<typeof useProductFilterOptionsQuery>;
export type ProductFilterOptionsLazyQueryHookResult = ReturnType<typeof useProductFilterOptionsLazyQuery>;
export type ProductFilterOptionsQueryResult = Apollo.QueryResult<ProductFilterOptionsQuery, ProductFilterOptionsQueryVariables>;
export const CartDocument = gql`
    query cart {
  cart {
    id
    items {
      ...LineItemFields
    }
    total
    subtotal
    discounts {
      code
    }
  }
}
    ${LineItemFieldsFragmentDoc}`;

/**
 * __useCartQuery__
 *
 * To run a query within a React component, call `useCartQuery` and pass it any options that fit your needs.
 * When your component renders, `useCartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCartQuery({
 *   variables: {
 *   },
 * });
 */
export function useCartQuery(baseOptions?: Apollo.QueryHookOptions<CartQuery, CartQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CartQuery, CartQueryVariables>(CartDocument, options);
      }
export function useCartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CartQuery, CartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CartQuery, CartQueryVariables>(CartDocument, options);
        }
export type CartQueryHookResult = ReturnType<typeof useCartQuery>;
export type CartLazyQueryHookResult = ReturnType<typeof useCartLazyQuery>;
export type CartQueryResult = Apollo.QueryResult<CartQuery, CartQueryVariables>;
export const UserDocument = gql`
    query user {
  user {
    id
    email
    phoneNumber
    firstName
    lastName
    patient {
      id
      isPrescribedThc
      streetAddress
      suburb
      region
      city
      postCode
      dob
      gender
    }
  }
}
    `;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserQuery(baseOptions?: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const PatientDocument = gql`
    query patient {
  patient {
    id
    isPrescribedThc
    followUpInterval
    followUpDate
  }
}
    `;

/**
 * __usePatientQuery__
 *
 * To run a query within a React component, call `usePatientQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientQuery({
 *   variables: {
 *   },
 * });
 */
export function usePatientQuery(baseOptions?: Apollo.QueryHookOptions<PatientQuery, PatientQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PatientQuery, PatientQueryVariables>(PatientDocument, options);
      }
export function usePatientLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PatientQuery, PatientQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PatientQuery, PatientQueryVariables>(PatientDocument, options);
        }
export type PatientQueryHookResult = ReturnType<typeof usePatientQuery>;
export type PatientLazyQueryHookResult = ReturnType<typeof usePatientLazyQuery>;
export type PatientQueryResult = Apollo.QueryResult<PatientQuery, PatientQueryVariables>;
export const FulfillmentOrdersDocument = gql`
    query fulfillmentOrders {
  fulfillmentOrders {
    ...FulfillmentOrderFields
  }
}
    ${FulfillmentOrderFieldsFragmentDoc}`;

/**
 * __useFulfillmentOrdersQuery__
 *
 * To run a query within a React component, call `useFulfillmentOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFulfillmentOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFulfillmentOrdersQuery({
 *   variables: {
 *   },
 * });
 */
export function useFulfillmentOrdersQuery(baseOptions?: Apollo.QueryHookOptions<FulfillmentOrdersQuery, FulfillmentOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FulfillmentOrdersQuery, FulfillmentOrdersQueryVariables>(FulfillmentOrdersDocument, options);
      }
export function useFulfillmentOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FulfillmentOrdersQuery, FulfillmentOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FulfillmentOrdersQuery, FulfillmentOrdersQueryVariables>(FulfillmentOrdersDocument, options);
        }
export type FulfillmentOrdersQueryHookResult = ReturnType<typeof useFulfillmentOrdersQuery>;
export type FulfillmentOrdersLazyQueryHookResult = ReturnType<typeof useFulfillmentOrdersLazyQuery>;
export type FulfillmentOrdersQueryResult = Apollo.QueryResult<FulfillmentOrdersQuery, FulfillmentOrdersQueryVariables>;
export const UpcomingReleasesDocument = gql`
    query upcomingReleases {
  upcomingReleases {
    ...FulfillmentOrderFields
  }
}
    ${FulfillmentOrderFieldsFragmentDoc}`;

/**
 * __useUpcomingReleasesQuery__
 *
 * To run a query within a React component, call `useUpcomingReleasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpcomingReleasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpcomingReleasesQuery({
 *   variables: {
 *   },
 * });
 */
export function useUpcomingReleasesQuery(baseOptions?: Apollo.QueryHookOptions<UpcomingReleasesQuery, UpcomingReleasesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UpcomingReleasesQuery, UpcomingReleasesQueryVariables>(UpcomingReleasesDocument, options);
      }
export function useUpcomingReleasesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UpcomingReleasesQuery, UpcomingReleasesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UpcomingReleasesQuery, UpcomingReleasesQueryVariables>(UpcomingReleasesDocument, options);
        }
export type UpcomingReleasesQueryHookResult = ReturnType<typeof useUpcomingReleasesQuery>;
export type UpcomingReleasesLazyQueryHookResult = ReturnType<typeof useUpcomingReleasesLazyQuery>;
export type UpcomingReleasesQueryResult = Apollo.QueryResult<UpcomingReleasesQuery, UpcomingReleasesQueryVariables>;
export const FulfillmentWarningsDocument = gql`
    query fulfillmentWarnings {
  fulfillmentWarnings {
    title
    type
  }
}
    `;

/**
 * __useFulfillmentWarningsQuery__
 *
 * To run a query within a React component, call `useFulfillmentWarningsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFulfillmentWarningsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFulfillmentWarningsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFulfillmentWarningsQuery(baseOptions?: Apollo.QueryHookOptions<FulfillmentWarningsQuery, FulfillmentWarningsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FulfillmentWarningsQuery, FulfillmentWarningsQueryVariables>(FulfillmentWarningsDocument, options);
      }
export function useFulfillmentWarningsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FulfillmentWarningsQuery, FulfillmentWarningsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FulfillmentWarningsQuery, FulfillmentWarningsQueryVariables>(FulfillmentWarningsDocument, options);
        }
export type FulfillmentWarningsQueryHookResult = ReturnType<typeof useFulfillmentWarningsQuery>;
export type FulfillmentWarningsLazyQueryHookResult = ReturnType<typeof useFulfillmentWarningsLazyQuery>;
export type FulfillmentWarningsQueryResult = Apollo.QueryResult<FulfillmentWarningsQuery, FulfillmentWarningsQueryVariables>;
export const PrescriptionsDocument = gql`
    query prescriptions {
  prescriptions {
    id
    doctor {
      user {
        email
        phoneNumber
        firstName
        lastName
      }
    }
    prescriptionItems {
      productVariantId
      title
      generatedDosageDesc
      nzUlmCode
      quantity
      unit
      maximumDosage
      thumbnail
      handle
      totalCalculatedPrice
      totalOriginalPrice
    }
    itemQuantity
    createdAt
  }
}
    `;

/**
 * __usePrescriptionsQuery__
 *
 * To run a query within a React component, call `usePrescriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrescriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrescriptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePrescriptionsQuery(baseOptions?: Apollo.QueryHookOptions<PrescriptionsQuery, PrescriptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PrescriptionsQuery, PrescriptionsQueryVariables>(PrescriptionsDocument, options);
      }
export function usePrescriptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PrescriptionsQuery, PrescriptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PrescriptionsQuery, PrescriptionsQueryVariables>(PrescriptionsDocument, options);
        }
export type PrescriptionsQueryHookResult = ReturnType<typeof usePrescriptionsQuery>;
export type PrescriptionsLazyQueryHookResult = ReturnType<typeof usePrescriptionsLazyQuery>;
export type PrescriptionsQueryResult = Apollo.QueryResult<PrescriptionsQuery, PrescriptionsQueryVariables>;
export const ScriptCartItemsDocument = gql`
    query scriptCartItems($input: ScriptCartItemsInput!) {
  scriptCartItems(input: $input) {
    lineItem {
      ...LineItemFields
    }
    product {
      ...ProductFields
    }
  }
}
    ${LineItemFieldsFragmentDoc}
${ProductFieldsFragmentDoc}`;

/**
 * __useScriptCartItemsQuery__
 *
 * To run a query within a React component, call `useScriptCartItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useScriptCartItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScriptCartItemsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useScriptCartItemsQuery(baseOptions: Apollo.QueryHookOptions<ScriptCartItemsQuery, ScriptCartItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ScriptCartItemsQuery, ScriptCartItemsQueryVariables>(ScriptCartItemsDocument, options);
      }
export function useScriptCartItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ScriptCartItemsQuery, ScriptCartItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ScriptCartItemsQuery, ScriptCartItemsQueryVariables>(ScriptCartItemsDocument, options);
        }
export type ScriptCartItemsQueryHookResult = ReturnType<typeof useScriptCartItemsQuery>;
export type ScriptCartItemsLazyQueryHookResult = ReturnType<typeof useScriptCartItemsLazyQuery>;
export type ScriptCartItemsQueryResult = Apollo.QueryResult<ScriptCartItemsQuery, ScriptCartItemsQueryVariables>;