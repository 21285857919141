import {
  Button,
  Flex,
  Image,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { FulfillmentOrderItem } from "../../../generated/graphql";
import productPlaceholder from "../../../images/productPlaceholder.png";

const OrderItem: FC<{
  item: FulfillmentOrderItem;
}> = ({ item }) => {
  const navigate = useNavigate();
  const linkProduct = useBreakpointValue({ base: true, lg: false });

  return (
    <Flex
      onClick={
        linkProduct
          ? () => navigate(`/patient/dispensary/${item.handle}`)
          : undefined
      }
    >
      <Flex
        borderWidth="1px"
        borderRadius="xl"
        borderColor="cc.lightScale.400"
        alignItems="center"
        maxH="24"
      >
        <Image
          src={item.thumbnail ?? productPlaceholder}
          w="24"
          h="24"
          objectFit="cover"
        />
      </Flex>
      <Flex
        flexDir="column"
        gap="0.5"
        ml={{ base: 2, xl: 4 }}
        textAlign="left"
        fontWeight="semibold"
      >
        <Text>
          {item.title}{" "}
          <Text as="span" fontWeight="normal">
            x{item.quantity}
          </Text>
        </Text>
        <Text fontWeight="semibold" fontSize="sm">
          ${item.price.toFixed(2)}
        </Text>
      </Flex>
      <Button
        display={{ base: "none", xl: "inherit" }}
        variant="primaryDarkOutline"
        fontSize={{ base: "xs", xl: "sm" }}
        ml="auto"
        px="7"
        my="auto"
        onClick={() => navigate(`/patient/dispensary/${item.handle}`)}
      >
        View Product
      </Button>
    </Flex>
  );
};

export default OrderItem;
