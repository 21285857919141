import { Box, Flex } from "@chakra-ui/react";
import { FC, useRef } from "react";
import SwiperCore, { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { NavigationOptions } from "swiper/types";
import { MedusaProduct } from "../../../../generated/graphql";
import { ReactComponent as LeftChevron } from "../../../../images/leftChevron.svg";
import { ReactComponent as RightChevron } from "../../../../images/rightChevron.svg";
import ProductPreview from "./ProductPreview";
SwiperCore.use([Navigation]);

const ProductSlider: FC<{
  products: MedusaProduct[];
  slideLimit?: number | undefined;
  showButtons?: boolean | undefined;
}> = ({ products, slideLimit, showButtons }) => {
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  return (
    <Flex mb="5" mt="8">
      {showButtons && (
        <Box mt="14vh" mr={{ base: "5", xl: "10" }} cursor="pointer">
          <LeftChevron
            cursor="pointer"
            ref={navigationPrevRef}
            height="20px"
            width="20px"
          />
        </Box>
      )}
      <Swiper
        style={{ paddingBottom: 50 }}
        pagination={true}
        modules={[Pagination]}
        spaceBetween="20"
        slidesPerView={products.length < 4 ? products.length : 2}
        breakpoints={{
          400: {
            slidesPerView: 2,
          },
          800: {
            slidesPerView: slideLimit ? slideLimit : 4,
          },
        }}
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        onBeforeInit={(swiper) => {
          if (typeof swiper.params.navigation === "object") {
            let navigation: NavigationOptions = swiper.params.navigation;
            navigation.prevEl = navigationPrevRef.current;
            navigation.nextEl = navigationNextRef.current;
          }
        }}
      >
        {products.map((product, i) => {
          return (
            <SwiperSlide key={i}>
              <ProductPreview product={product} />
            </SwiperSlide>
          );
        })}
      </Swiper>
      {showButtons && (
        <Box mt="14vh" ml={{ base: "5", xl: "10" }}>
          <RightChevron
            cursor="pointer"
            ref={navigationNextRef}
            height="20px"
            width="20px"
          />
        </Box>
      )}
    </Flex>
  );
};

export default ProductSlider;
