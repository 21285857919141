import { Box, Button, Flex, Spacer, Text } from "@chakra-ui/react";
import { FC, useState } from "react";
import { Prescription, useAddToCartMutation } from "../../../generated/graphql";
import ViewPrescriptionDetails from "./ViewPrescriptionDetails";

const prescriptionFields: {
  label: string;
  value: keyof Prescription | "totalPrice";
}[] = [
  {
    label: "Script Date",
    value: "createdAt",
  },
  {
    label: "Prescriber",
    value: "doctor",
  },
  {
    label: "Total Price",
    value: "totalPrice",
  },
  {
    label: "Medications",
    value: "itemQuantity",
  },
];

const DisplayPrescription: FC<{
  prescription: Prescription;
  updatePrescriptionToOrder: React.Dispatch<
    React.SetStateAction<Prescription | undefined>
  >;
}> = ({ prescription, updatePrescriptionToOrder }) => {
  const [viewPrescription, setViewPrescription] = useState(false);

  const [addToCart] = useAddToCartMutation({
    onCompleted: () => {
      updatePrescriptionToOrder(prescription);
    },
    onError: (err) => {
      alert(err);
      updatePrescriptionToOrder(prescription);
    },
  });

  const handleReOrder = () => {
    const lineItems = prescription.prescriptionItems.map((pi) => {
      return { quantity: pi.quantity, variant_id: pi.productVariantId };
    });
    addToCart({
      variables: {
        input: {
          lineItems,
        },
      },
    });
  };

  const formatValue = (value: keyof Prescription | "totalPrice") => {
    if (value === "createdAt") {
      const date = new Date(prescription[value]);
      return `${date.toLocaleDateString("en-NZ", {
        year: "numeric",
        month: "long",
        day: "numeric",
        timeZone: "nz",
      })}`;
    }

    if (value === "doctor") {
      return `Dr. ${prescription.doctor.user.firstName} ${prescription.doctor.user.lastName}`;
    }

    // Calculating total price of prescription here as resolve field on backend is calculating discounts live
    if (value === "totalPrice") {
      const totalPrice =
        prescription.prescriptionItems.reduce(
          (a, b) => a + b.totalCalculatedPrice * b.quantity,
          0
        ) / 100;
      return `$${totalPrice.toFixed(2)}`;
    }
    return prescription[value]?.toString();
  };

  return (
    <Box>
      <Flex
        borderWidth="1px"
        borderColor="cc.greyScale.100"
        borderRadius="2xl"
        textAlign={{ base: "left", xl: "center" }}
        flexDir={{ base: "column", xl: "row" }}
        gap={{ base: 2, xl: 10 }}
        px={{ base: 5, xl: 10 }}
        mx="auto"
        py="7"
        pb="5"
      >
        <Flex
          w="full"
          position="relative"
          gap={{ base: 2, xl: 10 }}
          alignItems={{ base: "inherit", xl: "center" }}
          flexDir={{ base: "column", xl: "row" }}
        >
          {prescriptionFields.map((prescriptionField, i) => {
            return (
              <>
                <Flex flexDir={{ base: "row", xl: "column" }} key={i}>
                  <Text w={{ base: "50%", xl: "100%" }}>
                    {prescriptionField.label}
                  </Text>
                  <Text
                    fontWeight="semibold"
                    my={{ base: 0, xl: 3 }}
                    w={{ base: "50%", xl: "100%" }}
                  >
                    {formatValue(prescriptionField.value)}
                  </Text>
                </Flex>
                <Spacer />
              </>
            );
          })}
          <Button
            variant="primaryDarkOutline"
            _hover={{ bgColor: "white" }}
            py="6"
            fontSize="sm"
            onClick={() => setViewPrescription(!viewPrescription)}
          >
            {viewPrescription ? "Hide Items" : "View Items"}
          </Button>
          <Button
            variant="primaryDark"
            py="6"
            fontSize="sm"
            onClick={() => handleReOrder()}
          >
            Add Script to Cart
          </Button>
        </Flex>
      </Flex>
      {viewPrescription && (
        <ViewPrescriptionDetails prescription={prescription} />
      )}
    </Box>
  );
};

export default DisplayPrescription;
