import { Box, Flex, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import LoadingAnimation from "../../components/LoadingAnimation";
import WarningBanner from "../../features/patients/WarningBanner";
import BookAppointment from "../../features/patients/booking/BookAppointment";
import ViewAppointments from "../../features/patients/booking/ViewAppointments";
import {
  CreateAcuityAppointmentInput,
  useAcuityAppointmentsQuery,
  useCreateAcuityAppointmentMutation,
  usePatientQuery,
} from "../../generated/graphql";
import useCalculateFollowUpDate from "../../hooks/patient/dispensary/useCalculateFollowUpDate";
import MainLayout from "../../layouts/MainLayout";

const Appointments = () => {
  const { data, loading } = useAcuityAppointmentsQuery({});
  const { data: patientData } = usePatientQuery({});

  const [previousAppointments, setPreviousAppointments] = useState<any[]>();
  const [upcomingAppointments, setUpcomingAppointments] = useState<any[]>();
  const [selectedBookingTime, setSelectedBookingTime] = useState<
    Date | undefined
  >();
  const [selectedAppointmentTypeId, setSelectedAppointmentTypeId] = useState<
    number | undefined
  >();

  const followUpMessage = useCalculateFollowUpDate(
    patientData?.patient.followUpDate
  );

  const filterAppointments = (appointments: any) => {
    const currentDate = new Date();

    const previousAppointmentData = appointments.filter((p: any) => {
      const date = new Date(p.date);
      return date < currentDate;
    });

    const upcomingAppointmentData = appointments.filter((p: any) => {
      const date = new Date(p.date);
      return date >= currentDate;
    });

    setPreviousAppointments(previousAppointmentData);
    setUpcomingAppointments(upcomingAppointmentData);
  };

  const [submit] = useCreateAcuityAppointmentMutation({
    onError: (error: any) => {
      alert(error);
    },
  });

  const onSubmit = ({
    selectedBookingTime,
    selectedAppointmentTypeId,
  }: CreateAcuityAppointmentInput) =>
    submit({
      variables: {
        input: {
          selectedBookingTime,
          selectedAppointmentTypeId,
        },
      },
    });

  useEffect(() => {
    if (data && data.acuityAppointments)
      filterAppointments(data.acuityAppointments);
  }, [data]);

  if (loading)
    return (
      <MainLayout>
        <Text as="h1" textAlign="left">
          Appointments
        </Text>
        <LoadingAnimation />
      </MainLayout>
    );

  return (
    <MainLayout>
      <Text as="h1" textAlign="left">
        Appointments
      </Text>
      {followUpMessage && (
        <WarningBanner type="reminder" message={followUpMessage} />
      )}
      <Flex
        flexDir={{ base: "column", xl: "row" }}
        gap="7"
        my={{ base: "10", xl: "10" }}
      >
        <Flex flexDir="column" gap="5" w={{ base: "100%", xl: "30%" }}>
          <Box>
            <Text as="h5" fontWeight="500" textAlign="left">
              Upcoming
            </Text>
            <ViewAppointments
              type="upcoming"
              appointments={upcomingAppointments}
            />
          </Box>
          <Box>
            <Text as="h5" fontWeight="500" textAlign="left">
              Previous
            </Text>
            <ViewAppointments
              type="previous"
              appointments={previousAppointments}
            />
          </Box>
        </Flex>
        <BookAppointment
          selectedBookingTime={selectedBookingTime}
          selectedAppointmentTypeId={selectedAppointmentTypeId}
          updateSelectedAppointmentTypeId={setSelectedAppointmentTypeId}
          updateSelectedBookingTime={setSelectedBookingTime}
          onSubmit={onSubmit}
        />
      </Flex>
    </MainLayout>
  );
};

export default Appointments;
