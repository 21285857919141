import { MedusaProductVariant } from "../../../generated/graphql";

function useGetTotalStockQuantity(variants: MedusaProductVariant[]) {
  return variants.reduce(
    (partialQuantity, variant) => partialQuantity + variant.inventory_quantity,
    0
  );
}

export default useGetTotalStockQuantity;
