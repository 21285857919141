import { Box, Divider, Flex, GridItem, Spacer, Text } from "@chakra-ui/react";
import { useState } from "react";
import LoadingAnimation from "../../../../components/LoadingAnimation";
import WarningBanner from "../../../../features/patients/WarningBanner";
import CartItems from "../../../../features/patients/dispensary/cart/CartItems";
import CartTotals from "../../../../features/patients/dispensary/cart/CartTotals";
import DisplayFulfillmentWarnings from "../../../../features/patients/dispensary/cart/DisplayFulfillmentWarnings";
import ProductSuggestions from "../../../../features/patients/dispensary/product/ProductSuggestions";
import UpcomingReleaseWarnings from "../../../../features/patients/orders/UpcomingReleaseWarnings";
import {
  MedusaLineItem,
  MedusaLineItemInput,
  useCartQuery,
  useFulfillmentWarningsQuery,
  usePatientQuery,
  useRecommendedProductsQuery,
  useRemoveFromCartMutation,
  useUpcomingReleasesQuery,
  useUpdateLineItemMutation,
} from "../../../../generated/graphql";
import useCalculateFollowUpDate from "../../../../hooks/patient/dispensary/useCalculateFollowUpDate";
import CartLayout from "../../../../layouts/CartLayout";

const Cart = () => {
  localStorage.setItem("checkout_step", "1");

  const [lineItems, setLineItems] = useState<MedusaLineItem[]>();

  const { data: patientData } = usePatientQuery();
  const { data: upcomingReleasesData } = useUpcomingReleasesQuery();
  const {
    data: fulfillmentWarningsData,
    loading: fulfillmentWarningsLoading,
    refetch: refetchFulfillmentWarnings,
  } = useFulfillmentWarningsQuery({
    fetchPolicy: "no-cache",
  });

  const followUpMessage = useCalculateFollowUpDate(
    patientData?.patient.followUpDate
  );

  const {
    data: cartData,
    loading: cartLoading,
    refetch,
  } = useCartQuery({
    fetchPolicy: "no-cache",
    onCompleted: (res) => {
      setLineItems(res.cart.items);
    },
    onError: (error) => {
      alert(error.message);
      refetch();
    },
  });
  const { data: recommendedProductData } = useRecommendedProductsQuery();

  const [removeFromCart] = useRemoveFromCartMutation({
    onCompleted: () => {
      refetch();
      refetchFulfillmentWarnings();
    },
  });

  const [updateLineItem] = useUpdateLineItemMutation({
    onCompleted: () => {
      refetch();
      refetchFulfillmentWarnings();
    },
    onError: (error) => {
      return alert(error.message);
    },
  });

  const onRemoveFromCart = (lineItem: MedusaLineItemInput) => {
    removeFromCart({
      variables: {
        input: {
          lineItems: [lineItem],
        },
      },
    });
  };

  const handleUpdateLineItem = (
    id: string,
    variant_id: string,
    quantity: number
  ) => {
    updateLineItem({
      variables: {
        input: {
          lineItem: {
            id,
            variant_id,
            quantity,
          },
        },
      },
    });
  };

  return (
    <CartLayout>
      <GridItem gap="7" colSpan={2}>
        <Text as="h2" textAlign="left">
          My Cart
        </Text>
        {followUpMessage && (
          <WarningBanner type="reminder" message={followUpMessage} />
        )}
        <DisplayFulfillmentWarnings
          fulfillmentWarnings={fulfillmentWarningsData?.fulfillmentWarnings}
        />
        <UpcomingReleaseWarnings
          upcomingReleaseOrders={upcomingReleasesData?.upcomingReleases}
        />
        <Flex flexDir="column" my={{ base: 0, lg: 7 }}>
          <Flex w="full" display={{ base: "none", lg: "flex" }}>
            <Text as="h6" mr="auto">
              Product
            </Text>
            <Flex w="72">
              <Text as="h6">Price</Text>
              <Spacer />
              <Text as="h6">Quantity</Text>
              <Spacer />
              <Text as="h6">Subtotal</Text>
            </Flex>
          </Flex>
          <Divider
            display={{ base: "none", lg: "block" }}
            borderWidth="1px"
            mt="3"
          />
          {cartLoading || fulfillmentWarningsLoading ? (
            <LoadingAnimation />
          ) : (
            <CartItems
              lineItems={lineItems}
              updateLineItem={handleUpdateLineItem}
              removeFromCart={onRemoveFromCart}
            />
          )}
          <Box textAlign="left" display={{ base: "none" }}>
            <ProductSuggestions
              type="recommended"
              slideLimit={2.5}
              products={
                recommendedProductData?.recommendedProducts.products ?? []
              }
            />
          </Box>
        </Flex>
      </GridItem>
      <GridItem
        display="flex"
        flexDir="column"
        gap="5"
        bottom="0"
        left="0"
        right="0"
        pb="14"
      >
        <CartTotals
          total={cartData?.cart.total}
          subtotal={cartData?.cart.subtotal}
          refetch={refetch}
          couponCode={
            cartData?.cart.discounts.find((d) => d.code !== "FREESHIPPING")
              ?.code
          }
        />
      </GridItem>
    </CartLayout>
  );
};

export default Cart;
