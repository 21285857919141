import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { FC } from "react";

const consentFormFields = [
  {
    label: "Side Effects",
    content:
      "I understand the risks associated with medicinal cannabis treatment and that the potential long-term side-effects are unknown. I agree to report any adverse effects I experience from taking medicinal cannabis including but not limited to changes in the levels of sedation fatigue, dry mouth, nausea, diarrhea, and drowsiness.",
    value: "sideEffects",
  },
  {
    label: "Unapproved Medicine",
    content:
      "I understand that medicinal cannabis is a section 29 unapproved medicine in New Zealand which means that it has not been approved by Medsafe for any particular condition. It is prescribed on a case-by-case basis and there is no guarantee there will be any benefit. I understand that information regarding the supply of medicinal cannabis will be forwarded onto the Ministry of Health in accordance with the Medicines Act 1981. ",
    value: "unapprovedMedicine",
  },
  {
    label: "Driving",
    content:
      "The patient understands that there can be no driving, alcohol, or operating heavy machinery within 8-10 hours of undergoing treatment with medicinal cannabis containing THC.",
    value: "driving",
  },
  {
    label: "Medication Interaction",
    content:
      "I understand that medicinal cannabis might interact with my other medications, and doses may need to be adjusted accordingly. I agree to notify my prescribing doctor of any changes in my other medications, and that I will not use any form of cannabis other than that prescribed, including any illicit form of cannabis (marijuana).",
    value: "medicationInteraction",
  },
  {
    label: "New Zealand Quality Standards",
    content:
      "I understand that I may be prescribed products that don’t meet the Ministry of Health quality standards and I accept to do so at my own risk.",
    value: "qualityStandards",
  },
  {
    label: "Monitoring",
    content:
      "I agree to follow my doctor’s recommendation regarding dosing and to attend regular follow-up consultations as directed by my doctor. I agree to keep a log of my doses and changes in symptoms due to medicinal cannabis, and understand that clinical outcomes may be shared for research purposes.",
    value: "monitoring",
  },
];

const ConsentForm: FC<{
  consentForm: any;
  updateConsentForm: (value: any) => void;
}> = ({ consentForm, updateConsentForm }) => {
  return (
    <Flex flexDir="column" textAlign="left" gap="2">
      <Text as="h3">Patient Consent Form</Text>
      <Text>
        Please read and confirm the following statements. Your doctor will walk
        you through these points in more detail during your consultation.
      </Text>
      {consentFormFields.map((consentField, i) => {
        return (
          <FormControl key={i} isRequired>
            <FormLabel fontWeight="600" my="3">
              {consentField.label}
            </FormLabel>
            <Box
              borderWidth="1px"
              borderColor="cc.greyScale.200"
              p="4"
              rounded="2xl"
            >
              {consentField.content}
            </Box>
            <RadioGroup
              my="4"
              onChange={(e) => {
                updateConsentForm({
                  ...consentForm,
                  [consentField.value]: e === "true" ? true : false,
                });
              }}
            >
              <Radio value="true">I understand</Radio>
            </RadioGroup>
          </FormControl>
        );
      })}
      <FormControl isRequired>
        <FormLabel fontWeight="600" my="3">
          Signature
        </FormLabel>
        <Textarea
          onChange={(e) => {
            updateConsentForm({
              ...consentForm,
              signature: e.target.value,
            });
          }}
        />
      </FormControl>
      <FormControl isRequired>
        <FormLabel fontWeight="600" my="3">
          Date
        </FormLabel>
        <Input
          type="date"
          onChange={(e) => {
            updateConsentForm({
              ...consentForm,
              date: e.target.value,
            });
          }}
        />
      </FormControl>
    </Flex>
  );
};

export default ConsentForm;
