import { Box, Button } from "@chakra-ui/react";

import { Text } from "@chakra-ui/react";
import { FC } from "react";
import LoadingAnimation from "../../../../../../components/LoadingAnimation";
import { useRequestThcProductAccessMutation } from "../../../../../../generated/graphql";

const RequestThcProduct: FC<{ productId: string }> = ({ productId }) => {
  const [requestThcProduct, { data, loading, error }] =
    useRequestThcProductAccessMutation({
      variables: { input: { productId } },
    });

  return (
    <Box mt="5">
      <Box bgColor="#F6F6F8" my="5" p="7" rounded="2xl">
        <Text fontSize="md" mb="4" fontWeight="600">
          You are not currently prescribed THC products, click below to request
          this product
        </Text>
        {!data && !loading && (
          <Button
            p="6"
            variant="primaryDarkOutline"
            onClick={() => requestThcProduct()}
          >
            Request Product
          </Button>
        )}
        <Box mt="5">
          {loading && <LoadingAnimation />}
          {error && (
            <Text>An unknown error occurred, please contact support!</Text>
          )}
          {!error && !loading && data && (
            <Text>Thanks for letting us know, we will be in touch!</Text>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default RequestThcProduct;
