import { Box, Divider, Flex, Text } from "@chakra-ui/react";
import { FC, useEffect, useState } from "react";

const ProfileDetails: FC<{ user: any }> = ({ user }) => {
  const [formattedDate, setFormattedDate] = useState<string>();

  useEffect(() => {
    const date = new Date(user.patient.dob);
    setFormattedDate(
      `${date.getDate()} / ${date.getMonth() + 1} / ${date.getFullYear()}`
    );
  }, [user]);

  return (
    <Box
      w="full"
      borderWidth="1px"
      p={{ base: 4, lg: 7 }}
      pb="8"
      rounded="2xl"
      bgColor="white"
    >
      <Text as="h5" my="3" mb="8">
        Profile
      </Text>
      <Flex flexDir="column" fontWeight="500" gap="6">
        <Flex flexDir="column" gap={{ base: 3, xl: 6 }}>
          <Flex>
            <Box w="50%">First Name</Box>
            <Box fontWeight="400">{user.firstName}</Box>
          </Flex>
          <Flex>
            <Box w="50%">Last Name</Box>
            <Box fontWeight="400">{user.lastName}</Box>
          </Flex>
        </Flex>
        <Divider />
        <Flex flexDir="column" gap={{ base: 3, xl: 6 }}>
          <Flex>
            <Box w="50%">Date Of Birth</Box>
            <Box fontWeight="400">{formattedDate}</Box>
          </Flex>
          <Flex>
            <Box w="50%">Gender</Box>
            <Box fontWeight="400">{user.patient.gender}</Box>
          </Flex>
        </Flex>
        <Divider />
        <Flex flexDir="column" gap={{ base: 3, xl: 6 }}>
          <Flex>
            <Box w="50%">Contact Number</Box>
            <Box fontWeight="400">{user.phoneNumber}</Box>
          </Flex>
          <Flex>
            <Box w="50%">Email Address</Box>
            <Box fontWeight="400">{user.email}</Box>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

export default ProfileDetails;
