function useCalculateFollowUpDate(date: Date) {
  const followUpDate = new Date(date);
  const current = new Date();

  var days = Math.round(
    (followUpDate.getTime() - current.getTime()) / (1000 * 60 * 60 * 24)
  );

  if (current.getTime() > followUpDate.getTime())
    return "You are overdue for a followup for at least 1 product you have been prescribed in the past - we will need to review this order and advise you accordingly.";
  if (current.getTime() < followUpDate.getTime())
    return `You will be due for a follow up in ${days} day(s)`;
  return null;
}

export default useCalculateFollowUpDate;
