import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { FC } from "react";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import { AcuityAppointmentCategories } from "../../../../../generated/graphql";
import { ReactComponent as PersonIcon } from "../../../../../images/perosn-icon.svg";

const SelectAppointmentCategory: FC<{
  appointmentCategories: any;
  selectedCategory: any;
  updateSelectedCategory: any;
}> = ({ appointmentCategories, selectedCategory, updateSelectedCategory }) => {
  return (
    <Box>
      <Text my="7" textAlign="left" fontWeight="500">
        Choose Category...
      </Text>
      <Swiper
        spaceBetween="20"
        slidesPerView={4.5}
        breakpoints={{
          300: {
            slidesPerView: 1.25,
          },
          800: {
            slidesPerView: 3.5,
          },
        }}
      >
        {appointmentCategories?.map(
          (appointmentCategory: AcuityAppointmentCategories, i: number) => {
            return (
              <SwiperSlide key={i}>
                <Flex
                  onClick={() => updateSelectedCategory(appointmentCategory)}
                  cursor="pointer"
                  flexDir="column"
                  p="18"
                  rounded="20px"
                  border="2px"
                  borderColor="cc.greyScale.100"
                  alignItems="center"
                  gap="5"
                  h="100%"
                >
                  <PersonIcon />
                  {appointmentCategory.name}
                  <Button
                    w="32"
                    mx="auto"
                    mt="auto"
                    variant={
                      selectedCategory === appointmentCategory
                        ? "primaryDark"
                        : "primaryDarkOutline"
                    }
                  >
                    {selectedCategory === appointmentCategory
                      ? "Selected"
                      : "Select"}
                  </Button>
                </Flex>
              </SwiperSlide>
            );
          }
        )}
      </Swiper>
    </Box>
  );
};

export default SelectAppointmentCategory;
