import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { FC, useEffect } from "react";
import AddToCartAnimation from "../../../../components/AddToCartAnimation";

const AddToCartPopup: FC<{ isOpen: boolean; onClose: () => void }> = ({
  isOpen,
  onClose,
}) => {
  useEffect(() => {
    if (!isOpen) return;
    const autoClose = async () => {
      function sleep(ms: number) {
        return new Promise((resolve) => setTimeout(resolve, ms));
      }
      await sleep(1500);
      return onClose();
    };
    autoClose();
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        mt="35vh"
        mx="5"
        maxHeight="70%"
        rounded="xl"
        bgColor="transparent"
        boxShadow="none"
      >
        <ModalBody overflowY="auto" textAlign="center">
          {isOpen && <AddToCartAnimation />}
          <Text as="h4" color="white" fontWeight="500">
            Added to cart!
          </Text>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AddToCartPopup;
