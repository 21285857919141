import { Box, Select, Text } from "@chakra-ui/react";
import { FC } from "react";

const DispensarySortOptions: FC<{
  orderFilter: { priceOrder: string; costOrder: string };
  updateOrderFilter: any;
}> = ({ orderFilter, updateOrderFilter }) => {
  return (
    <Box
      mt={{ base: "7", xl: "14" }}
      display="flex"
      flexDir={{ base: "column", xl: "row" }}
      gap={{ base: "5", xl: "14" }}
      alignItems={{ base: "normal", xl: "center" }}
    >
      <Box
        ml={{ base: "0", xl: "auto" }}
        display="flex"
        flexDir={{ base: "column", xl: "row" }}
        alignItems={{ base: "normal", xl: "center" }}
        textAlign="left"
        gap={{ base: "0", xl: "5" }}
      >
        Price
        <Select
          my="2"
          w="full"
          value={orderFilter?.priceOrder}
          onChange={(e) => {
            updateOrderFilter({
              costOrder: "none",
              priceOrder: e.target.value,
            });
          }}
        >
          <option value="none">None</option>
          <option value="low">Low to High</option>
          <option value="high">High to Low</option>
        </Select>
      </Box>
      <Box
        display="flex"
        flexDir={{ base: "column", xl: "row" }}
        alignItems={{ base: "normal", xl: "center" }}
        textAlign="left"
        gap={{ base: "0", xl: "5" }}
      >
        <Text>Cost per gram / day</Text>
        <Select
          my="2"
          value={orderFilter?.costOrder}
          onChange={(e) => {
            updateOrderFilter({
              costOrder: e.target.value,
              priceOrder: "none",
            });
          }}
        >
          <option value="none">None</option>
          <option value="low">Low to High</option>
          <option value="high">High to Low</option>
        </Select>
      </Box>
    </Box>
  );
};

export default DispensarySortOptions;
