import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { FC } from "react";
import { medicalInformationFields } from "./PatientIntakeFormFields";

const MedicalInformationForm: FC<{
  patientIntakeInfo: any;
  updatePatientIntakeInfo: any;
}> = ({ patientIntakeInfo, updatePatientIntakeInfo }) => {
  return (
    <Box>
      <Text as="h3" textAlign="left" mt="14">
        Medical Information
      </Text>
      <Grid my="5" templateColumns="repeat(2, 1fr)" gap="7">
        {medicalInformationFields.map(
          (medicalInformationField: any, i: number) => {
            let colSpan = 2;
            if (medicalInformationField.type === "radio") {
              colSpan = 1;
            }
            return (
              <>
                {medicalInformationField.value === "substanceAbuse" && (
                  <GridItem />
                )}
                <GridItem key={i} colSpan={colSpan}>
                  <FormControl isRequired={medicalInformationField.required}>
                    {medicalInformationField.label && (
                      <FormLabel>{medicalInformationField.label}</FormLabel>
                    )}
                    {medicalInformationField?.type === "select" && (
                      <Select
                        value={patientIntakeInfo[medicalInformationField.value]}
                        onChange={(e) =>
                          updatePatientIntakeInfo({
                            ...patientIntakeInfo,
                            [medicalInformationField.value]: e.target.value,
                          })
                        }
                        color={
                          !patientIntakeInfo?.[medicalInformationField.value]
                            ? "cc.greyScale.200"
                            : "black"
                        }
                      >
                        <option value={undefined} disabled selected>
                          {medicalInformationField?.placeholder}
                        </option>
                        {medicalInformationField?.options?.map(
                          (option: any, i: number) => {
                            return (
                              <option key={i} value={option.value}>
                                {option.label}
                              </option>
                            );
                          }
                        )}
                      </Select>
                    )}

                    {medicalInformationField?.type === "textarea" && (
                      <Textarea
                        value={patientIntakeInfo[medicalInformationField.value]}
                        onChange={(e) =>
                          updatePatientIntakeInfo({
                            ...patientIntakeInfo,
                            [medicalInformationField.value]: e.target.value,
                          })
                        }
                        placeholder={medicalInformationField?.placeholder}
                      />
                    )}

                    {!medicalInformationField?.type && (
                      <Input
                        value={patientIntakeInfo[medicalInformationField.value]}
                        type={medicalInformationField.type}
                        onChange={(e) =>
                          updatePatientIntakeInfo({
                            ...patientIntakeInfo,
                            [medicalInformationField.value]: e.target.value,
                          })
                        }
                        placeholder={medicalInformationField?.placeholder}
                      />
                    )}

                    {medicalInformationField?.type === "radio" && (
                      <RadioGroup
                        name={medicalInformationField.value}
                        value={`${
                          patientIntakeInfo[medicalInformationField.value]
                        }`}
                        defaultValue="false"
                        onChange={(e) =>
                          updatePatientIntakeInfo({
                            ...patientIntakeInfo,
                            [medicalInformationField.value]:
                              e === "true" ? true : false,
                          })
                        }
                      >
                        <Stack>
                          <Radio value="true">Yes</Radio>
                          <Radio value="false">No</Radio>
                        </Stack>
                      </RadioGroup>
                    )}
                    {medicalInformationField.value === "substanceAbuse" &&
                      patientIntakeInfo.substanceAbuse && (
                        <>
                          <FormLabel mt="5">What is it?</FormLabel>
                          <Select
                            value={patientIntakeInfo.selectedSubstanceAbuse}
                            onChange={(e) =>
                              updatePatientIntakeInfo({
                                ...patientIntakeInfo,
                                selectedSubstanceAbuse: e.target.value,
                              })
                            }
                            color={
                              !patientIntakeInfo?.selectedSubstanceAbuse
                                ? "cc.greyScale.200"
                                : "black"
                            }
                          >
                            <option value={undefined} disabled selected>
                              Select
                            </option>
                            <option value="Alcohol">Alcohol</option>
                            <option value="Prescription Medications">
                              Prescription Medications
                            </option>
                            <option value="Illicit Drugs">Illicit Drugs</option>
                          </Select>
                        </>
                      )}
                    {medicalInformationField.value ===
                      "selfMedicationHistory" &&
                      patientIntakeInfo.selfMedicationHistory && (
                        <>
                          <FormLabel mt="5">
                            How many grams do you consume per day?
                          </FormLabel>
                          <Select
                            value={patientIntakeInfo.gramsPerDay}
                            onChange={(e) =>
                              updatePatientIntakeInfo({
                                ...patientIntakeInfo,
                                gramsPerDay: e.target.value,
                              })
                            }
                            color={
                              !patientIntakeInfo?.gramsPerDay
                                ? "cc.greyScale.200"
                                : "black"
                            }
                          >
                            <option value={undefined} disabled selected>
                              Select
                            </option>
                            <option value={"<1 gram per day"}>
                              {"<1 gram per day"}
                            </option>
                            <option value={"<2 grams per day"}>
                              {"<2 grams per day"}
                            </option>
                            <option value={"2-4 grams per day"}>
                              {"2-4 grams per day"}
                            </option>
                            <option value={">4 grams per day"}>
                              {">4 grams per day"}
                            </option>
                          </Select>
                        </>
                      )}
                  </FormControl>
                </GridItem>
              </>
            );
          }
        )}

        <GridItem textAlign="left" colSpan={2}>
          <Text as="h3" mt="14">
            Consent
          </Text>
          <Text my="5">
            We don’t contact your GP without your permission. Do you consent to
            us contacting your GP:
          </Text>

          <Box my="5">
            <FormControl isRequired>
              <FormLabel>To request a summary of health your history</FormLabel>
              <FormLabel>
                To send a summary of your consultation to your GP
              </FormLabel>
              <RadioGroup
                my="5"
                name={patientIntakeInfo.gpConsent}
                value={`${patientIntakeInfo.gpConsent}`}
                defaultValue="false"
                onChange={(e) =>
                  updatePatientIntakeInfo({
                    ...patientIntakeInfo,
                    gpConsent: e === "true" ? true : false,
                  })
                }
              >
                <Stack>
                  <Radio value="true">Yes</Radio>
                  <Radio value="false">No</Radio>
                </Stack>
              </RadioGroup>
            </FormControl>

            {patientIntakeInfo.gpConsent && (
              <FormControl isRequired>
                <FormLabel>GP Email</FormLabel>
                <Input
                  value={patientIntakeInfo.gpEmail}
                  onChange={(e) =>
                    updatePatientIntakeInfo({
                      ...patientIntakeInfo,
                      gpEmail: e.target.value,
                    })
                  }
                  placeholder="GP Email..."
                />
              </FormControl>
            )}

            <Flex gap="7" my="8">
              <FormControl isRequired>
                <FormLabel>Patient GP Name</FormLabel>
                <Input
                  value={patientIntakeInfo.gpName}
                  onChange={(e) =>
                    updatePatientIntakeInfo({
                      ...patientIntakeInfo,
                      gpName: e.target.value,
                    })
                  }
                  placeholder="GP Name..."
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel>GP Location</FormLabel>
                <Input
                  value={patientIntakeInfo.gpLocation}
                  onChange={(e) =>
                    updatePatientIntakeInfo({
                      ...patientIntakeInfo,
                      gpLocation: e.target.value,
                    })
                  }
                  placeholder="GP Location..."
                />
              </FormControl>
            </Flex>
          </Box>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default MedicalInformationForm;
