import {
  Box,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Select,
  Text,
} from "@chakra-ui/react";
import { FC } from "react";
import { patientInfoFields } from "./PatientIntakeFormFields";

const PatientInformationForm: FC<{
  patientIntakeInfo: any;
  updatePatientIntakeInfo: any;
}> = ({ patientIntakeInfo, updatePatientIntakeInfo }) => {
  return (
    <Box>
      <Text as="h3" textAlign="left" mt="12">
        Patient Information
      </Text>
      <Grid my="5" templateColumns="repeat(2, 1fr)" gap="7">
        {patientInfoFields.map((patientInfoField: any, i: number) => {
          return (
            <FormControl key={i} isRequired={patientInfoField.required}>
              {patientInfoField.label && (
                <FormLabel>{patientInfoField.label}</FormLabel>
              )}
              {patientInfoField?.type === "select" ? (
                <Select
                  value={patientIntakeInfo[patientInfoField.value]}
                  onChange={(e) =>
                    updatePatientIntakeInfo({
                      ...patientIntakeInfo,
                      [patientInfoField.value]: e.target.value,
                    })
                  }
                  color={
                    !patientIntakeInfo?.[patientInfoField.value]
                      ? "cc.greyScale.200"
                      : "black"
                  }
                >
                  <option value={undefined} disabled selected>
                    {patientInfoField?.placeholder}
                  </option>
                  {patientInfoField?.options?.map((option: any, i: number) => {
                    return (
                      <option key={i} value={option.value}>
                        {option.label}
                      </option>
                    );
                  })}
                </Select>
              ) : (
                <Input
                  value={patientIntakeInfo[patientInfoField.value]}
                  type={patientInfoField.type}
                  onChange={(e) =>
                    updatePatientIntakeInfo({
                      ...patientIntakeInfo,
                      [patientInfoField.value]: e.target.value,
                    })
                  }
                  placeholder={patientInfoField?.placeholder}
                />
              )}
            </FormControl>
          );
        })}
      </Grid>
    </Box>
  );
};

export default PatientInformationForm;
