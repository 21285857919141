import { CloseIcon } from "@chakra-ui/icons";
import { Box, Flex, Text } from "@chakra-ui/react";
import { FC, useState } from "react";

const WarningBanner: FC<{ type: "warning" | "reminder"; message: string }> = ({
  type,
  message,
}) => {
  const [showBanner, setShowBanner] = useState(true);

  if (!showBanner) return null;
  return (
    <Flex
      bgColor="#FFF3F3"
      p="5"
      rounded="2xl"
      textAlign="left"
      fontSize="sm"
      mt="5"
    >
      <Box>
        <Text color="cc.red" fontWeight="semibold">
          {type === "reminder" ? "Reminder" : "Warning"}
        </Text>
        <Text color="cc.red" mt="1">
          {message}
        </Text>
      </Box>
      <CloseIcon
        color="cc.red"
        ml="auto"
        w="3"
        cursor="pointer"
        onClick={() => setShowBanner(false)}
      />
    </Flex>
  );
};

export default WarningBanner;
