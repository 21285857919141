import { ArrowForwardIcon } from "@chakra-ui/icons";
import { Box, GridItem, Text, useDisclosure } from "@chakra-ui/react";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  MedusaProduct,
  MedusaProductVariant,
  useAddToCartMutation,
} from "../../../../../generated/graphql";
import useCalculateCost from "../../../../../hooks/patient/dispensary/useCalculateCost";
import useGetTotalStockQuantity from "../../../../../hooks/patient/dispensary/useGetTotalStockQuantity";
import useIsProductThc from "../../../../../hooks/patient/dispensary/useIsProductThc";
import AddToCartPopup from "../../cart/AddToCartPopup";
import ProductImageSection from "./components/ProductImageSection";
import ProductPreviewVariantsBanner from "./components/ProductPreviewVariantsBanner";
import ProductStatusLabel from "./components/ProductStatusLabel";

const ProductPreview: FC<{
  product: MedusaProduct;
  isCartReviewPage?: boolean | undefined;
}> = ({ product, isCartReviewPage }) => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { variants, categories } = product;
  const [isDiscounted, setIsDiscounted] = useState(false);
  const [isComingSoon, setIsComingSoon] = useState(false);
  const [isVariantsBannerVisible, setIsVariantsBannerVisible] = useState(false);

  const totalStockQuantity = useGetTotalStockQuantity(variants);
  const isProductThc = useIsProductThc(categories);
  const cost = useCalculateCost(
    product?.average_dose,
    product?.total_size,
    product?.unit,
    product?.variants[0].calculated_price,
    product?.categories[0].handle
  );

  const convertPrice = (
    variant: MedusaProductVariant,
    priceKey: "calculated_price" | "original_price"
  ) => {
    if (!variant || !variant[priceKey]) return 0;
    return parseInt(variant[priceKey] ?? "0") / 100;
  };

  const getVariantPricing = (variant: MedusaProductVariant) => {
    const calculatedPrice = convertPrice(variant, "calculated_price");
    const originalPrice = convertPrice(variant, "original_price");
    const discountPercentage =
      originalPrice - (calculatedPrice / originalPrice) * 100;
    const isDiscounted = originalPrice > calculatedPrice;

    return {
      calculatedPrice,
      originalPrice,
      discountPercentage,
      isDiscounted,
    };
  };

  const calculateIsDiscounted = () => {
    for (const variant of variants) {
      const pricing = getVariantPricing(variant);
      if (pricing.isDiscounted) return setIsDiscounted(pricing?.isDiscounted);
    }
    return setIsDiscounted(false);
  };

  const calculateIsComingSoon = () => {
    if (totalStockQuantity === 0) return setIsComingSoon(true);
    return setIsComingSoon(false);
  };

  const handleAddToCart = () => {
    if (product.variants.length > 1) {
      setIsVariantsBannerVisible(true);
    } else {
      addToCart({
        variables: {
          input: {
            lineItems: [
              {
                id: product.id,
                quantity: 1,
              },
            ],
          },
        },
      });
    }
  };

  const [addToCart] = useAddToCartMutation({
    onCompleted: () => {
      onOpen();
    },
    onError: (error: any) => {
      return alert(error.message);
    },
  });

  useEffect(() => {
    calculateIsDiscounted();
    calculateIsComingSoon();
  }, [product]);

  return (
    <GridItem textAlign="left" display="flex" flexDir="column">
      <Box
        position="relative"
        fontSize={{ base: "xx-small", lg: "xs" }}
        cursor="pointer"
      >
        <ProductStatusLabel
          isComingSoon={isComingSoon}
          isDiscounted={isDiscounted}
          isPreOrder={product.pre_order ?? false}
        />
        <ProductPreviewVariantsBanner
          variants={variants}
          isVisible={isVariantsBannerVisible}
          updateIsVisible={setIsVariantsBannerVisible}
        >
          <ProductImageSection
            product={product}
            isCartReviewPage={isCartReviewPage}
            isProductThc={isProductThc}
            isDiscounted={isDiscounted}
            getVariantPricing={getVariantPricing}
          />
        </ProductPreviewVariantsBanner>
      </Box>
      <Box
        onClick={() => navigate(`/patient/dispensary/${product.handle}`)}
        cursor="pointer"
      >
        <Text fontSize={{ base: "sm", lg: "md" }} fontWeight="500">
          {product.title}
          {product.variants.length === 1 && ` - ${product.variants[0].title}`}
        </Text>
        <Text fontSize="xs" my="1">
          {product.subtitle}
          {product.pre_order && (
            <Text lineHeight="1.4">
              Available in 2-3 weeks & may be shipped separately.{" "}
            </Text>
          )}
        </Text>
        <Text fontSize="xs" fontWeight="600" color="black">
          {variants.map((variant, i) => {
            const variantPricing = getVariantPricing(variant);
            if (!variantPricing) return null;
            if (!isDiscounted || !variantPricing.isDiscounted) {
              return (
                <Text key={i} as="span" color="black">
                  ${variantPricing.originalPrice.toFixed(2)}{" "}
                  {i === 0 && variants.length > 1 && ` - `}
                </Text>
              );
            }
            return (
              <>
                <Text key={i} color="cc.greyScale.100" as="s" fontWeight="500">
                  ${variantPricing.originalPrice.toFixed(2)}
                </Text>{" "}
                <Text as="span" color="red">
                  ${variantPricing.calculatedPrice.toFixed(2)}{" "}
                  <Text as="span">
                    ({variantPricing.discountPercentage?.toFixed(0)}
                    {"% "}
                    off)
                  </Text>
                  {i === 0 && variants.length > 1 && ` - `}
                </Text>
              </>
            );
          })}
          <Text as="span" fontWeight="400" fontSize="12">
            {" "}
            incl GST
          </Text>
        </Text>
        <Text fontSize="11">{cost}</Text>
      </Box>
      <Text
        cursor="pointer"
        color="cc.primary.100"
        fontWeight="600"
        fontSize="xs"
        onClick={handleAddToCart}
      >
        Add to cart
        <ArrowForwardIcon ml="2" />
      </Text>
      <AddToCartPopup isOpen={isOpen} onClose={onClose} />
    </GridItem>
  );
};

export default ProductPreview;
