import { Flex, Text } from "@chakra-ui/react";
import LoadingAnimation from "../../components/LoadingAnimation";
import ProfileDetails from "../../features/patients/account/ProfileDetails";
import ShippingDetails from "../../features/patients/account/ShippingDetails";
import { useUserQuery } from "../../generated/graphql";
import MainLayout from "../../layouts/MainLayout";

const Account = () => {
  const { data, loading, refetch } = useUserQuery();

  if (loading) return <LoadingAnimation />;

  if (!data?.user) return null;

  return (
    <MainLayout>
      <Text as="h1" textAlign="left">
        Account Settings
      </Text>
      <Flex
        flexDir={{ base: "column", xl: "row" }}
        gap={{ base: 5, xl: 10 }}
        my="8"
        textAlign="left"
        fontSize={{ base: "sm", lg: "md" }}
      >
        <ProfileDetails user={data?.user} />
        <ShippingDetails user={data?.user} refetch={refetch} />
      </Flex>
    </MainLayout>
  );
};

export default Account;
