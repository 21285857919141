import { Box, Button, Flex, Spacer, Text } from "@chakra-ui/react";
import { FC, useState } from "react";
import { FulfillmentOrder } from "../../../generated/graphql";
import { ReactComponent as OrderIcon } from "../../../images/orders.svg";
import ViewOrderDetails from "./ViewOrderDetails";

export const orderFields: {
  label: string;
  value: keyof FulfillmentOrder;
}[] = [
  {
    label: "Order ID",
    value: "medusaOrderId",
  },
  {
    label: "Total Price",
    value: "totalPrice",
  },

  {
    label: "Order Date",
    value: "fulfillmentDate",
  },
  {
    label: "Status",
    value: "status",
  },
  {
    label: "Shipping No.",
    value: "shippingNo",
  },
];

export const formatOrderValue = (
  fulfillmentOrder: FulfillmentOrder,
  value: keyof FulfillmentOrder
) => {
  if (value === "medusaOrderId") {
    return (
      "#" +
      fulfillmentOrder[value].substring(
        fulfillmentOrder[value].length - 10,
        fulfillmentOrder[value].length
      )
    );
  }
  if (value === "fulfillmentDate") {
    const date = new Date(fulfillmentOrder[value]);
    return `${date.toLocaleDateString("en-NZ", {
      year: "numeric",
      month: "long",
      day: "numeric",
      timeZone: "nz",
    })}`;
  }
  if (value === "totalPrice") {
    return `$${fulfillmentOrder[value].toFixed(2)}`;
  }
  return fulfillmentOrder[value]?.toString();
};

const DisplayOrder: FC<{ fulfillmentOrder: FulfillmentOrder }> = ({
  fulfillmentOrder,
}) => {
  const [viewOrder, setViewOrder] = useState(false);

  return (
    <Box bgColor="cc.lightScale.200" borderRadius="2xl">
      <Flex
        textAlign={{ base: "left", xl: "center" }}
        flexDir={{ base: "column", xl: "row" }}
        gap={{ base: 2, xl: 10 }}
        px={{ base: 5, xl: 10 }}
        mx="auto"
        pt="7"
        pb="5"
      >
        <Flex
          w="full"
          position="relative"
          flexWrap={{ base: "wrap", xl: "nowrap" }}
          gap={{ base: 2, xl: 10 }}
        >
          <Box
            display={{ base: "inherit", xl: "none" }}
            position="absolute"
            right="0"
            top="0"
            color="cc.primary.500"
          >
            <OrderIcon />
          </Box>
          {orderFields.map((orderField, i) => {
            return (
              <>
                <Text
                  key={i}
                  display="block"
                  w={{
                    base: i === 0 ? "100%" : "40%",
                    xl: "initial",
                  }}
                >
                  <Text>{orderField.label}</Text>
                  <Text
                    fontWeight="semibold"
                    my="2"
                    color={
                      orderField.value === "status" &&
                      fulfillmentOrder[orderField.value].toLowerCase() !==
                        "complete"
                        ? "cc.greyScale.200"
                        : "black"
                    }
                  >
                    {formatOrderValue(fulfillmentOrder, orderField.value)}
                  </Text>
                </Text>
                <Spacer
                  display={{
                    base: i > 0 ? "block" : "none",
                    xl: "block",
                  }}
                />
              </>
            );
          })}
          <Button
            w={{ base: "full", xl: "initial" }}
            variant={viewOrder ? "primaryDarkOutline" : "primaryDark"}
            py="6"
            fontSize="sm"
            onClick={() => setViewOrder(!viewOrder)}
          >
            {viewOrder ? "Hide Order" : "View Order"}
          </Button>
        </Flex>
      </Flex>
      {viewOrder && <ViewOrderDetails fulfillmentOrder={fulfillmentOrder} />}
    </Box>
  );
};

export default DisplayOrder;
