import {
  Box,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  GridItem,
  Input,
  Radio,
  RadioGroup,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Dispatch, FC, SetStateAction, useEffect } from "react";

export interface Address {
  streetAddress: string;
  company: string;
  suburb: string;
  region: string;
  city: string;
  country: string;
  postCode: string;
}

const addressFields: {
  label: string;
  value: keyof Address;
}[] = [
  {
    label: "Street Address",
    value: "streetAddress",
  },
  {
    label: "Company",
    value: "company",
  },
  {
    label: "Suburb",
    value: "suburb",
  },
  {
    label: "Region",
    value: "region",
  },
  {
    label: "City",
    value: "city",
  },
  {
    label: "Country",
    value: "country",
  },
  {
    label: "Post Code",
    value: "postCode",
  },
];

const AddressInput: FC<{
  type: "shipping" | "billing";
  address: Address;
  updateAddress: any;
}> = ({ type, address, updateAddress }) => {
  return (
    <SimpleGrid columns={{ base: 1, xl: 2 }} gap="5" columnGap={{ base: 3 }}>
      {addressFields.map((addressField, i) => {
        return (
          <GridItem colSpan={i > 2 ? 1 : 2} key={i}>
            <FormControl isRequired={addressField.value !== "company"}>
              <FormLabel>{addressField.label}</FormLabel>
              <Input
                isDisabled={
                  type === "shipping" && addressField.value === "country"
                }
                value={address[addressField.value]}
                placeholder={`Enter ${addressField.label}`}
                type={addressField.value}
                onChange={(e) =>
                  updateAddress({
                    ...address,
                    [addressField.value]: e.target.value,
                  })
                }
              />
            </FormControl>{" "}
          </GridItem>
        );
      })}
    </SimpleGrid>
  );
};

const AddressForms: FC<{
  shippingAddress: Address;
  updateShippingAddress: Dispatch<SetStateAction<Address | undefined>>;
  shippingOption: string;
  updateShippingOption: Dispatch<SetStateAction<string>>;
  isBillingSeparate: boolean;
  updateIsBillingSeparate: Dispatch<SetStateAction<boolean>>;
  billingAddress: Address;
  updateBillingAddress: Dispatch<SetStateAction<Address | undefined>>;
}> = ({
  shippingAddress,
  updateShippingAddress,
  shippingOption,
  updateShippingOption,
  isBillingSeparate,
  updateIsBillingSeparate,
  billingAddress,
  updateBillingAddress,
}) => {
  useEffect(() => {
    if (isBillingSeparate) updateBillingAddress(shippingAddress);
  }, [isBillingSeparate]);

  return (
    <>
      <Flex flexDir="column" gap="5">
        <Box>
          <Text as="h5" textAlign="left">
            Shipping Address
          </Text>
          <Divider display={{ base: "none", xl: "block" }} my="3" />
        </Box>
        <AddressInput
          address={shippingAddress}
          updateAddress={updateShippingAddress}
          type="shipping"
        />
      </Flex>

      <Flex flexDir="column" textAlign="left" gap="5">
        <Box>
          <Text as="h5" textAlign="left">
            Shipping Method
          </Text>
          <Divider display={{ base: "none", xl: "block" }} my="3" />
        </Box>
        <RadioGroup
          onChange={(e) => updateShippingOption(e)}
          value={shippingOption}
        >
          <Stack direction="column" gap="3">
            <Radio className="default" value="standard">
              Standard Shipping
            </Radio>
            <Radio className="default" value="rural">
              Rural Shipping
            </Radio>
          </Stack>
        </RadioGroup>
      </Flex>

      <Flex flexDir="column" gap="5">
        <Box>
          <Text as="h5" textAlign="left">
            Billing Address
          </Text>
          <Divider display={{ base: "none", xl: "block" }} my="3" />
        </Box>
        <Box textAlign="left" mb="5">
          <Text mb="5">
            Select the address that matches your card or payment method
          </Text>
          <RadioGroup
            onChange={(e) => {
              if (e === "true") return updateIsBillingSeparate(true);
              updateIsBillingSeparate(false);
              updateBillingAddress(shippingAddress);
            }}
            value={isBillingSeparate === true ? "true" : "false"}
          >
            <Stack direction="column" gap="3">
              <Radio className="default" value="false">
                Same as shipping address
              </Radio>
              <Radio className="default" value="true">
                Use a different billing address
              </Radio>
            </Stack>
          </RadioGroup>
        </Box>
        {isBillingSeparate && (
          <AddressInput
            address={billingAddress}
            updateAddress={updateBillingAddress}
            type="billing"
          />
        )}
      </Flex>
    </>
  );
};

export default AddressForms;
