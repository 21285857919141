import { Box, Flex } from "@chakra-ui/react";
import LoadingAnimation from "../../../../../components/LoadingAnimation";
import { useFulfillmentOrdersQuery } from "../../../../../generated/graphql";
import DisplayOrderPreview from "../../../orders/DisplayOrderPreview";

const OrdersPreview = () => {
  const { data, loading } = useFulfillmentOrdersQuery();

  if (loading) return <LoadingAnimation />;

  if (data?.fulfillmentOrders || !data?.fulfillmentOrders.length)
    return (
      <Flex pb="24" color="cc.greyScale.200">
        No Orders to display
      </Flex>
    );

  return (
    <Flex direction="column" gap="3" w="full">
      {data?.fulfillmentOrders.map((order: any, i) => {
        return <DisplayOrderPreview key={i} fulfillmentOrder={order} />;
      })}
      <Box p="1" />
    </Flex>
  );
};

export default OrdersPreview;
