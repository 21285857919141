import LoginForm from "../../../features/auth/LoginForm";
import AuthEntryLayout from "../../../layouts/AuthEntryLayout";

const PatientLogin = () => {
  return (
    <AuthEntryLayout>
      <LoginForm type="patient" />
    </AuthEntryLayout>
  );
};

export default PatientLogin;
