import { CheckIcon } from "@chakra-ui/icons";
import { Box, Flex, Text } from "@chakra-ui/react";
import { FC } from "react";
import { MedusaProductCategory } from "../../../../../../generated/graphql";
import useIsProductThc from "../../../../../../hooks/patient/dispensary/useIsProductThc";

const SubscribeSelect: FC<{
  size?: "sm" | "lg";
  categories: MedusaProductCategory[];
  initialCalculatedPrice: number;
  subscriptionSelected: boolean;
  updateSubscriptionSelected: (value: boolean) => void;
}> = ({
  size,
  categories,
  initialCalculatedPrice,
  subscriptionSelected,
  updateSubscriptionSelected,
}) => {
  const isSizeSmall = size === "sm";
  const isProductCbd =
    !useIsProductThc(categories) && categories[0].handle.includes("cbd");

  if (!isProductCbd) return null;
  return (
    <Flex flexDir="column" gap="5" w="full">
      <Flex
        onClick={() => updateSubscriptionSelected(!subscriptionSelected)}
        gap="3"
        borderWidth="1.5px"
        borderColor={
          subscriptionSelected ? "cc.primary.500" : "cc.greyScale.100"
        }
        p={{ base: isSizeSmall ? 3 : 4, xl: isSizeSmall ? 3 : 5 }}
        rounded="2xl"
        cursor="pointer"
        fontSize={{
          base: isSizeSmall ? "xs" : "sm",
          xl: isSizeSmall ? "sm" : "md",
        }}
      >
        <Box
          borderWidth="1px"
          borderColor={subscriptionSelected ? "transparent" : "black"}
          px="1.5"
          py=""
          rounded="md"
          ml="1"
          backgroundColor={
            subscriptionSelected ? "cc.primary.500" : "transparent"
          }
          maxH="6"
        >
          <CheckIcon color="white" w="2.5" h="2.5" />
        </Box>
        <Text>Subscribe & Save 10%</Text>
        <Text ml="auto" fontWeight="600">
          ${(initialCalculatedPrice * 0.9).toFixed(2)}
        </Text>
      </Flex>
    </Flex>
  );
};

export default SubscribeSelect;
