import {
  Box,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Text,
} from "@chakra-ui/react";
import { Dispatch, FC, useEffect, useState } from "react";

const PatientPasswordForm: FC<{
  patientAccountInfo: any;
  updatePatientAccountInfo: Dispatch<any>;
}> = ({ patientAccountInfo, updatePatientAccountInfo }) => {
  const [passwordValid, setPasswordValid] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(false);
  const isPasswordValid =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;

  useEffect(() => {
    if (isPasswordValid.test(patientAccountInfo.password)) {
      setPasswordValid(true);
    } else {
      setPasswordValid(false);
    }

    if (patientAccountInfo.password === patientAccountInfo.confirmPassword) {
      setPasswordMatch(true);
    } else {
      setPasswordMatch(false);
    }
  }, [patientAccountInfo.password, patientAccountInfo.confirmPassword]);

  return (
    <Box>
      <Text as="h3" textAlign="left" mt="8">
        Account
      </Text>
      <Grid my="5" templateColumns="repeat(2, 1fr)" gap="7">
        <FormControl isInvalid={!passwordValid || !passwordMatch} isRequired>
          <FormLabel>Password</FormLabel>
          <Input
            type="password"
            placeholder="Password"
            value={patientAccountInfo.password}
            onChange={(e) =>
              updatePatientAccountInfo({
                ...patientAccountInfo,
                password: e.target.value,
              })
            }
          />
        </FormControl>
        <Box />
        <FormControl isRequired>
          <FormLabel>Confirm Password</FormLabel>
          <Input
            type="password"
            placeholder="Confirm Password"
            value={patientAccountInfo.confirmPassword}
            onChange={(e) =>
              updatePatientAccountInfo({
                ...patientAccountInfo,
                confirmPassword: e.target.value,
              })
            }
          />
        </FormControl>
      </Grid>
      {!passwordMatch && (
        <Text color="darkred" fontSize="sm" textAlign="left">
          * Passwords must match
        </Text>
      )}
      {!passwordValid && (
        <Text fontSize="sm" textAlign="left" color="darkred">
          * Password must be more than 6 characters, and include at least 1
          uppercase character as well as at least 1 numeric or special character
        </Text>
      )}
    </Box>
  );
};

export default PatientPasswordForm;
