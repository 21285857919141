import { Flex, FormControl, FormLabel, Input } from "@chakra-ui/react";
import { Dispatch, FC, SetStateAction } from "react";

interface PatientBasicInfo {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
}

const PatientBasicInfoForm: FC<{
  basicInfo: PatientBasicInfo;
  updateBasicInfo: Dispatch<
    SetStateAction<{
      firstName: string;
      lastName: string;
      phoneNumber: string;
      email: string;
    }>
  >;
}> = ({ basicInfo, updateBasicInfo }) => {
  return (
    <Flex flexDir="column" gap="6" my="7">
      {[
        { label: "First Name(s) (Legal)", value: "firstName" },
        { label: "Last Name (Legal)", value: "lastName" },
        { label: "Phone", value: "phoneNumber" },
        { label: "Email", value: "email" },
      ].map(({ label, value }, i) => {
        return (
          <FormControl isRequired key={i}>
            <FormLabel>{label}</FormLabel>
            <Input
              onChange={(e) =>
                updateBasicInfo({ ...basicInfo, [value]: e.target.value })
              }
            />
          </FormControl>
        );
      })}
    </Flex>
  );
};

export default PatientBasicInfoForm;
