import { Box, Button, Flex, Text } from "@chakra-ui/react";
// import healthNow from "../../../images/health-now.png";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ReactComponent as CartIcon } from "../../../../../images/cartIcon.svg";
import MainLayout from "../../../../../layouts/MainLayout";

const Result = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const status = searchParams.get("status");
  const isSuccess = status === "success" ? true : false;

  useEffect(() => {
    if (!status) return navigate("/patient/dispensary/cart");
    if (status && isSuccess) {
      localStorage.removeItem("cart_id");
      localStorage.removeItem("checkout_step");
    }
  }, []);

  return (
    <MainLayout>
      <Flex flexDir="column" my="auto" mt="40" gap="5">
        <Box mx="auto">
          <CartIcon color="#5197DE" width="65" height="65" />
        </Box>
        <Text as="h2" textAlign="center">
          {isSuccess ? "Order Completed!" : "Oops! Your order has failed..."}
        </Text>
        <Text>
          {isSuccess
            ? "Thanks for your order!"
            : " Try again, please make sure you’ve entered your details correctly."}
        </Text>
        <Button
          variant="primaryDark"
          mx="auto"
          mt="5"
          onClick={() =>
            navigate(
              isSuccess
                ? "/patient/dispensary"
                : "/patient/dispensary/cart/checkout"
            )
          }
        >
          {isSuccess ? "Back to Dispensary" : "Back to Checkout"}
        </Button>
      </Flex>
    </MainLayout>
  );
};

export default Result;
