import { Box, Flex } from "@chakra-ui/react";
import { FC } from "react";
import { Prescription } from "../../../generated/graphql";
import DisplayPrescription from "./DisplayPrescription";

const DisplayOrders: FC<{
  prescriptions: any;
  updatePrescriptionToOrder: React.Dispatch<
    React.SetStateAction<Prescription | undefined>
  >;
}> = ({ prescriptions, updatePrescriptionToOrder }) => {
  if (!prescriptions || !prescriptions.length)
    return (
      <Box as="h6" mt="10" my="3" color="cc.primary.500">
        No Prescriptions to display!
      </Box>
    );

  return (
    <Flex flexDir="column" gap="7" mb="10" mt="12">
      {prescriptions.map((prescription: any, i: number) => {
        return (
          <DisplayPrescription
            key={i}
            prescription={prescription}
            updatePrescriptionToOrder={updatePrescriptionToOrder}
          />
        );
      })}
    </Flex>
  );
};

export default DisplayOrders;
