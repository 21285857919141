import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { FC, useEffect, useState } from "react";
import { MedusaLineItem } from "../../../../../../generated/graphql";
import productPlaceholder from "../../../../../../images/productPlaceholder.png";

const CheckoutProduct: FC<{
  lineItem: MedusaLineItem;
}> = ({ lineItem }) => {
  const [quantity, setQuantity] = useState(lineItem.quantity);
  const [discountPercentage, setDiscountPercentage] = useState<number>();

  const calculateDiscount = () => {
    const originalTotal = lineItem.original_total;
    const unitPrice = lineItem.unit_price;
    const originalUnitPrice = originalTotal / quantity;

    if (originalUnitPrice <= unitPrice) return setDiscountPercentage(undefined);
    setDiscountPercentage(originalUnitPrice / unitPrice);
  };

  useEffect(() => {
    calculateDiscount();
  }, [lineItem]);

  return (
    <>
      <Flex gap="3">
        <Flex
          borderWidth="1px"
          borderRadius="xl"
          borderColor="cc.greyScale.100"
          maxH="20"
          alignItems="center"
        >
          <Image
            maxW="20"
            maxH="20"
            src={productPlaceholder}
            objectFit="cover"
          />
        </Flex>
        <Box w="full">
          <Flex flexDir="column" gap="1" fontSize="sm" textAlign="left">
            <Flex>
              <Text fontWeight="600">Product</Text>
              <Text ml="auto">{lineItem.title}</Text>
            </Flex>

            <Flex>
              <Text fontWeight="600">Quantity</Text>
              <Text ml="auto">{lineItem.quantity}</Text>
            </Flex>

            <Box>
              <Flex>
                <Text fontWeight="600">Total</Text>
                <Flex gap="1" ml="auto">
                  {discountPercentage && (
                    <Text
                      as={discountPercentage ? "s" : "p"}
                      color="cc.greyScale.200"
                    >
                      $
                      {(
                        (lineItem.unit_price * quantity * discountPercentage) /
                        100
                      ).toFixed(2)}
                    </Text>
                  )}
                  <Text>
                    ${((lineItem.unit_price * quantity) / 100).toFixed(2)}
                  </Text>
                </Flex>
              </Flex>
              <Text textAlign="right">
                {lineItem?.metadata?.subscribe && (
                  <Text fontSize="sm" color="cc.greyScale.200">
                    Subscription (Save 10%)
                  </Text>
                )}
              </Text>
              {/* {lineItem?.metadata?.pre_order && (
                <Box
                  fontSize={{ base: "10px", xl: "12px" }}
                  mt={{ base: 0, xl: 1 }}
                  lineHeight="1.7"
                  my="1"
                >
                  <Text fontWeight="600">
                    This pre-order will be charged upfront
                  </Text>
                  <Text>
                    Available in 2-3 weeks & may be shipped separately.
                  </Text>
                </Box>
              )} */}
            </Box>
          </Flex>
        </Box>
      </Flex>
    </>
  );
};

export default CheckoutProduct;
