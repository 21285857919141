import { Box, Text } from "@chakra-ui/react";
import { FC, useEffect, useState } from "react";

import "swiper/css";
import LoadingAnimation from "../../../../components/LoadingAnimation";

import {
  useAcuityAppointmentFormDataQuery,
  useAcuityAvailableAppointmentDaysLazyQuery,
} from "../../../../generated/graphql";
import SelectAppointmentCalendar from "./components/SelectAppointmentCalendar";
import SelectAppointmentCategory from "./components/SelectAppointmentCategory";
import SelectAppointmentTime from "./components/SelectAppointmentTime";
import SelectAppointmentType from "./components/SelectAppointmentType";

const AcuityAvailabilityForm: FC<{
  updateSelectedAppointmentTypeId: (appointmentId: number | undefined) => void;
  updateSelectedBookingTime: (bookingTime: Date | undefined) => void;
}> = ({ updateSelectedAppointmentTypeId, updateSelectedBookingTime }) => {
  const [selectedCategory, setSelectedCategory] = useState<any>();
  const [selectedAppointmentType, setSelectedAppointmentType] = useState<any>();
  const [selectedCalendar, setSelectedCalendar] = useState<any>();

  const currentDate = new Date();
  const [selectedMonth, setSelectedMonth] = useState<Date>(currentDate);
  const [selectedTime, setSelectedTime] = useState<Date>();

  const { data: bookingFormData, loading } =
    useAcuityAppointmentFormDataQuery();
  const [
    getAvailableDays,
    { loading: isAvailableDaysLoading, data: availableDays },
  ] = useAcuityAvailableAppointmentDaysLazyQuery({
    variables: {
      input: {
        month: selectedMonth,
        appointmentTypeId: selectedAppointmentType?.id,
        calendarId: selectedCalendar?.id ?? undefined,
      },
    },
  });

  // Get calendar availability
  useEffect(() => {
    if (selectedCalendar) getAvailableDays();
  }, [selectedCalendar]);

  useEffect(() => {
    updateSelectedBookingTime(selectedTime);
    updateSelectedAppointmentTypeId(selectedAppointmentType?.id);
  }, [selectedTime]);

  if (loading) return <LoadingAnimation />;

  return (
    <Box mb="10">
      <Box>
        <Text as="h5" textAlign="left" pt={{ base: 0, xl: 4 }}>
          <Text as="span" color="cc.primary.500" fontWeight="500">
            Step One
          </Text>
          <Text as="span" fontWeight="600">
            {" "}
            - Choose Appointment
          </Text>
        </Text>
        <SelectAppointmentCategory
          appointmentCategories={
            bookingFormData?.acuityAppointmentFormData.appointmentCategories
          }
          selectedCategory={selectedCategory}
          updateSelectedCategory={setSelectedCategory}
        />
        <SelectAppointmentType
          appointmentTypes={
            bookingFormData?.acuityAppointmentFormData.appointmentTypes
          }
          selectedAppointmentType={selectedAppointmentType}
          selectedCategory={selectedCategory}
          updateSelectedAppointmentType={setSelectedAppointmentType}
          updateSelectedCalendar={setSelectedCalendar}
          updateSelectedTime={setSelectedTime}
        />
        <SelectAppointmentCalendar
          calendars={bookingFormData?.acuityAppointmentFormData.calendars}
          selectedCalendar={selectedCalendar}
          selectedCategory={selectedCategory}
          selectedTime={selectedTime}
          selectedAppointmentType={selectedAppointmentType}
          updateSelectedCalendar={setSelectedCalendar}
          updateSelectedTime={setSelectedTime}
        />
        <SelectAppointmentTime
          availableDays={availableDays?.acuityAvailableAppointmentDays}
          isAvailableDaysLoading={isAvailableDaysLoading}
          selectedCalendar={selectedCalendar}
          selectedCategory={selectedCategory}
          selectedTime={selectedTime}
          selectedAppointmentType={selectedAppointmentType}
          updateSelectedTime={setSelectedTime}
        />
      </Box>
    </Box>
  );
};

export default AcuityAvailabilityForm;
