import { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useCartLazyQuery } from "./generated/graphql";
import Account from "./pages/patient/account";
import Appointments from "./pages/patient/appointments";
import PatientDashboard from "./pages/patient/dashboard";
import Dispensary from "./pages/patient/dispensary";
import Cart from "./pages/patient/dispensary/cart";
import Checkout from "./pages/patient/dispensary/cart/checkout";
import Payment from "./pages/patient/dispensary/cart/checkout/payment";
import Result from "./pages/patient/dispensary/cart/checkout/result";
import IndividualProduct from "./pages/patient/dispensary/product";
import CompleteSignup from "./pages/patient/onboarding/completeSignup";
import InitialBooking from "./pages/patient/onboarding/initialBooking";
import PatientLogin from "./pages/patient/onboarding/patientLogin";
import PatientSignup from "./pages/patient/onboarding/patientSignup";
import Orders from "./pages/patient/orders";
import Scripts from "./pages/patient/scripts";
import RequestResetPassword from "./pages/requestResetPassword";
import ResetPassword from "./pages/resetPassword";

const pages: { path: string; component: React.FunctionComponent }[] = [
  {
    path: "/patient/dashboard",
    component: PatientDashboard,
  },
  {
    path: "/patient/orders",
    component: Orders,
  },
  {
    path: "/patient/scripts",
    component: Scripts,
  },
  {
    path: "/patient/appointments",
    component: Appointments,
  },
  {
    path: "/patient/account",
    component: Account,
  },
  {
    path: "/patient/dispensary",
    component: Dispensary,
  },
  {
    path: "/patient/dispensary/:id",
    component: IndividualProduct,
  },
  {
    path: "/patient/dispensary/cart",
    component: Cart,
  },
  {
    path: "/patient/dispensary/cart/checkout",
    component: Checkout,
  },
  {
    path: "/patient/dispensary/cart/checkout/payment",
    component: Payment,
  },
  {
    path: "/patient/dispensary/cart/checkout/result",
    component: Result,
  },
];

// Don't require local storage auth
const authPages = [
  {
    path: "/patient/initial-booking",
    component: InitialBooking,
  },
  {
    path: "/patient/login",
    component: PatientLogin,
  },
  {
    path: "/patient/signup",
    component: PatientSignup,
  },
  {
    path: "/patient/complete-signup",
    component: CompleteSignup,
  },
  {
    path: "/request-reset-password",
    component: RequestResetPassword,
  },
  {
    path: "/reset-password",
    component: ResetPassword,
  },
];

const RouterRoutes = () => {
  const userLoggedIn = localStorage.getItem("token");
  const [cart] = useCartLazyQuery({
    onCompleted: (res) => {
      localStorage.setItem("cart_id", res.cart.id);
    },
  });

  useEffect(() => {
    if (!localStorage.getItem("cart_id")) {
      if(localStorage.getItem("token"))
        cart();
    }
  }, [localStorage.getItem("cart_id")]);

  if (!userLoggedIn) {
    return (
      <Routes>
        {authPages.map(({ path, component: Component }) => {
          return <Route key={path} path={path} element={<Component />} />;
        })}
        <Route path="*" element={<Navigate to="/patient/login" />} />
      </Routes>
    );
  }
  return (
    <Routes>
      {pages.map(({ path, component: Component }) => {
        return (
          <Route
            key={path}
            path={path}
            element={!userLoggedIn ? <Navigate to="/" /> : <Component />}
          />
        );
      })}
      <Route path="*" element={<Navigate to="/patient/dashboard" />} />
    </Routes>
  );
};

export default RouterRoutes;
