import { Box, Flex } from "@chakra-ui/react";
import { FC, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as CCLogo } from "../images/CannabisClinicLogo1.svg";

interface OnboardingLayoutProps {
  children: ReactNode | ReactNode[];
}

const OnboardingLayout: FC<OnboardingLayoutProps> = ({ children }) => {
  const navigate = useNavigate();

  return (
    <Box backgroundColor="#F6F6F8" minH="100vh">
      <Flex flexDir="column" mx="auto" alignItems="center">
        <Flex
          flexDir="column"
          maxW="8xl"
          w="full"
          textAlign="center"
          p={["5", "12"]}
          rounded="20px"
        >
          <Box mx="auto" mb="10">
            <CCLogo
              width="300"
              height="100"
              onClick={() => navigate("/")}
              cursor="pointer"
            />
          </Box>
          <Box backgroundColor="white" p={["6", "14"]} rounded="20px">
            {children}
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
};

export default OnboardingLayout;
