import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { FC, useState } from "react";
import PatientBasicInfoForm from "../onboarding/PatientBasicInfoForm";
import AcuityAvailabilityForm from "./AcuityAvailabilityForm";

const InitialBookingForm: FC<{ onSubmit: (bookingFormData: any) => void }> = ({
  onSubmit,
}) => {
  const [basicInfo, setBasicInfo] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
  });
  const [selectedBookingTime, setSelectedBookingTime] = useState<
    Date | undefined
  >();
  const [selectedAppointmentTypeId, setSelectedAppointmentTypeId] = useState<
    number | undefined
  >();

  return (
    <Flex flexDir="column" gap="8">
      <Box
        py="20px"
        px="26px"
        rounded="20px"
        border="2px"
        borderColor="cc.greyScale.100"
      >
        <AcuityAvailabilityForm
          updateSelectedBookingTime={setSelectedBookingTime}
          updateSelectedAppointmentTypeId={setSelectedAppointmentTypeId}
        />
      </Box>
      <Box
        py="8"
        px="10"
        rounded="20px"
        border="2px"
        borderColor="cc.greyScale.100"
        textAlign="left"
      >
        <Text as="h5" textAlign="left">
          <Text as="span" color="cc.primary.500" fontWeight="500">
            Step Two
          </Text>
          <Text as="span" fontWeight="600">
            {" "}
            - Personal Details
          </Text>
        </Text>
        {selectedBookingTime && (
          <PatientBasicInfoForm
            basicInfo={basicInfo}
            updateBasicInfo={setBasicInfo}
          />
        )}
      </Box>
      {selectedBookingTime && (
        <Button
          mx="auto"
          w="60"
          variant="primaryDarkOutline"
          onClick={() =>
            onSubmit({
              selectedBookingTime,
              selectedAppointmentTypeId,
              basicInfo,
            })
          }
        >
          Submit
        </Button>
      )}
    </Flex>
  );
};

export default InitialBookingForm;
