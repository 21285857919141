import { Box } from "@chakra-ui/react";
import { FC } from "react";
import WarningBanner from "../WarningBanner";

const UpcomingReleaseWarnings: FC<{
  upcomingReleaseOrders: any;
}> = ({ upcomingReleaseOrders }) => {
  return (
    <Box>
      {upcomingReleaseOrders &&
        upcomingReleaseOrders.map((upcomingReleaseOrder: any) =>
          upcomingReleaseOrder.fulfillmentOrderItems.map((foi: any) => {
            return (
              <WarningBanner
                type="reminder"
                message={`Upcoming ${foi.title} is about to release`}
              />
            );
          })
        )}
    </Box>
  );
};

export default UpcomingReleaseWarnings;
