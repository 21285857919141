import { Text } from "@chakra-ui/react";
import { useState } from "react";
import LoadingAnimation from "../../../components/LoadingAnimation";
import DisplayOrders from "../../../features/patients/orders/DisplayOrders";
import OrderFilterSelect from "../../../features/patients/orders/OrderFilterSelect";
import UpcomingReleaseWarnings from "../../../features/patients/orders/UpcomingReleaseWarnings";
import {
  useFulfillmentOrdersQuery,
  useUpcomingReleasesQuery,
} from "../../../generated/graphql";
import MainLayout from "../../../layouts/MainLayout";

const Orders = () => {
  const { data: upcomingReleasesData } = useUpcomingReleasesQuery();
  const { data, loading } = useFulfillmentOrdersQuery({
    onCompleted: (res) => {
      console.log(res);
    },
  });

  const [ordersCategory, setOrdersCategory] = useState<
    "incomplete" | "complete"
  >("incomplete");

  if (loading)
    return (
      <MainLayout>
        <LoadingAnimation />
      </MainLayout>
    );

  return (
    <MainLayout>
      <Text as="h1" textAlign="left">
        My Orders
      </Text>
      <OrderFilterSelect
        ordersCategory={ordersCategory}
        updateOrdersCategory={setOrdersCategory}
      />
      <UpcomingReleaseWarnings
        upcomingReleaseOrders={upcomingReleasesData?.upcomingReleases}
      />
      <DisplayOrders
        ordersCategory={ordersCategory}
        fulfillmentOrders={data?.fulfillmentOrders ?? []}
      />
    </MainLayout>
  );
};

export default Orders;
