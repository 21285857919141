import { Box, Flex, Image, Spacer, Text } from "@chakra-ui/react";
import { FC, useEffect, useState } from "react";
import {
  MedusaLineItem,
  MedusaLineItemInput,
} from "../../../../../generated/graphql";
import productPlaceholder from "../../../../../images/productPlaceholder.png";
import QuantitySelect from "../../product/ProductDetails/components/QuantitySelect";
import MobileCartItemDetails from "../MobileCartItemDetails";
import RemoveButton from "./components/RemoveButton";

const CartItem: FC<{
  lineItem: MedusaLineItem;
  updateLineItem: (id: string, variant_id: string, quantity: number) => void;
  removeFromCart: (lineItem: MedusaLineItemInput) => void;
}> = ({ lineItem, updateLineItem, removeFromCart }) => {
  const [discountPercentage, setDiscountPercentage] = useState<number>();

  const calculateDiscount = () => {
    const unitPrice = lineItem.unit_price;
    const originalUnitPrice = lineItem.original_total / lineItem.quantity;

    if (originalUnitPrice <= unitPrice) return setDiscountPercentage(undefined);
    setDiscountPercentage(originalUnitPrice / unitPrice);
  };

  const updateQuantity = (value: number) => {
    if (value === lineItem.quantity) return;
    updateLineItem(lineItem.id, lineItem.variant_id, value);
  };

  useEffect(() => {
    calculateDiscount();
  }, [lineItem]);

  return (
    <Flex
      gap={{ base: 3, lg: 7 }}
      textAlign="left"
      fontSize={{ base: "xs", lg: "md", xl: "lg" }}
    >
      <RemoveButton lineItem={lineItem} removeFromCart={removeFromCart} />
      <Flex
        borderWidth="1px"
        borderRadius="xl"
        borderColor="cc.greyScale.100"
        alignItems="center"
        maxH="24"
      >
        <Image maxW="24" maxH="24" src={productPlaceholder} objectFit="cover" />
      </Flex>
      <Flex flexDir="column" w="full" maxW={{ base: "100%", xl: "30%" }}>
        <Text fontWeight="500">
          <Text mt="0.5">
            {lineItem.title} - {lineItem.description}
          </Text>
          <Box
            fontSize={{ base: "xs", lg: "sm" }}
            mt={{ base: 0, lg: 1 }}
            lineHeight="1.7"
            my="1"
          >
            {lineItem?.metadata?.subscribe && (
              <Text
                fontSize={{ base: "xs", lg: "sm" }}
                color="cc.greyScale.200"
              >
                Subscription (Save 10%)
              </Text>
            )}
          </Box>
        </Text>
        <MobileCartItemDetails
          lineItem={lineItem}
          updateQuantity={updateQuantity}
        />
      </Flex>
      <Spacer />
      <Flex
        display={{ base: "none", lg: "flex" }}
        w="80"
        fontSize="md"
        fontWeight="500"
        textAlign="center"
        alignItems="center"
        gap="3"
      >
        <Flex flexDir="column" gap="1">
          {discountPercentage && (
            <Text as={discountPercentage ? "s" : "p"} color="cc.greyScale.200">
              ${((lineItem.unit_price * discountPercentage) / 100).toFixed(2)}
            </Text>
          )}
          <Text>${(lineItem.unit_price / 100).toFixed(2)}</Text>
        </Flex>
        <Spacer />
        <Box w="30">
          <QuantitySelect
            quantity={lineItem.quantity}
            updateQuantity={updateQuantity}
          />
        </Box>
        <Spacer />
        <Text>${(lineItem.subtotal / 100).toFixed(2)}</Text>
      </Flex>
    </Flex>
  );
};

export default CartItem;
