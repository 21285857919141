import { ArrowForwardIcon } from "@chakra-ui/icons";
import { Box, Button, FormLabel, Input, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  ResetPasswordInput,
  useResetPasswordMutation,
} from "../generated/graphql";
import AuthLayout from "../layouts/AuthFlowLayout";

const ResetPassword = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("tkn");
  const [password, setPassword] = useState("");

  const [resetPassword] = useResetPasswordMutation({
    onCompleted: async (data) => {
      localStorage.removeItem("token");
      navigate("/");
    },
    onError: (error) => {
      alert(error);
    },
  });

  const onResetPassword = ({ password, token }: ResetPasswordInput) =>
    resetPassword({
      variables: {
        input: {
          password,
          token,
        },
      },
    });

  useEffect(() => {
    if (!token) navigate("/");
  }, []);

  if (!token) return <Box>Error</Box>;

  return (
    <AuthLayout>
      <Text as="h3"> Reset Password</Text>
      <Text my="5" fontSize="sm" textAlign="left" color="cc.greyScale.300">
        Enter a new password below
      </Text>
      <form>
        <Box>
          <Box>
            <FormLabel>New Password</FormLabel>
            <Input
              type="password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </Box>
          <Button
            my="7"
            variant="primaryLight"
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              onResetPassword({ password, token });
            }}
            rightIcon={<ArrowForwardIcon />}
          >
            Submit
          </Button>
        </Box>
      </form>
    </AuthLayout>
  );
};

export default ResetPassword;
