import {
  Box,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
} from "@chakra-ui/react";
import { Dispatch, FC, SetStateAction } from "react";

export interface PersonalInfo {
  name: string;
  email: string;
  tel: string;
}

const personalFields: {
  label: string;
  value: keyof PersonalInfo;
}[] = [
  {
    label: "Full Name",
    value: "name",
  },
  {
    label: "Email Address",
    value: "email",
  },
  {
    label: "Contact Number",
    value: "tel",
  },
];

const PersonalInfoForm: FC<{
  personalInfo: PersonalInfo;
  updatePersonalInfo: Dispatch<SetStateAction<PersonalInfo | undefined>>;
}> = ({ personalInfo, updatePersonalInfo }) => {
  return (
    <Flex flexDir="column" gap="5">
      <Box>
        <Text as="h5" textAlign="left">
          Personal Details
        </Text>
        <Divider display={{ base: "none", xl: "block" }} my="3" />
      </Box>
      {personalFields.map((personalField, i) => {
        return (
          <FormControl key={i} isRequired>
            <FormLabel>{personalField.label}</FormLabel>
            <Input
              isDisabled={true}
              value={personalInfo[personalField.value]}
              placeholder={`Enter ${personalField.label}`}
              type={personalField.value}
              onChange={(e) =>
                updatePersonalInfo({
                  ...personalInfo,
                  [personalField.value]: e.target.value,
                })
              }
            />
          </FormControl>
        );
      })}
    </Flex>
  );
};

export default PersonalInfoForm;
