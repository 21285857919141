import { ArrowForwardIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";

import { Text } from "@chakra-ui/react";
import { FC, useState } from "react";
import { useAddToNotifyProductListMutation } from "../../../../../../generated/graphql";

const ProductComingSoon: FC<{ productId: string }> = ({ productId }) => {
  const [customerEmail, setCustomerEmail] = useState("");
  const [addToNotifyProduct, { data, loading, error }] =
    useAddToNotifyProductListMutation({
      variables: { input: { productId, customerEmail } },
    });

  return (
    <Box mt="5">
      <Text as="h5">This product is coming soon</Text>
      <Box bgColor="#F6F6F8" my="5" p="7" rounded="2xl">
        <Text fontSize="md" mb="4" fontWeight="600">
          Email me when this product is in stock
        </Text>
        {!data && (
          <InputGroup>
            <Input
              value={customerEmail}
              onChange={(e) => setCustomerEmail(e.target.value)}
              type="email"
              placeholder="Enter Email Address"
            />
            <InputRightElement
              cursor="pointer"
              mx="1"
              onClick={() => {
                if (!customerEmail)
                  return alert("Please enter an email address");
                addToNotifyProduct();
              }}
            >
              <Flex bgColor="cc.primary.500" p="2" mt="5px" rounded="lg">
                <ArrowForwardIcon my="auto" color="white" />
              </Flex>
            </InputRightElement>
          </InputGroup>
        )}
        <Box mt="5">
          {!error && (
            <Text>An unknown error occurred, please contact support!</Text>
          )}
          {!error && !loading && data && (
            <Text>
              Thanks for letting us know, we will be in touch once this product
              is back in stock!
            </Text>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ProductComingSoon;
