import { Maybe } from "graphql/jsutils/Maybe";

// DOESN'T WORK WITH PRODUCT DISCOUNTS CURRENTLY - Similar function on backend for product sort calculation
function useCalculateCost(
  average_dose: Maybe<number> | undefined,
  total_size: Maybe<number> | undefined,
  unit: Maybe<string> | undefined,
  calculated_price: string | undefined | null,
  category: string
) {
  if (!average_dose || !total_size || !calculated_price) return;
  const price = parseInt(calculated_price) / 100;
  const cost = (price / total_size) * average_dose;

  if (category.includes("flower")) return <>${cost.toFixed(2)} per gram</>;
  return (
    <>
      ${cost.toFixed(2)} per day on a {average_dose}
      {unit} dose
    </>
  );
}

export default useCalculateCost;
