import { Dispatch, FC, useEffect } from "react";
import MedicalInformationForm from "./components/MedicalInformationForm";
import PatientInformationForm from "./components/PatientInformationForm";

const PatientIntakeForm: FC<{
  patientIntakeInfo: any;
  updatePatientIntakeInfo: Dispatch<any>;
}> = ({ patientIntakeInfo, updatePatientIntakeInfo }) => {
  useEffect(() => {
    if (!patientIntakeInfo.substanceAbuse)
      updatePatientIntakeInfo({
        ...patientIntakeInfo,
        selectedSubstanceAbuse: undefined,
      });

    if (!patientIntakeInfo.selfMedicationHistory)
      updatePatientIntakeInfo({ ...patientIntakeInfo, gramsPerDay: undefined });
  }, [
    patientIntakeInfo.substanceAbuse,
    patientIntakeInfo.selfMedicationHistory,
  ]);

  return (
    <>
      <PatientInformationForm
        patientIntakeInfo={patientIntakeInfo}
        updatePatientIntakeInfo={updatePatientIntakeInfo}
      />
      <MedicalInformationForm
        patientIntakeInfo={patientIntakeInfo}
        updatePatientIntakeInfo={updatePatientIntakeInfo}
      />
    </>
  );
};

export default PatientIntakeForm;
