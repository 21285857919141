import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import { Button, Flex, GridItem, Spacer, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoadingAnimation from "../../../../../components/LoadingAnimation";
import AddressForms, {
  Address,
} from "../../../../../features/patients/dispensary/checkout/AddressForms";
import CheckoutTotals from "../../../../../features/patients/dispensary/checkout/CheckoutTotals";
import PersonalInfoForm, {
  PersonalInfo,
} from "../../../../../features/patients/dispensary/checkout/PersonalInfoForm";
import {
  useCartQuery,
  useCompleteCheckoutMutation,
  useUserQuery,
} from "../../../../../generated/graphql";
import CartLayout from "../../../../../layouts/CartLayout";

const Checkout = () => {
  const navigate = useNavigate();

  const [personalInfo, setPersonalInfo] = useState<PersonalInfo>();
  const [shippingAddress, setShippingAddress] = useState<Address>();
  const [billingAddress, setBillingAddress] = useState<Address>();

  const [shippingOption, setShippingOption] = useState("standard");
  const [isBillingSeparate, setIsBillingSeparate] = useState(false);

  const { loading } = useUserQuery({
    onCompleted: ({ user }) => {
      setPersonalInfo({
        name: `${user.firstName} ${user.lastName}`,
        email: user.email,
        tel: user.phoneNumber,
      });
      const address = {
        streetAddress: user.patient.streetAddress,
        suburb: user.patient.suburb,
        region: user.patient.region,
        city: user.patient.city,
        country: "New Zealand",
        company: "",
        postCode: user.patient.postCode,
      };
      setShippingAddress(address);
      setBillingAddress(address);
    },
  });

  const {
    data: cartData,
    loading: cartLoading,
    refetch,
  } = useCartQuery({
    fetchPolicy: "no-cache",
    onError: (error) => {
      alert(error.message);
      refetch();
    },
  });

  const [completeCheckout, { loading: completeCheckoutLoading }] =
    useCompleteCheckoutMutation({
      onCompleted: () => {
        navigate("/patient/dispensary/cart/checkout/payment");
      },
      onError: (error) => {
        alert(error);
      },
    });

  const handleCompleteCheckout = () => {
    if (!shippingAddress || !billingAddress)
      return alert("Missing required information");

    completeCheckout({
      variables: {
        input: {
          shippingAddress,
          billingAddress,
        },
      },
    });
  };

  useEffect(() => {
    const step = localStorage.getItem("checkout_step");
    if (step !== "1" && step !== "2") {
      navigate("/patient/dispensary/cart");
    } else {
      localStorage.setItem("checkout_step", "2");
    }
  }, []);

  if (loading || completeCheckoutLoading)
    return (
      <CartLayout>
        <LoadingAnimation />
      </CartLayout>
    );

  if (!shippingAddress || !billingAddress || !personalInfo) {
    navigate("/patient/dispensary/cart");
    return null;
  }

  return (
    <CartLayout isCheckout={true}>
      <GridItem gap="7" colSpan={2} mt="3">
        <Text as="h2" textAlign="left">
          Checkout
        </Text>
        <Flex flexDir="column" gap="10" mt="10">
          <PersonalInfoForm
            personalInfo={personalInfo}
            updatePersonalInfo={setPersonalInfo}
          />
          <AddressForms
            shippingAddress={shippingAddress}
            updateShippingAddress={setShippingAddress}
            shippingOption={shippingOption}
            updateShippingOption={setShippingOption}
            isBillingSeparate={isBillingSeparate}
            updateIsBillingSeparate={setIsBillingSeparate}
            billingAddress={billingAddress}
            updateBillingAddress={setBillingAddress}
          />
        </Flex>
        <Flex gap="3" my="5">
          <Button
            variant="primaryDarkOutline"
            px="5"
            fontSize={{ base: "sm", xl: "md" }}
            onClick={() => navigate("/patient/dispensary/cart")}
          >
            <ArrowBackIcon mr="2" />
            Back to cart
          </Button>
          <Spacer />
          <Button
            variant="primaryDark"
            px="5"
            fontSize={{ base: "sm", xl: "md" }}
            onClick={handleCompleteCheckout}
          >
            Proceed to payment
            <ArrowForwardIcon ml="2" />
          </Button>
        </Flex>
      </GridItem>
      <GridItem
        display="flex"
        flexDir="column"
        gap="5"
        bottom="0"
        left="0"
        right="0"
        pb="14"
      >
        <CheckoutTotals
          total={cartData?.cart.total}
          subtotal={cartData?.cart.subtotal}
          couponCode={
            cartData?.cart.discounts.find((d) => d.code !== "FREESHIPPING")
              ?.code
          }
        />
      </GridItem>
    </CartLayout>
  );
};

export default Checkout;
