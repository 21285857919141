import { FC } from "react";
import CheckoutTotalsContainer from "./components/CheckoutTotalsContainer";
import CheckoutTotalsContent from "./components/CheckoutTotalsContent";

const CheckoutTotals: FC<{
  total: number | undefined;
  subtotal: number | undefined;
  couponCode: string | undefined;
}> = ({ total, subtotal, couponCode }) => {
  return (
    <CheckoutTotalsContainer total={total}>
      <CheckoutTotalsContent
        total={total}
        subtotal={subtotal}
        couponCode={couponCode}
      />
    </CheckoutTotalsContainer>
  );
};

export default CheckoutTotals;
