import { Box, Flex, Image, useDisclosure } from "@chakra-ui/react";
import { useState } from "react";
import { ReactComponent as ImageExpandIcon } from "../../../../../../images/imageExpand.svg";
import { ReactComponent as LeftChevron } from "../../../../../../images/leftChevron.svg";
import productPlaceholder from "../../../../../../images/productPlaceholder.png";
import { ReactComponent as RightChevron } from "../../../../../../images/rightChevron.svg";
import ImageModal from "./ImageModal";

const ProductImage = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const images = [productPlaceholder, productPlaceholder, productPlaceholder];
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  return (
    <Box maxW={{ base: "100%", lg: "50%", xl: "70%" }} mx="auto">
      <Flex position="relative">
        {selectedImageIndex > 0 && (
          <Box
            onClick={() => {
              if (selectedImageIndex <= images.length)
                setSelectedImageIndex(selectedImageIndex - 1);
            }}
            cursor="pointer"
            position="absolute"
            color="cc.greyScale.200"
            my="auto"
            top="50%"
            left="0"
          >
            <LeftChevron height="40px" width="40px" />
          </Box>
        )}

        <Image
          src={images[selectedImageIndex]}
          w="full"
          cursor="pointer"
          onClick={onOpen}
        />

        {selectedImageIndex < images.length - 1 && (
          <Box
            onClick={() => {
              if (selectedImageIndex < images.length)
                setSelectedImageIndex(selectedImageIndex + 1);
            }}
            cursor="pointer"
            position="absolute"
            color="cc.greyScale.200"
            my="auto"
            top="50%"
            right="0"
          >
            <RightChevron height="40px" width="40px" />
          </Box>
        )}
      </Flex>
      <ImageExpandIcon cursor="pointer" onClick={onOpen} />
      <ImageModal
        isOpen={isOpen}
        onClose={onClose}
        imageSrc={productPlaceholder}
      />
    </Box>
  );
};

export default ProductImage;
