import { defineStyle, defineStyleConfig, extendTheme } from "@chakra-ui/react";

const primaryLight = defineStyle({
  backgroundColor: "cc.primary.100",
  color: "cc.primary.500",
  rounded: "15px",
  borderColor: "#79CEDF",
  borderWidth: "2px",
  _disabled: {
    borderColor: "cc.greyScale.100",
    backgroundColor: "cc.greyScale.100",
    color: "cc.greyScale.200",
  },
  _hover: {
    backgroundColor: "#79CEDF",
  },
  // _focus: {
  //   borderWidth: "6px",
  //   borderColor: "#ACF1FF",
  //   backgroundColor: "#79CEDF",
  // },
  paddingX: "35px",
  paddingY: "25px",
  fontSize: "16px",
});

const primaryLightOutline = defineStyle({
  color: "#79CEDF",
  rounded: "15px",
  borderColor: "#79CEDF",
  borderWidth: "2px",
  _disabled: {
    backgroundColor: "cc.greyScale.100",
    color: "cc.greyScale.200",
  },
  _hover: {
    borderColor: "cc.greyScale.100",
    backgroundColor: "#79CEDF",
    color: "cc.primary.500",
  },
  // _focus: {
  //   borderWidth: "6px",
  //   borderColor: "#ACF1FF",
  //   backgroundColor: "#79CEDF",
  //   color: "cc.primary.500",
  // },
  paddingX: "35px",
  paddingY: "25px",
  fontSize: "16px",
});

const primaryDark = defineStyle({
  backgroundColor: "cc.primary.500",
  color: "white",
  rounded: "15px",
  borderColor: "#367ABF",
  borderWidth: "2px",
  _disabled: {
    borderColor: "cc.greyScale.100",
    backgroundColor: "cc.greyScale.100",
    color: "cc.greyScale.200",
  },
  _hover: {
    backgroundColor: "#367ABF",
    color: "white",
  },
  // _focus: {
  //   borderWidth: "6px",
  //   borderColor: "#6DB1F5",
  //   color: "white",
  // },
  paddingX: "35px",
  paddingY: "25px",
  fontSize: "16px",
});

const primaryDarkOutline = defineStyle({
  color: "#367ABF",
  rounded: "15px",
  borderColor: "#367ABF",
  borderWidth: "2px",
  _disabled: {
    borderColor: "cc.greyScale.100",
    backgroundColor: "cc.greyScale.100",
    color: "cc.greyScale.200",
  },
  _hover: {
    backgroundColor: "#367ABF",
    color: "white",
  },
  // _focus: {
  //   backgroundColor: "#367ABF",
  //   borderWidth: "6px",
  //   borderColor: "#6DB1F5",
  //   color: "white",
  // },
  paddingX: "35px",
  paddingY: "25px",
  fontSize: "16px",
});

const buttonTheme = defineStyleConfig({
  variants: {
    primaryLight,
    primaryLightOutline,
    primaryDark,
    primaryDarkOutline,
  },
});

const theme = extendTheme({
  components: { Button: buttonTheme },
  colors: {
    cc: {
      primary: {
        500: "#5197DE",
        300: "#8AB8E7",
        100: "#8DDAE9",
      },
      greyScale: {
        500: "#24242B",
        400: "#30313F",
        300: "#656674",
        200: "#A2A2AD",
        100: "#DBDBE1",
      },
      lightScale: {
        500: "#E5E5EB",
        400: "#EBEBF0",
        300: "#F2F2F5",
        200: "#FAFAFC",
        100: "#FFFFFF",
      },
      red: "#EE4E4E",
      green: "#ACD667",
    },
  },
  styles: {
    global: () => ({
      body: {
        fontFamily: "Montserrat",
        fontSize: "16px",
        lineHeight: "22px",
      },
    }),
  },
});

export default theme;
