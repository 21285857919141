import { ApolloQueryResult } from "@apollo/client";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Spacer,
  Text,
} from "@chakra-ui/react";
import { FC, useEffect, useState } from "react";
import {
  Exact,
  UserQuery,
  useUpdateAddressMutation,
} from "../../../generated/graphql";

const addressFields: {
  label: string;
  value: "streetAddress" | "suburb" | "region" | "city" | "postCode";
}[] = [
  {
    label: "Street Address",
    value: "streetAddress",
  },
  {
    label: "Suburb",
    value: "suburb",
  },
  {
    label: "Region",
    value: "region",
  },
  {
    label: "City",
    value: "city",
  },
  {
    label: "Post Code",
    value: "postCode",
  },
];

const ShippingDetails: FC<{
  user: any;
  refetch: (
    variables?:
      | Partial<
          Exact<{
            [key: string]: never;
          }>
        >
      | undefined
  ) => Promise<ApolloQueryResult<UserQuery>>;
}> = ({ user, refetch }) => {
  const [shippingAddress, setShippingAddress] = useState({
    streetAddress: "",
    suburb: "",
    region: "",
    city: "",
    postCode: "",
  });

  const refetchShippingAddress = async () => {
    await refetch();
    if (!user) return;
    setShippingAddress({
      streetAddress: user.patient.streetAddress,
      suburb: user.patient.suburb,
      region: user.patient.region,
      city: user.patient.city,
      postCode: user.patient.postCode,
    });
  };

  useEffect(() => {
    refetchShippingAddress();
  }, [user]);

  const [updateAddress, { loading: updateAddressLoading }] =
    useUpdateAddressMutation({
      onError: () => {
        alert("There was an error updating your address");
      },
    });

  const handleUpdateAddress = () => {
    if (
      !shippingAddress.streetAddress.length ||
      !shippingAddress.suburb.length ||
      !shippingAddress.region.length ||
      !shippingAddress.city.length ||
      !shippingAddress.postCode.length
    )
      return alert("All address fields are required!");

    updateAddress({
      variables: {
        input: shippingAddress,
      },
    });
  };

  return (
    <Box
      w="full"
      borderWidth="1px"
      p={{ base: 4, lg: 7 }}
      rounded="2xl"
      bgColor="white"
    >
      <Text as="h5" my="3" mb="5">
        Shipping
      </Text>
      <Grid templateColumns="repeat(2, 1fr)" gap="5" my="8">
        {addressFields.map((addressField, i) => {
          return (
            <GridItem
              key={i}
              colSpan={{
                base: 2,
                xl:
                  i === addressFields.length - 1 ||
                  i === addressFields.length - 2
                    ? 1
                    : 2,
              }}
            >
              <FormControl isRequired>
                <FormLabel fontSize={{ base: "sm", xl: "md" }}>
                  {addressField.label}
                </FormLabel>
                <Input
                  value={shippingAddress[addressField.value]}
                  placeholder={`Enter ${addressField.label}`}
                  type={addressField.value}
                  onChange={(e) => {
                    setShippingAddress({
                      ...shippingAddress,
                      [addressField.value]: e.target.value,
                    });
                  }}
                />
              </FormControl>
            </GridItem>
          );
        })}
      </Grid>
      {!updateAddressLoading && (
        <Flex gap="3">
          <Button
            variant="primaryDarkOutline"
            onClick={() => refetchShippingAddress()}
            fontSize="sm"
          >
            <Text>
              Discard
              <Text display={{ base: "none", lg: "initial" }} as="span">
                {" "}
                Changes
              </Text>
            </Text>
          </Button>
          <Spacer />
          <Button
            variant="primaryDark"
            onClick={() => handleUpdateAddress()}
            fontSize="sm"
          >
            <Text>
              Update
              <Text display={{ base: "none", lg: "initial" }} as="span">
                {" "}
                Changes
              </Text>
            </Text>
          </Button>
        </Flex>
      )}
    </Box>
  );
};

export default ShippingDetails;
