import { SimpleGrid } from "@chakra-ui/react";

import { FC, ReactNode } from "react";
import MainLayout from "./MainLayout";

const CartLayout: FC<{
  isCheckout?: boolean;
  children: ReactNode | ReactNode[];
}> = ({ isCheckout, children }) => {
  return (
    <MainLayout>
      <SimpleGrid
        columns={{ base: 1, xl: 3 }}
        gap={{ base: 0, xl: 14 }}
        pb="20"
      >
        {children}
      </SimpleGrid>
    </MainLayout>
  );
};

export default CartLayout;
