import { Box } from "@chakra-ui/react";

import { Button, Flex, Text } from "@chakra-ui/react";

import {
  ArrowBackIcon,
  ArrowForwardIcon,
  ChevronLeftIcon,
} from "@chakra-ui/icons";
import { FC, useEffect, useState } from "react";
import "swiper/css";
import LoadingAnimation from "../../../../../components/LoadingAnimation";
import AcuityAvailableTimes from "./AcuityAvailableTimes";

const SelectAppointmentTime: FC<{
  availableDays: any;
  isAvailableDaysLoading: boolean;
  selectedCategory: any;
  selectedAppointmentType: any;
  selectedCalendar: any;
  selectedTime: any;
  updateSelectedTime: any;
}> = ({
  availableDays,
  isAvailableDaysLoading,
  selectedCategory,
  selectedAppointmentType,
  selectedCalendar,
  selectedTime,
  updateSelectedTime,
}) => {
  const [calenderPageSize, setCalenderPageSize] = useState(2);
  const [calendarPage, setCalendarPage] = useState(1);
  const [totalCalendarPages, setTotalCalendarPages] = useState(0);

  useEffect(() => {
    if (availableDays && availableDays.length)
      setTotalCalendarPages(Math.ceil(availableDays.length / 5));
  }, [availableDays]);

  if (!selectedAppointmentType || !selectedCategory || !selectedCalendar)
    return null;

  if (isAvailableDaysLoading) return <LoadingAnimation />;

  return (
    <Box>
      <Flex flexDir="column">
        {selectedTime ? (
          <Flex flexDir="column" gap="8" mt="5">
            <Flex
              mt="7"
              cursor="pointer"
              py="8"
              px="10"
              rounded="20px"
              border="2px"
              borderColor="cc.greyScale.100"
              textAlign="left"
            >
              <Box my="auto">
                <Text as="h5" fontSize="md" fontWeight="600">
                  {selectedTime.toLocaleString("en-nz", {
                    weekday: "long",
                  })}{" "}
                  {selectedTime.toLocaleString("en-nz", {
                    month: "long",
                  })}{" "}
                  {selectedTime.getDate()}
                  {" - "}
                  {selectedTime
                    .toTimeString()
                    .split(" ")[0]
                    .substring(
                      0,
                      selectedTime.toTimeString().split(" ")[0].length - 3
                    )}
                </Text>
              </Box>
            </Flex>
            <Button
              mr="auto"
              variant="primaryLightOutline"
              cursor="pointer"
              onClick={() => {
                updateSelectedTime(undefined);
              }}
            >
              <ChevronLeftIcon boxSize="6" />
              View Times
            </Button>
          </Flex>
        ) : !availableDays || !availableDays.length ? (
          <Text>No Times Available</Text>
        ) : (
          <Flex
            w="100%"
            textAlign="center"
            py="5"
            rounded="20px"
            overflowX="auto"
          >
            {availableDays.map((availableDate: any, i: number) => {
              const date = new Date(availableDate.date);

              if (
                i > calendarPage * calenderPageSize - calenderPageSize &&
                i < calendarPage * calenderPageSize
              )
                return (
                  <Flex key={i} flexDir="column" w="full">
                    <Flex
                      mb="2"
                      backgroundColor="#F4FBFC"
                      minW="40"
                      py="4"
                      justifyContent="center"
                      alignItems="center"
                      px="10"
                    >
                      {calendarPage > 1 && !selectedTime && (
                        <Box
                          onClick={() => {
                            setCalendarPage(calendarPage - 1);
                          }}
                        >
                          <ArrowBackIcon boxSize="6" />
                        </Box>
                      )}
                      <Box mx="auto">
                        <Text fontWeight="600">
                          {date.toLocaleString("en-nz", {
                            weekday: "long",
                          })}
                        </Text>
                        <Text
                          my="1"
                          color="cc.greyScale.200"
                        >{`${date.toLocaleString("en-nz", {
                          month: "long",
                        })} ${date.getDate()}`}</Text>
                      </Box>
                      {calendarPage < totalCalendarPages && !selectedTime && (
                        <Box
                          onClick={() => {
                            setCalendarPage(calendarPage + 1);
                          }}
                        >
                          <ArrowForwardIcon boxSize="6" />
                        </Box>
                      )}
                    </Flex>
                    <Flex gap="5" flexDir="column" py="5">
                      <AcuityAvailableTimes
                        availableTimes={availableDate.times}
                        timeRange="morning"
                        updateSelectedTime={updateSelectedTime}
                      />
                      <AcuityAvailableTimes
                        availableTimes={availableDate.times}
                        timeRange="afternoon"
                        updateSelectedTime={updateSelectedTime}
                      />
                    </Flex>
                  </Flex>
                );
            })}
          </Flex>
        )}
      </Flex>
    </Box>
  );
};

export default SelectAppointmentTime;
