import { Box, Flex, useDisclosure } from "@chakra-ui/react";
import { FC, useState } from "react";

import {
  useIsUnreadNotificationsQuery,
  useMarkAllNotificationsReadMutation,
} from "../../generated/graphql";
import { ReactComponent as NotificationIcon } from "../../images/notifications.svg";
import NotificationModal from "./NotificationModal";

// Not using real time events as notifications currently only being used for appointments (not time sensitive to the second)
const Notifications: FC<{ iconColor: string }> = ({ iconColor }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isNotificationsUnread, setIsNotificationsUnread] = useState(false);

  const [markAsRead] = useMarkAllNotificationsReadMutation();
  useIsUnreadNotificationsQuery({
    pollInterval: 300000,
    onCompleted: (res) => {
      setIsNotificationsUnread(res.isUnreadNotifications);
    },
  });

  const handleMarkAsRead = () => {
    markAsRead({
      update(cache) {
        cache.evict({ id: "ROOT_QUERY", fieldName: "getNotifications" });
        cache.evict({ id: "ROOT_QUERY", fieldName: "isNotificationsUnread" });
      },
    });
  };

  return (
    <>
      <NotificationModal isOpen={isOpen} onClose={onClose} />
      <Box
        onClick={() => {
          onOpen();
          handleMarkAsRead();
          setIsNotificationsUnread(false);
        }}
        cursor="pointer"
        fontWeight="500"
      >
        <Flex>
          <NotificationIcon color={iconColor} width="18" />
          <Box display="absolute">
            <Box
              top="0"
              position="relative"
              backgroundColor="cc.primary.500"
              w="1.5"
              h="1.5"
              rounded="full"
              display={isNotificationsUnread ? "block" : "none"}
            />
          </Box>
        </Flex>
      </Box>
    </>
  );
};

export default Notifications;
