import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { Box, Collapse, Flex, Text, useDisclosure } from "@chakra-ui/react";
import { FC, useEffect } from "react";
import FilterOption from "../dispensary/FilterOption";

const DispensaryFilter: FC<{
  type: "categories" | "brand" | "type";
  isAllFiltersClosed: boolean;
  updateIsAllFiltersClosed: React.Dispatch<React.SetStateAction<boolean>>;
  filterOptions: any;
  selectedFilterOption: any;
  updateSelectedFilterOption: React.Dispatch<React.SetStateAction<any>>;
}> = ({
  type,
  isAllFiltersClosed,
  updateIsAllFiltersClosed,
  filterOptions,
  selectedFilterOption,
  updateSelectedFilterOption,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  // If the filters are set to closed in parent, then close
  useEffect(() => {
    if (isAllFiltersClosed) onClose();
  }, [isAllFiltersClosed]);

  // Unselect the filter option if the filter is closed
  useEffect(() => {
    if (!isOpen) updateSelectedFilterOption(undefined);
    updateIsAllFiltersClosed(false);
  }, [isOpen]);

  return (
    <Box my="4">
      <Flex
        w="full"
        alignItems="center"
        color={isOpen ? "cc.primary.500" : "black"}
        fontWeight={isOpen ? "600" : "400"}
        cursor="pointer"
        onClick={() => (isOpen ? onClose() : onOpen())}
        pb="2"
        borderBottom="1px"
        borderColor="cc.greyScale.100"
      >
        <Text>
          {type.charAt(0).toUpperCase() + type.substring(1, type.length)}
        </Text>
        {isOpen ? <ChevronUpIcon ml="auto" /> : <ChevronDownIcon ml="auto" />}
      </Flex>
      <Collapse in={isOpen}>
        <Box ml="5" py="4">
          {filterOptions?.map((filterOption: any, i: number) => {
            if (!filterOption.parent_category)
              return (
                <FilterOption
                  key={i}
                  selectedFilterOption={selectedFilterOption}
                  filterOption={filterOption}
                  filterOptionChildren={
                    filterOption.category_children &&
                    filterOption.category_children.length
                      ? filterOption.category_children
                      : undefined
                  }
                  updateSelectedFilterOption={updateSelectedFilterOption}
                />
              );
          })}
        </Box>
      </Collapse>
    </Box>
  );
};

export default DispensaryFilter;
