import { ArrowForwardIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, FormLabel, Input, Text } from "@chakra-ui/react";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoadingAnimation from "../../components/LoadingAnimation";
import { useLoginMutation } from "../../generated/graphql";

interface LoginFormFields {
  email: string;
  password: string;
}

const LoginForm: FC<{ type: "patient" | "doctor" | "staff" }> = ({ type }) => {
  const navigate = useNavigate();
  const [loginInfo, setLoginInfo] = useState<LoginFormFields>({
    email: "",
    password: "",
  });

  const [login, { loading }] = useLoginMutation({
    onCompleted: async (data: any) => {
      localStorage.setItem("token", data.login.token);
      window.location.href = `/${type}/dashboard`;
    },
    onError: (error: any) => {
      alert(
        "There was an error logging in - please check your Email/Password is correct"
      );
    },
  });

  const onLogin = async () =>
    login({
      variables: {
        input: loginInfo,
      },
    });

  if (loading) return <LoadingAnimation />;

  return (
    <Flex flexDir="column">
      <Text as="h2">
        {type.charAt(0).toUpperCase() + type.slice(1, type.length)} Login
      </Text>

      <Flex mt="10" flexDir="column" gap="7">
        {["email", "password"].map((loginField, i) => {
          return (
            <Box key={i}>
              <FormLabel>
                {loginField.charAt(0).toUpperCase() +
                  loginField.slice(1, loginField.length)}
              </FormLabel>
              <Input
                type={loginField}
                onChange={(e) =>
                  setLoginInfo({ ...loginInfo, [loginField]: e.target.value })
                }
              />
            </Box>
          );
        })}
      </Flex>
      <Text
        mt="3"
        textAlign="right"
        fontSize="sm"
        onClick={() => navigate("/request-reset-password")}
        cursor="pointer"
        color="cc.greyScale.300"
      >
        Forgot Password?
      </Text>
      <Button
        mt="7"
        mb="5"
        mx="auto"
        variant="primaryDark"
        type="submit"
        isDisabled={!loginInfo.email || !loginInfo.password}
        onClick={(e) => {
          e.preventDefault();
          onLogin();
        }}
        rightIcon={<ArrowForwardIcon />}
      >
        Login
      </Button>
      {type === "patient" && (
        <Text
          textAlign="center"
          fontSize="sm"
          onClick={() => navigate(`/${type}/signup`)}
          cursor="pointer"
          color="cc.greyScale.200"
        >
          Don't already have an account?{" "}
          <Text
            as="span"
            fontWeight="600"
            textDecoration="underline"
            color="cc.primary.500"
          >
            Sign Up
          </Text>
        </Text>
      )}
    </Flex>
  );
};

export default LoginForm;
