import { Box, Flex } from "@chakra-ui/react";
import { FC, ReactNode } from "react";

interface AuthLayoutProps {
  children: ReactNode | ReactNode[];
}

const AuthFlowLayout: FC<AuthLayoutProps> = ({ children }) => {
  return (
    <Box>
      <Flex
        mt="20"
        flexDir="column"
        mx="auto"
        justifyContent="center"
        alignItems="center"
      >
        <Box maxW="md" w="full" textAlign="center" px="5">
          {children}
        </Box>
      </Flex>
    </Box>
  );
};

export default AuthFlowLayout;
