import { Box, Button, Flex, Text, useDisclosure } from "@chakra-ui/react";
import { FC, ReactNode } from "react";
import LoadingAnimation from "../../../../../../components/LoadingAnimation";
import {
  AddToCartInput,
  MedusaProductVariant,
  useAddToCartMutation,
} from "../../../../../../generated/graphql";
import useGetTotalStockQuantity from "../../../../../../hooks/patient/dispensary/useGetTotalStockQuantity";
import AddToCartPopup from "../../../cart/AddToCartPopup";

const ProductPreviewVariantsBanner: FC<{
  variants: MedusaProductVariant[];
  isVisible: boolean;
  updateIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  children: ReactNode | ReactNode[];
}> = ({ variants, isVisible, updateIsVisible, children }) => {
  const totalStockQuantity = useGetTotalStockQuantity(variants);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [addToCart, { loading }] = useAddToCartMutation({
    onCompleted: () => {
      onOpen();
    },
    onError: (error: any) => {
      return alert("Error adding product to cart - please contact support");
    },
  });

  const onAddToCart = ({ lineItems }: AddToCartInput) =>
    addToCart({
      variables: {
        input: {
          lineItems,
        },
      },
    });

  if (variants.length <= 1 || totalStockQuantity === 0) return <>{children}</>;

  if (loading)
    return (
      <>
        <Box
          position="absolute"
          left="0"
          right="0"
          zIndex="20"
          top="0"
          bottom="0"
          bgColor="#FFFFFFCC"
          pt="20"
        >
          <LoadingAnimation />
        </Box>
        {children}
      </>
    );

  return (
    <Box
      zIndex="20"
      position="relative"
      onMouseEnter={() => updateIsVisible(true)}
      onMouseLeave={() => updateIsVisible(false)}
    >
      <Box
        position="absolute"
        zIndex="20"
        bottom="5"
        left="0"
        right="0"
        bgColor="#F6F6F8CC"
        textAlign="center"
        rounded="2xl"
        m={{ base: "3", xl: "5" }}
        p="1"
      >
        {!isVisible && (
          <Text
            my="2"
            fontWeight="600"
            onClick={() => updateIsVisible(true)}
            zIndex="20"
          >
            Select strength
          </Text>
        )}
        {isVisible && (
          <>
            <Text
              display={{ base: "none", xl: "block" }}
              mt="1"
              mb="2"
              fontWeight="600"
            >
              Select strength
            </Text>
            <Flex
              flexDir={{ base: "column", xl: "row" }}
              gap="2"
              my="2"
              mx="2"
              justifyContent="center"
            >
              {variants.map((variant, i) => {
                return (
                  <Button
                    key={i}
                    variant="primaryDarkOutline"
                    py="1"
                    px="5"
                    fontSize="xs"
                    onClick={() => {
                      updateIsVisible(false);
                      onAddToCart({
                        lineItems: [{ id: variant.id, quantity: 1 }],
                      });
                    }}
                  >
                    {variant.title}
                  </Button>
                );
              })}
            </Flex>
          </>
        )}
      </Box>
      {children}
      <AddToCartPopup isOpen={isOpen} onClose={onClose} />
    </Box>
  );
};

export default ProductPreviewVariantsBanner;
