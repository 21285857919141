import { Box, Divider, Text } from "@chakra-ui/react";
import { FC } from "react";
import { MedusaProduct } from "../../../../generated/graphql";
import ProductSlider from "./ProductSlider";

const ProductSuggestions: FC<{
  type: "recommended" | "alternative";
  slideLimit?: number | undefined;
  products: MedusaProduct[];
}> = ({ type, slideLimit, products }) => {
  if (!products.length) return null;

  return (
    <Box my="10">
      <Text as="h4">
        {type === "recommended"
          ? "Recommended for you"
          : "Suitable Alternatives"}
      </Text>
      <ProductSlider
        products={products}
        slideLimit={slideLimit}
        showButtons={false}
      />
      <Divider />
    </Box>
  );
};

export default ProductSuggestions;
