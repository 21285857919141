import { Box, Image, Text } from "@chakra-ui/react";
import DispensarySplash from "../../../images/DispensarySplash.png";

const DispensaryHeader = () => {
  return (
    <Box display="flex" flexDir={{ base: "column", md: "row" }} gap="5">
      <Box textAlign="left" maxW={{ base: "100%", xl: "50%" }}>
        <Text as="h1" mb="8" lineHeight="1.2">
          The Dispensary
        </Text>
        <Text fontSize={{ base: "md", xl: "lg" }} my="5" color="cc.primary.500">
          Our clinical team reviews each order placed via the dispensary
        </Text>
        <Text fontSize={{ base: "sm", xl: "md" }}>
          From time to time, our doctors will require a follow-up consultation
          to catch-up on your progress. All medicinal cannabis products are
          ordered on your behalf and delivered with your prescribed dosing
          instructions. If you can’t see the products below, please enter the
          dispensary access code to request and purchase your{" "}
          <Text as="span" color="cc.primary.500" fontWeight="600">
            prescribed medication
          </Text>
          :
        </Text>
      </Box>
      <Box display="flex" flexDirection="column">
        <Box
          maxW={{ base: "80%", xl: "60%" }}
          ml="auto"
          mr={{ base: "auto", xl: "auto" }}
        >
          <Image src={DispensarySplash} ml="auto" />
        </Box>
      </Box>
    </Box>
  );
};

export default DispensaryHeader;
