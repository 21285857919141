import { ArrowForwardIcon } from "@chakra-ui/icons";
import { Button, Flex, Grid, Spacer, Text } from "@chakra-ui/react";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import {
  Prescription,
  ScriptCartItem,
  useScriptCartItemsQuery,
  useUpdateLineItemMutation,
} from "../../../../generated/graphql";
import { ReactComponent as ScriptIllustration } from "../../../../images/script-illustration.svg";
import MainLayout from "../../../../layouts/MainLayout";
import ReviewScriptCartItem from "./components/ReviewScriptCartItem";

const ReviewScriptSplashPage: FC<{
  prescriptionToOrder: Prescription;
}> = ({ prescriptionToOrder }) => {
  const navigate = useNavigate();

  const { data, refetch } = useScriptCartItemsQuery({
    variables: {
      input: {
        variantIds: prescriptionToOrder.prescriptionItems.map(
          (pi: any) => pi.productVariantId
        ),
      },
    },
  });

  const [updateLineItem] = useUpdateLineItemMutation({
    onCompleted: () => {
      refetch();
    },
    onError: (error) => {
      console.log(error);
      alert(error);
    },
  });

  const handleUpdateScriptCartItem = (
    id: string,
    variant_id: string,
    quantity: number,
    subscribe: boolean
  ) => {
    updateLineItem({
      variables: {
        input: {
          lineItem: {
            id,
            variant_id,
            quantity,
            subscribe,
          },
        },
      },
    });
  };

  return (
    <MainLayout>
      <ScriptIllustration
        style={{
          maxHeight: "12vw",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: -40,
        }}
      />
      <Text as="h1" textAlign="center" mt="4">
        Review your script.
      </Text>
      <Grid
        my="20"
        w="full"
        templateColumns={{ base: "repeat(1, 1fr)", xl: "repeat(3, 1fr)" }}
        gap={{ base: "5", xl: "10" }}
        flexWrap="wrap"
      >
        {data?.scriptCartItems?.map(
          (scriptCartItem: ScriptCartItem, i: number) => {
            return (
              <ReviewScriptCartItem
                key={i}
                scriptCartItem={scriptCartItem}
                updateScriptCartItem={handleUpdateScriptCartItem}
              />
            );
          }
        )}
      </Grid>
      <Flex>
        <Spacer />
        <Button
          variant="primaryDark"
          onClick={() => navigate("/patient/dispensary/cart")}
        >
          Checkout <ArrowForwardIcon ml="2" />
        </Button>
      </Flex>
    </MainLayout>
  );
};

export default ReviewScriptSplashPage;
