import { FetchResult } from "@apollo/client";
import { Box, Button, Text } from "@chakra-ui/react";
import { FC } from "react";

import {
  CreateAcuityAppointmentInput,
  CreateAcuityAppointmentMutation,
} from "../../../generated/graphql";
import AcuityAvailabilityForm from "./AcuityAvailabilityForm";

const BookAppointment: FC<{
  selectedBookingTime: Date | undefined;
  selectedAppointmentTypeId: number | undefined;
  updateSelectedAppointmentTypeId: (appointmentId: number | undefined) => void;
  updateSelectedBookingTime: (bookingId: Date | undefined) => void;
  onSubmit: ({
    selectedBookingTime,
    selectedAppointmentTypeId,
  }: CreateAcuityAppointmentInput) => Promise<
    FetchResult<CreateAcuityAppointmentMutation>
  >;
}> = ({
  selectedBookingTime,
  selectedAppointmentTypeId,
  updateSelectedAppointmentTypeId,
  updateSelectedBookingTime,
  onSubmit,
}) => {
  return (
    <Box
      w={{ base: "100%", lg: "70%" }}
      backgroundColor="white"
      p={{ base: 5, xl: 0 }}
      rounded="3xl"
    >
      <Text mb="4" as="h5" fontWeight="500" textAlign="left">
        Book an Appointment
      </Text>
      <Box
        h="50vh"
        overflowY="auto"
        px={{ base: 5, xl: 10 }}
        py="5"
        borderWidth="1px"
        rounded="20px"
      >
        <AcuityAvailabilityForm
          updateSelectedAppointmentTypeId={updateSelectedAppointmentTypeId}
          updateSelectedBookingTime={updateSelectedBookingTime}
        />
        {selectedBookingTime && selectedAppointmentTypeId && (
          <Button
            mx="auto"
            mb="5"
            variant="primaryDark"
            onClick={() =>
              onSubmit({
                selectedBookingTime,
                selectedAppointmentTypeId,
              })
            }
          >
            Submit
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default BookAppointment;
