import { Divider, Flex, Text } from "@chakra-ui/react";
import { FC, Fragment, useEffect, useState } from "react";
import {
  MedusaLineItem,
  useCartQuery,
} from "../../../../../../generated/graphql";
import CheckoutProduct from "./CheckoutProduct";

const CheckoutTotalsContent: FC<{
  total: number | undefined;
  subtotal: number | undefined;
  couponCode: string | undefined;
}> = ({ total, subtotal, couponCode }) => {
  const [lineItems, setLineItems] = useState<MedusaLineItem[]>();
  const { data: cartData, refetch } = useCartQuery();

  const prices = [
    {
      label: "Subtotal",
      value: subtotal ?? 0,
    },
    {
      label: `Coupon Code`,
    },
    {
      label: "Total",
      value: total ?? 0,
    },
  ];

  // If the quantity of an item has changed AND the unit price hasn't changed
  // then refetch the data (as discount override on Medusa backend runs after fetching the cart)
  useEffect(() => {
    if (!cartData?.cart.items) return;
    cartData.cart.items.map((item) => {
      const existingItem = lineItems?.find((i) => i.id === item.id);
      if (
        item.quantity !== existingItem?.quantity &&
        item.unit_price === existingItem?.unit_price
      ) {
        return refetch();
      }
    });
    setLineItems(cartData.cart.items);
  }, [cartData?.cart.items]);

  return (
    <Flex
      flexDir="column"
      gap="4"
      bgColor="#F4FBFC"
      p="6"
      py="8"
      textAlign="left"
      rounded="2xl"
    >
      <Text as="h6" fontWeight="500">
        Cart Totals
      </Text>
      <Divider borderWidth="1px" />

      {lineItems?.map((lineItem, i) => {
        return (
          <Fragment key={i}>
            <CheckoutProduct key={i} lineItem={lineItem} />{" "}
            <Divider borderWidth="1px" />
          </Fragment>
        );
      })}

      {prices.map((price, i) => {
        if (price.label === "Coupon Code" && !couponCode) return null;
        return (
          <Fragment key={i}>
            <Flex fontSize="md">
              <Text color="cc.greyScale.200">
                {price.label}{" "}
                {price.label === "Coupon Code" && `- ${couponCode}`}
              </Text>
              <Text ml="auto" fontWeight="600">
                {price.label === "Coupon Code"
                  ? `-$${(((subtotal ?? 0) - (total ?? 0)) / 100).toFixed(2)}`
                  : `$${((price.value ?? 0) / 100).toFixed(2)}`}
                {price.label === "Total" && (
                  <Text as="span" fontWeight="400" fontSize="sm">
                    {" "}
                    (includes{" "}
                    <Text as="span" fontWeight="600">
                      ${((price.value ?? 0) / 11 / 100).toFixed(2)}{" "}
                    </Text>
                    Tax)
                  </Text>
                )}
              </Text>
            </Flex>
            <Divider borderWidth="1px" />
          </Fragment>
        );
      })}
    </Flex>
  );
};

export default CheckoutTotalsContent;
