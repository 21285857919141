import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { FC } from "react";
import DashboardSplash from "../../../images/dashboard-splash.png";

const PatientGreeting: FC<{ patientName: string }> = ({ patientName }) => {
  return (
    <Flex direction={{ base: "row", xl: "column" }} textAlign="left">
      <Box>
        <Text as="h1">
          Hello,{" "}
          <Text as="span" fontWeight="semibold">
            {patientName}
          </Text>
        </Text>
        <Text as="h3" my="3" color="cc.primary.300">
          How can we help you <br />
          today?
        </Text>
      </Box>
      <Image src={DashboardSplash} maxW="15vw" maxH="15vw" mx="5" ml="auto" />
    </Flex>
  );
};

export default PatientGreeting;
