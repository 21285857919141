import { Box } from "@chakra-ui/react";
import { FC } from "react";
import {
  FulfillmentWarningType,
  FulfillmentWarnings,
} from "../../../../generated/graphql";
import WarningBanner from "../../WarningBanner";

const DisplayFulfillmentWarnings: FC<{
  fulfillmentWarnings: FulfillmentWarnings[] | undefined;
}> = ({ fulfillmentWarnings }) => {
  return (
    <Box>
      {fulfillmentWarnings?.map((fulfillmentWarning, i) => {
        let message = `"${fulfillmentWarning.title}"`;
        if (fulfillmentWarning.type === FulfillmentWarningType.Prescription) {
          message =
            message +
            ` is out of your prescription. Are you sure you want to continue placing order?`;
        } else {
          message =
            fulfillmentWarning.type ===
              FulfillmentWarningType.CartMaximumDosage ||
            fulfillmentWarning.type ===
              FulfillmentWarningType.CartMaximumDosageSplit
              ? message + ` in your cart, `
              : message + ``;

          message =
            message +
            ` exceeds your maximum prescribed dosage. Are you sure you want to continue placing order?`;

          message =
            fulfillmentWarning.type ===
              FulfillmentWarningType.CartMaximumDosageSplit ||
            fulfillmentWarning.type ===
              FulfillmentWarningType.PendingMaximumDosageSplit
              ? message + ` Your order will be split.`
              : message + ``;
        }
        return <WarningBanner type="warning" message={message} />;
      })}
    </Box>
  );
};

export default DisplayFulfillmentWarnings;
