import { ArrowForwardIcon } from "@chakra-ui/icons";
import { Button, Flex, Spacer, Text } from "@chakra-ui/react";
import { FC, ReactNode } from "react";
import { useNavigate } from "react-router-dom";

const PreviewContainer: FC<{
  type: "dispensary" | "orders" | "appointments";
  children: ReactNode | ReactNode[];
}> = ({ type, children }) => {
  const navigate = useNavigate();

  return (
    <Flex
      direction="column"
      borderWidth={{
        base: type === "dispensary" ? 0 : "",
        xl: type === "dispensary" ? "1px" : "",
      }}
      borderRadius="2xl"
      pt={{ base: 0, lg: type === "dispensary" ? 5 : "" }}
      maxH={{
        base: type === "dispensary" ? "40%" : 52,
        lg: type === "dispensary" ? "container.sm" : 72,
      }}
      w="full"
    >
      <Flex
        alignItems="center"
        px={{ base: 0, xl: type === "dispensary" ? 5 : 0 }}
      >
        <Text as="h5" textAlign="left" fontWeight="medium">
          {(type === "orders" ? "My " : "") +
            type.charAt(0).toUpperCase() +
            type.substring(1, type.length) +
            " "}
        </Text>
        <Spacer />
        <Button
          ml="2"
          py="5"
          px="5"
          fontSize="xs"
          variant="primaryDarkOutline"
          onClick={() => navigate(`/patient/${type}`)}
        >
          {type === "dispensary" ? "View Dispensary" : "View All"}
          <ArrowForwardIcon ml="2" width="4" height="4" />
        </Button>
      </Flex>
      {children}
    </Flex>
  );
};

export default PreviewContainer;
