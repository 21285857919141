import { Box, Button, Flex, Text } from "@chakra-ui/react";

import { ChevronLeftIcon } from "@chakra-ui/icons";
import { FC } from "react";
import "swiper/css";

const SelectAppointmentCalendar: FC<{
  calendars: any;
  selectedCategory: any;
  selectedAppointmentType: any;
  selectedCalendar: any;
  selectedTime: any;
  updateSelectedCalendar: any;
  updateSelectedTime: any;
}> = ({
  calendars,
  selectedCategory,
  selectedAppointmentType,
  selectedCalendar,
  selectedTime,
  updateSelectedCalendar,
  updateSelectedTime,
}) => {
  return (
    <Box>
      {selectedCategory &&
        selectedAppointmentType &&
        !selectedCalendar &&
        !selectedTime && (
          <Box mt="8">
            <Text textAlign="left" fontWeight="500">
              With...
            </Text>
            <Flex flexDir="column" gap="8" mt="5">
              <Flex
                onClick={() => updateSelectedCalendar({ name: "Any Doctor" })}
                cursor="pointer"
                flexDir={{
                  base: "column",
                  md: "row",
                }}
                py="8"
                px="10"
                rounded="20px"
                border="2px"
                borderColor="cc.greyScale.100"
                textAlign="left"
              >
                <Box my="auto">
                  <Text fontWeight="600">Any Doctor</Text>
                </Box>
                <Box
                  ml="auto"
                  mr={{ base: "auto", md: "0" }}
                  my="auto"
                  mt={{ base: "4", md: "auto" }}
                >
                  <Button
                    w="32"
                    mx="auto"
                    mt="auto"
                    variant="primaryDarkOutline"
                  >
                    Book Now
                  </Button>
                </Box>
              </Flex>
              {calendars.map((calendar: any, i: number) => {
                if (selectedAppointmentType.calendarIDs.includes(calendar.id))
                  return (
                    <Flex
                      key={i}
                      onClick={() => updateSelectedCalendar(calendar)}
                      cursor="pointer"
                      flexDir={{
                        base: "column",
                        md: "row",
                      }}
                      py="8"
                      px="10"
                      rounded="20px"
                      border="2px"
                      borderColor="cc.greyScale.100"
                      textAlign="left"
                    >
                      <Box my="auto">
                        <Text fontWeight="600">{calendar.name}</Text>
                      </Box>
                      <Box
                        ml="auto"
                        mr={{ base: "auto", md: "0" }}
                        my="auto"
                        mt={{ base: "4", md: "auto" }}
                      >
                        <Button
                          w="32"
                          mx="auto"
                          mt="auto"
                          variant="primaryDarkOutline"
                        >
                          Book Now
                        </Button>
                      </Box>
                    </Flex>
                  );
              })}
            </Flex>
          </Box>
        )}
      {selectedCalendar && selectedCategory && (
        <Box mt="8">
          <Text textAlign="left" fontWeight="500">
            With...
          </Text>
          <Flex flexDir="column" gap="8" mt="5">
            <Box
              cursor="pointer"
              flexDir={{
                base: "column",
                md: "row",
              }}
              py="8"
              px="10"
              rounded="20px"
              border="2px"
              borderColor="cc.greyScale.100"
              textAlign="left"
            >
              <Box my="auto">
                <Text fontWeight="600">{selectedCalendar.name}</Text>
              </Box>
              <Box ml="auto" mr={{ base: "auto", md: "0" }} my="auto" mt="4">
                <Button
                  ml="auto"
                  w="32"
                  mx="auto"
                  mt="auto"
                  variant="primaryDark"
                >
                  Selected
                </Button>
              </Box>
            </Box>
          </Flex>
        </Box>
      )}
      {selectedAppointmentType && selectedCategory && selectedCalendar && (
        <Box mt="5" mr="auto" w="20">
          <Button
            mr="auto"
            variant="primaryLightOutline"
            cursor="pointer"
            onClick={() => {
              updateSelectedCalendar(null);
              updateSelectedTime(undefined);
            }}
          >
            <ChevronLeftIcon boxSize="6" />
            View Doctors
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default SelectAppointmentCalendar;
