import { MedusaProductCategory } from "../../../generated/graphql";

function useIsProductThc(categories: MedusaProductCategory[]) {
  const productCategory = categories[0];
  if (
    productCategory.handle.toLowerCase().includes("thc") ||
    (productCategory.handle.toLowerCase().includes("flower") &&
      !productCategory.handle.toLowerCase().includes("cbd"))
  ) {
    return true;
  }
  return false;
}

export default useIsProductThc;
