import { Grid } from "@chakra-ui/react";
import { FC } from "react";
import { MedusaProduct } from "../../../generated/graphql";
import ProductPreview from "./product/ProductPreview";

const DisplayProducts: FC<{ products: MedusaProduct[] | undefined }> = ({
  products,
}) => {
  return (
    <Grid
      w="full"
      templateColumns={{ base: "repeat(2, 1fr)", xl: "repeat(3, 1fr)" }}
      gap={{ base: "4", xl: "10" }}
      flexWrap="wrap"
    >
      {products?.map((product, i) => {
        return <ProductPreview key={i} product={product} />;
      })}
    </Grid>
  );
};

export default DisplayProducts;
