import { Flex } from "@chakra-ui/react";
import { FC } from "react";

const ProductStatusLabel: FC<{
  isComingSoon: boolean;
  isDiscounted: boolean;
  isPreOrder: boolean;
}> = ({ isComingSoon, isDiscounted, isPreOrder }) => {
  const resolveStatus = () => {
    if (isPreOrder) {
      if (!isComingSoon) return "Pre Order";
      return "Coming Soon";
    } else if (isDiscounted) {
      if (!isComingSoon) return "Sale";
      return "Coming Soon";
    }
  };

  if (isComingSoon || isDiscounted || isPreOrder)
    return (
      <Flex
        zIndex="50"
        m={{ base: "3", sm: "4" }}
        position="absolute"
        backgroundColor={isComingSoon ? "cc.greyScale.200" : "cc.primary.500"}
        color="white"
        justifyContent="center"
        alignItems="center"
        px={{ base: "2", sm: "4" }}
        py={{ base: "0.5", sm: "1.5" }}
        rounded="xl"
        borderWidth="2px"
        borderColor={isComingSoon ? "#9B9BA6" : "#367ABF"}
        fontWeight="600"
      >
        {resolveStatus()}
        {/* {isPreOrder && !isComingSoon
        ? "Pre Order"
        : isComingSoon
        ? "Coming Soon"
        : "Sale"} */}
      </Flex>
    );

  return null;
};

export default ProductStatusLabel;
