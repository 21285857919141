import { Flex, Select, Text } from "@chakra-ui/react";
import { FC } from "react";
import { MedusaProductVariant } from "../../../../../../generated/graphql";

const VariantSelect: FC<{
  variants: MedusaProductVariant[];
  selectedVariantIndex: number;
  updateSelectedVariantIndex: React.Dispatch<React.SetStateAction<number>>;
}> = ({ variants, selectedVariantIndex, updateSelectedVariantIndex }) => {
  if (variants.length <= 1) return null;

  return (
    <Flex gap="3" alignItems="center">
      <Text fontWeight="600">
        Select strength
        <Text as="span" color="red">
          {" "}
          *
        </Text>
      </Text>
      <Select
        maxW="240"
        value={selectedVariantIndex}
        onChange={(e) => updateSelectedVariantIndex(parseInt(e.target.value))}
      >
        {variants.map((variant, i) => {
          return (
            <option key={i} value={i}>
              {variant.title}
            </option>
          );
        })}
      </Select>
    </Flex>
  );
};

export default VariantSelect;
