import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import {
  MedusaProduct,
  MedusaProductVariant,
} from "../../../../../../generated/graphql";
import { ReactComponent as BulkPurchaseFlag } from "../../../../../../images/bulk-purchase-flag.svg";
import { ReactComponent as CBDIcon } from "../../../../../../images/cbdIcon.svg";
import productPlaceholder from "../../../../../../images/productPlaceholder.png";
import { ReactComponent as SaleSplash } from "../../../../../../images/sale-splash.svg";
import { ReactComponent as THCIcon } from "../../../../../../images/thcIcon.svg";

const ProductImageSection: FC<{
  product: MedusaProduct;
  isCartReviewPage?: boolean;
  isProductThc: boolean;
  isDiscounted: boolean;
  getVariantPricing: (variant: MedusaProductVariant) => {
    calculatedPrice: number;
    originalPrice: number;
    discountPercentage: number;
    isDiscounted: boolean;
  };
}> = ({
  product,
  isCartReviewPage,
  isProductThc,
  isDiscounted,
  getVariantPricing,
}) => {
  const navigate = useNavigate();

  return (
    <Flex position="relative">
      <Image
        border="1px"
        rounded="2xl"
        borderColor="cc.greyScale.100"
        mb="5"
        objectFit="contain"
        // src={initialProduct.thumbnail}
        src={productPlaceholder}
        alt="product thumbnail"
        mx="auto"
        onClick={() => navigate(`/patient/dispensary/${product.handle}`)}
      />
      <Flex position="absolute" bottom="10" left="5">
        {isProductThc ? <THCIcon /> : <CBDIcon />}
      </Flex>
      {isDiscounted && isCartReviewPage && product.variants.length === 1 && (
        <Box position="absolute" bottom="5" right="0" color="white">
          <Text
            position="absolute"
            textAlign="center"
            right="7"
            top="3"
            lineHeight="1.2"
            fontWeight="semibold"
          >
            {getVariantPricing(product.variants[0]).discountPercentage}%
            <Text fontSize="sm" fontWeight="normal">
              off!
            </Text>
          </Text>
          <SaleSplash />
        </Box>
      )}
      {isCartReviewPage &&
        product.bulk_purchase &&
        product.bulk_discount_1 &&
        product.bulk_discount_2 &&
        product.variants[0].calculated_price && (
          <>
            <Flex position="absolute" top="-5" right="-5">
              <BulkPurchaseFlag />
            </Flex>
            <Flex
              position="absolute"
              direction="column"
              top="-4"
              right="5"
              textColor="white"
            >
              {[product.bulk_discount_1, product.bulk_discount_2].map(
                (productBulkDiscount, i) => {
                  if (product.variants[0].calculated_price)
                    return (
                      <Text fontSize="sm">
                        {i + 2} for
                        <Text as="span" fontWeight="medium">
                          {" "}
                          $
                          {(
                            (((100 - productBulkDiscount) / 100) *
                              parseInt(product.variants[0].calculated_price)) /
                            100
                          ).toFixed(2)}
                        </Text>
                      </Text>
                    );
                }
              )}
            </Flex>
          </>
        )}
    </Flex>
  );
};

export default ProductImageSection;
