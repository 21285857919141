import { Flex, Image, Text } from "@chakra-ui/react";
import { useCreatePaymentSessionMutation } from "../../../../../generated/graphql";
import applePay from "../../../../../images/apple-pay.png";
import bankTransfer from "../../../../../images/bank-transfer.png";
import googlePay from "../../../../../images/google-pay.png";
// import healthNow from "../../../images/health-now.png";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import mastercard from "../../../../../images/mastercard.png";
import visa from "../../../../../images/visa.png";
import MainLayout from "../../../../../layouts/MainLayout";

const paymentOptions = [
  {
    id: "windcave",
    label: "Debit / Credit Card",
    value: "card",
    logos: [visa, mastercard],
  },
  {
    id: "windcave",
    label: "Internet Banking",
    value: "bank",
    logos: [bankTransfer],
  },
  {
    id: "windcave",
    label: "Google Pay",
    value: "googlePay",
    logos: [googlePay],
  },
  { id: "windcave", label: "Apple Pay", value: "applePay", logos: [applePay] },
  // {
  //   id: "healthNow",
  //   label: "Health Now",
  //   value: "healthNow",
  //   logos: [healthNow],
  // },
];

const Payment = () => {
  const navigate = useNavigate();
  const [createPaymentSession] = useCreatePaymentSessionMutation({
    onCompleted: (res) => {
      console.log(res);
      window.location.href = res.createPaymentSession.payment_session.data.url;
    },
  });

  const onCreatePaymentSession = (providerId: string) => {
    createPaymentSession({
      variables: {
        input: {
          providerId,
        },
      },
    });
  };

  useEffect(() => {
    const step = localStorage.getItem("checkout_step");
    if (step !== "2" && step !== "3") {
      navigate("/patient/dispensary/cart");
    } else {
      localStorage.setItem("checkout_step", "3");
    }
  }, []);

  return (
    <MainLayout>
      <Text
        as="h2"
        textAlign="left"
        mb={{ base: 0, xl: 5 }}
        mt={{ base: 14, xl: 0 }}
      >
        Select Payment
      </Text>
      <Flex my="5" flexDir="column" gap="2" textAlign="left">
        {paymentOptions.map((paymentOption, i) => {
          return (
            <Flex
              key={i}
              py="6"
              px="5"
              borderWidth="1px"
              rounded="xl"
              alignItems="center"
              onClick={() => onCreatePaymentSession(paymentOption.id)}
              _hover={{
                bgColor: "cc.primary.500",
                cursor: "pointer",
                color: "white",
                fontWeight: "600",
                borderColor: "cc.primary.100",
              }}
            >
              <Text>{paymentOption.label}</Text>
              <Flex gap="2" ml="auto">
                {paymentOption.logos.map((logo, i) => {
                  return <Image key={i} src={logo} w="12" />;
                })}
              </Flex>
            </Flex>
          );
        })}
      </Flex>
    </MainLayout>
  );
};

export default Payment;
