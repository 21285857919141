import { Box, Flex } from "@chakra-ui/react";
import LoadingAnimation from "../../components/LoadingAnimation";
import DashboardPreviews from "../../features/patients/dashboard/DashboardPreviews";
import PatientGreeting from "../../features/patients/onboarding/PatientGreeting";
import { useUserQuery } from "../../generated/graphql";
import MainLayout from "../../layouts/MainLayout";

const Dashboard = () => {
  const { data: userData, loading } = useUserQuery();

  if (loading || !userData)
    return (
      <MainLayout>
        <LoadingAnimation />
      </MainLayout>
    );

  return (
    <MainLayout>
      <Flex
        flexDir={{ base: "column", xl: "row" }}
        gap={{ base: 5, xl: 7 }}
        minW="full"
      >
        {userData.user.firstName && (
          <PatientGreeting patientName={userData.user.firstName} />
        )}
        <Box
          maxH={{ base: "full", xl: "container.sm" }}
          pb="10"
          overflow="hidden"
        >
          <DashboardPreviews />
        </Box>
      </Flex>
    </MainLayout>
  );
};

export default Dashboard;
