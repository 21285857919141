import { Box, Flex, Text } from "@chakra-ui/react";
import LoadingAnimation from "../../../../../components/LoadingAnimation";
import {
  useAcuityAppointmentsQuery,
  usePatientQuery,
} from "../../../../../generated/graphql";
import useCalculateFollowUpDate from "../../../../../hooks/patient/dispensary/useCalculateFollowUpDate";
import ViewAppointments from "../../../booking/ViewAppointments";

const AppointmentsPreview = () => {
  const { data: patientData, loading } = usePatientQuery({});
  const followUpMessage = useCalculateFollowUpDate(
    patientData?.patient.followUpDate
  );

  const { data: upcomingAppointmentsData } = useAcuityAppointmentsQuery({
    variables: {
      input: {
        minDate: new Date(),
      },
    },
  });

  if (loading)
    return (
      <Box>
        <LoadingAnimation />
      </Box>
    );

  return (
    <Flex direction="column" gap="3" w="full">
      {followUpMessage && (
        <Text fontSize="sm" lineHeight="1.4" textAlign="left" color="cc.red">
          {followUpMessage}
        </Text>
      )}
      <ViewAppointments
        type="upcoming"
        appointments={upcomingAppointmentsData?.acuityAppointments}
      />
    </Flex>
  );
};

export default AppointmentsPreview;
