import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { FC } from "react";
import {
  ConfirmAcuityAppointmentInput,
  useConfirmAcuityAppointmentMutation,
  useNotificationsQuery,
} from "../../generated/graphql";

const NotificationModal: FC<{
  isOpen: boolean;
  onClose: () => void;
}> = ({ isOpen, onClose }) => {
  const { data, loading, refetch } = useNotificationsQuery();

  const [confirmAppointment, { data: confirmedAppointment }] =
    useConfirmAcuityAppointmentMutation();
  const onSubmit = ({ id }: ConfirmAcuityAppointmentInput) =>
    confirmAppointment({
      variables: {
        input: {
          id,
        },
      },
    });

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        mt="15vh"
        mx="5"
        maxHeight="70%"
        p={{ base: 2, xl: 3 }}
        rounded="2xl"
      >
        <ModalHeader fontSize="2xl" mt="3">
          Notifications
        </ModalHeader>
        <ModalCloseButton />

        <ModalBody overflowY="auto">
          <Text as="h6">Recently</Text>
          {data?.notifications &&
            data?.notifications.length > 1 &&
            data?.notifications.map((notification: any, i: number) => {
              const date = new Date(notification.createdAt);
              const current = new Date();
              current.setDate(current.getDate() - 5);

              // If the date is in the last 5 days its recent
              if (date > current) {
                return (
                  <Flex
                    key={i}
                    flexDir="column"
                    p="5"
                    my="3"
                    backgroundColor="#F6F6F8"
                    rounded="xl"
                  >
                    <Flex>
                      <Text fontWeight="600">{notification.description}</Text>
                      <Box
                        ml="auto"
                        backgroundColor="cc.primary.500"
                        w="3"
                        h="3"
                        rounded="full"
                        display={notification.isRead ? "none" : "block"}
                      />
                    </Flex>

                    <Text fontSize="sm" mt="2">
                      Received {date.toLocaleDateString("en-nz")}
                    </Text>
                    <Flex mt="2" gap="4">
                      <Button
                        variant="primaryDarkOutline"
                        p="5"
                        onClick={() => window.open(notification.link, "_blank")}
                      >
                        View
                      </Button>
                      {notification.appointment && (
                        <Button
                          variant="primaryDarkOutline"
                          p="5"
                          isDisabled={
                            notification.appointment.confirmed ||
                            confirmedAppointment?.confirmAcuityAppointment
                              ?.confirmed
                          }
                          onClick={() => {
                            onSubmit({ id: notification.appointment.id });
                          }}
                        >
                          Confirm Appointment
                        </Button>
                      )}
                    </Flex>
                  </Flex>
                );
              }
            })}
          <Text as="h6" mt="5">
            Previous
          </Text>
          {data?.notifications &&
            data?.notifications.length > 1 &&
            data?.notifications.map((notification: any, i: number) => {
              const date = new Date(notification.createdAt);
              const current = new Date();
              current.setDate(current.getDate() - 5);

              if (date <= current)
                return (
                  <Flex
                    key={i}
                    flexDir="column"
                    p="5"
                    my="3"
                    backgroundColor="#F6F6F8"
                    rounded="xl"
                  >
                    <Flex>
                      <Text fontWeight="600">{notification.description}</Text>
                      <Box
                        ml="auto"
                        backgroundColor="cc.primary.500"
                        w="3"
                        h="3"
                        rounded="full"
                        display={notification.isRead ? "none" : "block"}
                      />
                    </Flex>

                    <Text fontSize="sm" mt="2">
                      Received {date.toLocaleDateString("en-nz")}
                    </Text>
                    <Flex mt="2" gap="4">
                      <Button
                        variant="primaryDarkOutline"
                        p="5"
                        onClick={() => window.open(notification.link, "_blank")}
                      >
                        View
                      </Button>
                      {notification.appointment && (
                        <Button
                          variant="primaryDarkOutline"
                          p="5"
                          isDisabled={
                            notification.appointment.confirmed ||
                            confirmedAppointment?.confirmAcuityAppointment
                              ?.confirmed
                          }
                          onClick={() => {
                            onSubmit({ id: notification.appointment.id });
                          }}
                        >
                          Confirm Appointment
                        </Button>
                      )}
                    </Flex>
                  </Flex>
                );
            })}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default NotificationModal;
