import { ArrowForwardIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useApplyCouponMutation } from "../../../../../generated/graphql";
import { ReactComponent as TagIcon } from "../../../../../images/tag.svg";
import FreeShippingProgress from "./components/FreeShippingProgress";

const CartTotals: FC<{
  total: number | undefined;
  subtotal: number | undefined;
  refetch: any;
  couponCode: string | undefined;
}> = ({ total, subtotal, refetch, couponCode }) => {
  const navigate = useNavigate();
  const [couponApplied, setCouponApplied] = useState(false);
  const [couponInput, setCouponInput] = useState<string>(couponCode ?? "");

  const [applyCoupon] = useApplyCouponMutation({
    onCompleted: () => {
      setCouponApplied(true);
      refetch();
    },
    onError: (error: any) => {
      setCouponApplied(false);
      return alert(error);
    },
  });

  const onApplyCoupon = () => {
    if (!couponInput) return;
    applyCoupon({
      variables: {
        input: {
          couponCode: couponInput,
        },
      },
    });
  };

  useEffect(() => {
    if (couponCode) {
      setCouponInput(couponCode);
      setCouponApplied(true);
    }
  }, [couponCode]);

  return (
    <Flex flexDir="column" gap="5">
      <FreeShippingProgress total={total} />
      <Box
        borderWidth="1px"
        borderRadius="2xl"
        borderColor="cc.greyScale.100"
        p="4"
      >
        <InputGroup>
          <Input
            focusBorderColor="cc.greyScale.200 !important"
            value={couponInput}
            onChange={(e) =>
              setCouponInput(e.target.value.toUpperCase().replace(/\s/g, ""))
            }
            placeholder="Coupon Code"
            h={{ base: 12, xl: 14 }}
            borderRadius="2xl !important"
            borderColor="cc.greyScale.200 !important"
            isDisabled={couponApplied}
          />
          <InputRightElement top="0" bottom="0" my="auto" mx="1">
            <TagIcon />
          </InputRightElement>
        </InputGroup>
        <Button
          variant="primaryDark"
          w="full"
          p="5"
          fontSize={{ base: "xs", xl: "md" }}
          mt="4"
          isDisabled={!couponInput || couponApplied}
          onClick={() => onApplyCoupon()}
        >
          Apply Coupon
        </Button>
      </Box>

      <Flex flexDir="column" gap="1">
        {subtotal !== undefined && subtotal > 0 && (
          <Flex>
            <Text as="h6" color="cc.greyScale.200" textAlign="left">
              Subtotal
            </Text>

            <Text as="h6" ml="auto">
              ${subtotal && (subtotal / 100).toFixed(2)}{" "}
              <Text as="span" fontSize="sm" fontWeight="400">
                (includes{" "}
                <Text as="span" fontWeight="600">
                  ${subtotal && (subtotal / 11 / 100).toFixed(2)}{" "}
                </Text>
                Tax)
              </Text>
            </Text>
          </Flex>
        )}

        {couponCode && (
          <Flex ml="auto" alignItems="center" gap="2">
            <TagIcon width="10" />
            <Text color="cc.greyScale.200" fontSize="sm">
              {couponCode} (-$
              {total && subtotal && ((subtotal - total) / 100).toFixed(2)})
            </Text>
          </Flex>
        )}

        <Button
          variant="primaryDark"
          ml="auto"
          my="5"
          onClick={() => navigate("checkout")}
          isDisabled={!subtotal || !total}
        >
          Proceed to checkout
          <ArrowForwardIcon ml="3" w="5" h="5" />
        </Button>
      </Flex>
    </Flex>
  );
};

export default CartTotals;
