import { Text } from "@chakra-ui/react";
import InitialBookingForm from "../../../features/patients/booking/InitialBookingForm";
import {
  CreateInitialAcuityAppointmentInput,
  useCreateInitialAcuityAppointmentMutation,
} from "../../../generated/graphql";
import MainLayout from "../../../layouts/MainLayout";
import OnboardingLayout from "../../../layouts/OnboardingLayout";

const InitialBooking = () => {
  const [submit, { loading, error, data }] =
    useCreateInitialAcuityAppointmentMutation({
      onError: (error: any) => {
        alert(error);
      },
    });

  const onSubmit = ({
    selectedBookingTime,
    selectedAppointmentTypeId,
    basicInfo,
  }: CreateInitialAcuityAppointmentInput) =>
    submit({
      variables: {
        input: {
          selectedBookingTime,
          selectedAppointmentTypeId,
          basicInfo,
        },
      },
    });

  if (!loading && !error && data)
    return (
      <MainLayout hideNavbar={true}>
        Thanks for signing up and creating your first booking! Follow the link
        in your email to complete your sign up and fill in your pre-appointment
        patient information
      </MainLayout>
    );

  return (
    <OnboardingLayout>
      <Text as="h3">First time? Let's get you signed up</Text>
      <Text maxW="md" mx="auto" my="4" color="cc.greyScale.400">
        Please schedule your consultation below. If you have any questions or
        queries, please call us on 0800 223 645 (0800 CBD OIL).
      </Text>
      <InitialBookingForm onSubmit={onSubmit} />
    </OnboardingLayout>
  );
};

export default InitialBooking;
