import { Box } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import LoadingAnimation from "../../../components/LoadingAnimation";
import ProductDetails from "../../../features/patients/dispensary/product/ProductDetails";
import ProductFAQs from "../../../features/patients/dispensary/product/ProductFAQs";
import ProductSuggestions from "../../../features/patients/dispensary/product/ProductSuggestions";
import {
  useAlternativeProductsQuery,
  useProductQuery,
  useRecommendedProductsQuery,
} from "../../../generated/graphql";
import MainLayout from "../../../layouts/MainLayout";

const Product = () => {
  const { id } = useParams();

  const { data: productData, loading } = useProductQuery({
    variables: { input: { handle: id } },
  });
  const { data: alternativeProductData } = useAlternativeProductsQuery({
    variables: { input: { handle: id } },
  });
  const { data: recommendedProductData } = useRecommendedProductsQuery();

  if (loading) return <LoadingAnimation />;
  if (!productData) return <Box>404</Box>;

  return (
    <MainLayout>
      <ProductDetails product={productData.product} />
      <ProductSuggestions
        type="recommended"
        products={recommendedProductData?.recommendedProducts.products ?? []}
      />
      <ProductSuggestions
        type="alternative"
        products={alternativeProductData?.alternativeProducts.products ?? []}
      />
      <ProductFAQs />
    </MainLayout>
  );
};

export default Product;
