import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { Box, Divider, Flex, Text, useDisclosure } from "@chakra-ui/react";
import { FC } from "react";

const questions = [
  {
    title: "What areas do you deliver to?",
    content:
      "We deliver to all locations within New Zealand. We do not ship internationally.",
  },
  {
    title: "How long does it usually take for delivery after I place my order?",
    content:
      "Once you have confirmed your order and paid any outstanding fees that may apply, your order will be processed by our clinical team within 1-3 business days. 2) Your order will then be picked up from our Auckland-based pharmacy by NZ Post Courier. Typically, courier deliveries are made within 2-3 business days. However, delivery times may vary depending on your location (eg Auckland vs South Island rural). 3) Please remember – like any courier service, NZ Post courier delivery times can vary from time to time. If you need an order urgently, please let our team know.",
  },
  {
    title: "What are your delivery prices?",
    content: "Our up-to-date delivery prices can be found on our website here.",
  },
  {
    title: "Can I track my delivery?",
    content:
      "Yes, once your order has been dispatched, you will receive an email or text with tracking information. You can check your order’s delivery progress in your tracking link or at NZ Post.",
  },
  {
    title:
      "What measures are in place to ensure the safety and privacy of my delivered medication?",
    content:
      "Rest assured, all our packages are discreetly and securely packaged and sealed to ensure the privacy and integrity of your medication.",
  },
  {
    title: "What happens if I'm not at home when my delivery arrives?",
    content:
      "If you are not at home, NZ Post Couriers will leave a notice with instructions on how to schedule a redelivery or pick up your package from a nearby location.",
  },
  {
    title: "I have an Authority to Leave (ATL)/ Non-Signature Delivery",
    content:
      "To ensure the security of your order a driver will always attempt to gain a signature when a delivery is made unless an ‘authority to leave’ is active at your chosen delivery address or a ‘one-time use authority to leave’ is selected directly via NZ Post. If you have a permanent ‘authority to leave’ or agree to a ‘one-time use authority to leave’, you understand and agree that this gives the couriers permission to leave your order in question unattended in a safe place at the delivery address without obtaining a signature confirming delivery at the delivery location. In these circumstances, you understand and agree that by having an authority to leave or a one-time use authority to leave, we and the couriers are released of all responsibility and liability for the orders delivered and left unattended, and that this responsibility and liability transfers to you on delivery. Therefore, we do not replace or refund products that are scanned as ‘delivered’ by NZ Post.",
  },
  {
    title: "What if I live in an apartment?",
    content:
      "NZ Post Courier can deliver to apartments if they have a drop point in an open/accessible foyer. Please note that NZ Post Couriers do not/will not call individual customers on delivery, or wait for them to come down, or deliver to a person on the top floor. In apartments without an open or accessible foyer, you may need to speak to your building manager/body corporate and see if they can offer any solutions for courier drivers to your location. Or, you could change your delivery address to a close friend, family member, OR use NZ Post ‘Parcel Collect’ instead, find out more here.",
  },
  {
    title: "What about PO Boxes?",
    content:
      "Yes, NZ Post Couriers delivers to PO boxes and Private Bags. Please note, should your order be oversized, a collection card will be left inside your PO Box or Private Bag for you to be able to collect your order from your provider directly. Please ensure when collecting your parcel you bring your collection card or consignment number as well as your photo ID.",
  },
  {
    title:
      "Can I return or exchange my medication if I'm not satisfied with it?",
    content:
      "We hope that you will be delighted with your medicinal cannabis product. Because all products are medicines or controlled drugs, we are UNABLE to accept returns or refunds.",
  },
  {
    title: "What should I do if my medication is damaged during delivery?",
    content:
      "If your medication arrives damaged, please contact our customer service team immediately. We will guide you through the process of filing a claim and getting a replacement.",
  },
  {
    title: "How do I cancel or modify an order for delivery?",
    content:
      "To cancel or modify an order, please contact our customer service team as soon as possible. Please note that we may not be able to make changes once your order has been dispatched. Once your order has been dispatched, it is YOUR responsibility to coordinate with NZ Post Couriers directly on 0800 268 743 or you can request for re-direction via their website. Please make sure you have your tracking number.",
  },
];

const Question: FC<{ title: string; content: string }> = ({
  title,
  content,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box w="full" ml="auto" textAlign="left">
      <Flex
        cursor="pointer"
        alignItems="center"
        onClick={() => (isOpen ? onClose() : onOpen())}
      >
        <Text
          as="h5"
          fontSize={{ base: "sm", sm: "sm", md: "md", lg: "lg", xl: "lg" }}
          lineHeight={{ base: "1.5" }}
          color={isOpen ? "cc.primary.500" : "black"}
        >
          {title}
        </Text>
        <Box ml="auto">
          {isOpen ? (
            <ChevronUpIcon boxSize="6" />
          ) : (
            <ChevronDownIcon boxSize="6" />
          )}
        </Box>
      </Flex>
      <Divider my="3" borderWidth="1px" />
      {isOpen && (
        <Text
          fontSize={{ base: "sm", sm: "sm", md: "md", lg: "lg", xl: "lg" }}
          my="5"
        >
          {content}
        </Text>
      )}
    </Box>
  );
};

const ProductFAQs = () => {
  return (
    <Flex flexDir={{ base: "column", xl: "row" }} my="10" p="5">
      <Text as="h3" mr={{ base: 0, xl: "auto" }}>
        FAQs
      </Text>
      <Flex
        flexDir="column"
        gap="3"
        w={{ base: "full", xl: "50%" }}
        ml={{ base: 0, xl: "auto" }}
        mt={{ base: 10, xl: 2 }}
      >
        {questions.map((question, i) => {
          return (
            <Question
              key={i}
              title={question.title}
              content={question.content}
            />
          );
        })}
      </Flex>
    </Flex>
  );
};

export default ProductFAQs;
