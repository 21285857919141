import { ArrowForwardIcon } from "@chakra-ui/icons";
import { Box, Button, Text } from "@chakra-ui/react";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoadingAnimation from "../../components/LoadingAnimation";

import {
  SignupPatientInput,
  useSignupPatientMutation,
} from "../../generated/graphql";
import PatientBasicInfoForm from "../patients/onboarding/PatientBasicInfoForm";

const SignupForm: FC<{ type: "patient" | "doctor" | "staff" }> = ({ type }) => {
  const navigate = useNavigate();

  const isEmailValid =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gi;
  const [basicInfo, setBasicInfo] = useState<any>({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
  });

  const [submit, { loading, error, data }] = useSignupPatientMutation({
    onError: (error: any) => {
      alert(error);
    },
  });

  const onSubmit = ({ basicInfo }: SignupPatientInput) =>
    submit({
      variables: {
        input: {
          basicInfo,
        },
      },
    });

  if (!loading && !error && data)
    return (
      <Box>
        Thanks for signing up! Follow the link in your email to confirm your
        sign up!
      </Box>
    );

  if (loading)
    return (
      <Box>
        <LoadingAnimation />
      </Box>
    );

  return (
    <Box>
      <Text as="h2">
        {type.charAt(0).toUpperCase() + type.slice(1, type.length)} Signup
      </Text>
      <Box my="10" display="flex" flexDir="column" gap="6">
        <PatientBasicInfoForm
          basicInfo={basicInfo}
          updateBasicInfo={setBasicInfo}
        />
        <Button
          mx="auto"
          variant="primaryDark"
          type="submit"
          onClick={() => {
            onSubmit({ basicInfo });
          }}
          isDisabled={
            !basicInfo.firstName ||
            !basicInfo.lastName ||
            !basicInfo.phoneNumber ||
            !basicInfo.email ||
            !isEmailValid.test(basicInfo.email)
          }
          rightIcon={<ArrowForwardIcon />}
        >
          Sign Up
        </Button>
        <Text
          textAlign="center"
          fontSize="sm"
          onClick={() => navigate("/patient/login")}
          cursor="pointer"
          color="cc.greyScale.200"
        >
          Already have an account?{" "}
          <Text as="span" textDecoration="underline" color="cc.primary.500">
            Log In
          </Text>
        </Text>
      </Box>
    </Box>
  );
};

export default SignupForm;
