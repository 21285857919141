import { Box, Flex, Progress, Text } from "@chakra-ui/react";
import { FC, useEffect, useState } from "react";

const FreeShippingProgress: FC<{ total: number | undefined }> = ({ total }) => {
  const [progressValue, setProgressValue] = useState(0);

  useEffect(() => {
    if (!total) return;
    setProgressValue(total / 450);
  }, [total]);

  if (!total) return null;

  return (
    <Box
      borderWidth="1px"
      borderRadius="2xl"
      borderColor="cc.greyScale.100"
      p="4"
    >
      <Text fontSize={{ base: "sm", md: "sm", xl: "md" }}>
        {progressValue < 100
          ? `You are $${450 - total / 100} away from `
          : `You qualify for `}

        <Text as="span" fontWeight="600">
          Free Nz Express Shipping
        </Text>
      </Text>
      <Flex
        alignItems="center"
        gap="4"
        mt={{ base: 2, lg: 4 }}
        fontSize={{ base: "sm", md: "md", xl: "md" }}
      >
        $0
        <Progress value={progressValue} size="lg" w="full" rounded="xl" />
        $450
      </Flex>
    </Box>
  );
};

export default FreeShippingProgress;
