import { Box, Flex } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import LoadingAnimation from "../../../components/LoadingAnimation";
import DispensaryFilters from "../../../features/patients/dispensary/DispensaryFilters";
import DispensaryHeader from "../../../features/patients/dispensary/DispensaryHeader";
import DispensarySortOptions from "../../../features/patients/dispensary/DispensarySortOptions";
import DisplayProducts from "../../../features/patients/dispensary/DisplayProducts";
import { MedusaProduct, useProductsQuery } from "../../../generated/graphql";
import MainLayout from "../../../layouts/MainLayout";

const Dispensary = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [limit, setLimit] = useState(10);
  const [products, setProducts] = useState<MedusaProduct[]>();

  const [selectedCategory, setSelectedCategory] = useState<any>();
  const [selectedBrand, setSelectedBrand] = useState<any>();
  const [selectedType, setSelectedType] = useState<any>();

  const [orderFilter, setOrderFilter] = useState<{
    priceOrder: string;
    costOrder: string;
  }>({
    priceOrder: "none",
    costOrder: "none",
  });

  const {
    data: productData,
    loading,
    refetch,
  } = useProductsQuery({
    variables: {
      input: {
        limit,
        selectedCategoryIds: selectedCategory?.id ? [selectedCategory.id] : [],
        selectedBrandIds: selectedBrand?.id ? [selectedBrand?.id] : [],
        selectedTypeIds: selectedType?.id ? [selectedType?.id] : [],
        price:
          orderFilter.priceOrder !== "none"
            ? orderFilter.priceOrder
            : undefined,
        cost:
          orderFilter.costOrder !== "none" ? orderFilter.costOrder : undefined,
      },
    },
  });

  const handleScroll = () => {
    if (!ref.current) return;
    if (
      ref.current.getBoundingClientRect().bottom > window.innerHeight ||
      loading ||
      productData?.products.count === limit
    ) {
      return;
    }
    setLimit(limit * 2);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [loading]);

  useEffect(() => {
    if (!selectedCategory && !selectedBrand && !selectedType && !orderFilter)
      return;
    refetch();
  }, [selectedCategory, selectedBrand, selectedType, orderFilter]);

  useEffect(() => {
    if (productData?.products.products)
      setProducts(productData?.products.products);
  }, [productData?.products.products]);

  if (loading && !products)
    return (
      <MainLayout>
        <DispensaryHeader />
        <LoadingAnimation />
      </MainLayout>
    );

  return (
    <MainLayout>
      <DispensaryHeader />
      <DispensarySortOptions
        orderFilter={orderFilter}
        updateOrderFilter={setOrderFilter}
      />
      <Flex
        ref={ref}
        flexDir={{ base: "column", xl: "row" }}
        alignItems={{ base: "center", xl: "initial" }}
        mt={{ base: "3", xl: "7" }}
        mb="14"
        gap="10"
      >
        <Box w={{ base: "100%", xl: "40%" }}>
          <DispensaryFilters
            selectedCategory={selectedCategory}
            updateSelectedCategory={setSelectedCategory}
            selectedBrand={selectedBrand}
            updateSelectedBrand={setSelectedBrand}
            selectedType={selectedType}
            updateSelectedType={setSelectedType}
          />
        </Box>
        <DisplayProducts products={products} />
      </Flex>
    </MainLayout>
  );
};

export default Dispensary;
