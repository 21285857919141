import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { FC } from "react";
import {
  Maybe,
  MedusaProductVariant,
} from "../../../../../../generated/graphql";
import productPlaceholder from "../../../../../../images/productPlaceholder.png";

const ProductDiscountSelect: FC<{
  size?: "sm" | "lg";
  bulkPurchase: Maybe<boolean> | undefined;
  bulk_discount_1: Maybe<number> | undefined;
  bulk_discount_2: Maybe<number> | undefined;
  variants: MedusaProductVariant[];
  selectedVariantIndex: number;
  quantity: number;
  updateQuantity: (value: number) => void;
}> = ({
  bulkPurchase,
  bulk_discount_1,
  bulk_discount_2,
  variants,
  selectedVariantIndex,
  quantity,
  updateQuantity,
  size,
}) => {
  const isSizeSmall = size === "sm";
  if (!bulkPurchase || (bulkPurchase && (!bulk_discount_1 || !bulk_discount_2)))
    return null;

  return (
    <Flex gap={{ base: isSizeSmall ? 2 : 3, xl: isSizeSmall ? 2 : 5 }}>
      {[0, 1].map((i) => {
        const bulkPurchaseQuantity = i === 0 ? 2 : 3;
        let isOptionSelected;

        if (
          (bulkPurchaseQuantity === 2 && quantity === 2) ||
          (bulkPurchaseQuantity === 3 && quantity >= 3)
        )
          isOptionSelected = true;

        const discountPercentage = i === 0 ? bulk_discount_1 : bulk_discount_2;
        const purchaseQuantity = i === 0 ? 2 : 3;
        if (!discountPercentage) return null;

        const discountedPrice =
          variants[selectedVariantIndex].calculated_price &&
          (parseInt(variants[selectedVariantIndex].calculated_price ?? "0") /
            100) *
            0.01 *
            (100 - discountPercentage);

        return (
          <Flex
            key={i}
            flexDir={{ base: isSizeSmall ? "row" : "column", xl: "row" }}
            borderWidth="1.5px"
            backgroundColor={!isOptionSelected ? "white" : "cc.primary.100"}
            borderColor={!isOptionSelected ? "cc.greyScale.100" : "transparent"}
            rounded="2xl"
            cursor="pointer"
            onClick={() => updateQuantity(bulkPurchaseQuantity)}
            textAlign={{ base: isSizeSmall ? "left" : "center", xl: "left" }}
          >
            <Box position="relative">
              <Box
                position="absolute"
                top={isSizeSmall ? 2 : 3}
                right={isSizeSmall ? 2 : 3}
                backgroundColor="cc.primary.500"
                px={isSizeSmall ? "5px" : 2.5}
                py={isSizeSmall ? "4px" : 2}
                rounded="full"
                color="white"
                lineHeight={isSizeSmall ? 1.3 : "inherit"}
                fontSize={{
                  base: isSizeSmall ? "xs" : "sm",
                  xl: isSizeSmall ? "xs" : "md",
                }}
              >
                x
                <Text as="span" fontWeight="600">
                  {purchaseQuantity}
                </Text>
              </Box>
              <Image src={productPlaceholder} maxW={isSizeSmall ? 14 : 32} />
            </Box>
            {discountedPrice && (
              <Box
                mt={{ base: !isSizeSmall ? 0 : 2, xl: isSizeSmall ? 2 : 5 }}
                mb={{ base: !isSizeSmall ? 3 : 2.5, xl: isSizeSmall ? 2 : 5 }}
                pr={{ base: !isSizeSmall ? 5 : 0, xl: 10 }}
                pl={{ base: !isSizeSmall ? 5 : 0, xl: 0 }}
              >
                <Text
                  fontSize={{
                    base: isSizeSmall ? "sm" : "md",
                    xl: isSizeSmall ? "sm" : "lg",
                  }}
                  lineHeight={isSizeSmall ? 1.3 : "inherit"}
                  fontWeight="500"
                >
                  {discountPercentage}% off
                </Text>
                <Text
                  lineHeight={isSizeSmall ? 1.3 : "inherit"}
                  fontSize={{
                    base: isSizeSmall ? "xs" : "sm",
                    xl: isSizeSmall ? "xs" : "md",
                  }}
                  my={isSizeSmall ? 0 : 0.5}
                >
                  if you buy {purchaseQuantity} for $
                  {discountedPrice.toFixed(2)} each
                </Text>
              </Box>
            )}
          </Flex>
        );
      })}
    </Flex>
  );
};

export default ProductDiscountSelect;
