import { CloseIcon } from "@chakra-ui/icons";
import { Box } from "@chakra-ui/react";
import { FC } from "react";
import { MedusaLineItemInput } from "../../../../../../generated/graphql";

const RemoveButton: FC<{
  lineItem: MedusaLineItemInput;
  removeFromCart: (lineItem: MedusaLineItemInput) => void;
}> = ({ lineItem, removeFromCart }) => {
  return (
    <>
      <Box
        display={{ base: "block", lg: "none" }}
        position="relative"
        left="1.5"
        top="0"
      >
        <Box
          position="absolute"
          bgColor="cc.lightScale.400"
          rounded="full"
          py="2"
          px="1"
        >
          <CloseIcon
            h="2"
            cursor="pointer"
            color="black"
            onClick={() => removeFromCart({ id: lineItem.id })}
            display={{ base: "flex", lg: "none" }}
          />
        </Box>
      </Box>
      <CloseIcon
        my="auto"
        h="3"
        cursor="pointer"
        onClick={() => removeFromCart({ id: lineItem.id })}
        display={{ base: "none", lg: "flex" }}
      />
    </>
  );
};

export default RemoveButton;
