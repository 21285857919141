import {
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import { FC } from "react";

const ImageModal: FC<{
  imageSrc: string;
  isOpen: boolean;
  onClose: () => void;
}> = ({ imageSrc, isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent mt="15vh" mx="5" minW="38vw">
        <ModalCloseButton />
        <ModalBody overflowY="auto">
          <Image src={imageSrc} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ImageModal;
