import {
  Box,
  Button,
  Flex,
  Spacer,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { FC, ReactNode, useEffect, useRef } from "react";

const CheckoutTotalsContainer: FC<{
  total: number | undefined;
  children: ReactNode | ReactNode[];
}> = ({ total, children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!wrapperRef || !wrapperRef.current) return;

    function handleClickOutside(event: any) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        onClose();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <Box>
      <Flex
        display={{
          base: isOpen ? "none" : "flex",
          xl: "none",
        }}
        p="5"
        bgColor="#F4FBFC"
        alignItems="center"
      >
        <Text>Total Amount: ${total && (total / 100).toFixed(2)}</Text>
        <Spacer />
        <Button
          variant="primaryDarkOutline"
          onClick={onOpen}
          p="3"
          fontSize="sm"
        >
          Order Summary
        </Button>
      </Flex>
      <Box
        display={{
          base: isOpen ? "block" : "none",
          xl: "block",
        }}
        ref={wrapperRef}
      >
        {children}
      </Box>
    </Box>
  );
};

export default CheckoutTotalsContainer;
