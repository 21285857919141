import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { FC, ReactNode } from "react";
import { ReactComponent as CCLogo } from "../images/CannabisClinicLogo1.svg";
import PatientSplashImage from "../images/PatientLoginSplash.png";

interface AuthEntryLayoutInput {
  children: ReactNode | ReactNode[];
}

const AuthEntryLayout: FC<AuthEntryLayoutInput> = ({ children }) => {
  return (
    <Flex flexDir={{ base: "column", md: "column", lg: "row" }}>
      <Flex
        bg={{ base: "transparent", lg: "cc.lightScale.200" }}
        maxW={{ base: "100%", lg: "50%" }}
      >
        <Box
          position={{ lg: "absolute" }}
          mx={{ base: "auto", lg: "10%" }}
          zIndex="10"
          my="12%"
        >
          <CCLogo width="250" height="120" />
          <Box display={{ base: "none", lg: "block" }}>
            <Text as="h2">Natural.</Text>
            <Text as="h2">Holistic.</Text>
            <Text as="h2">Plant-Based.</Text>
          </Box>
        </Box>
        <Box display={{ base: "none", lg: "block" }}>
          <Image
            src={PatientSplashImage}
            boxSize="100vh"
            objectFit="cover"
            position="relative"
          />
        </Box>
      </Flex>

      <Flex flexDir="column" my="auto" mx="auto" w="md" px="5">
        {children}
      </Flex>
    </Flex>
  );
};

export default AuthEntryLayout;
