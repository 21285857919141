import { Flex, Image, Spacer, Text } from "@chakra-ui/react";
import { FC } from "react";
import { FulfillmentOrder } from "../../../generated/graphql";
import productPlaceholder from "../../../images/productPlaceholder.png";
import { formatOrderValue, orderFields } from "./DisplayOrder";

// Order preview doesn't show shipping number
orderFields.pop();
const previewOrderFields = orderFields;

const DisplayOrderPreview: FC<{ fulfillmentOrder: FulfillmentOrder }> = ({
  fulfillmentOrder,
}) => {
  return (
    <Flex
      bgColor="cc.lightScale.200"
      borderRadius="2xl"
      p="5"
      gap="5"
      fontSize="sm"
    >
      <Flex
        borderWidth="1px"
        borderRadius="xl"
        borderColor="cc.lightScale.400"
        alignItems="center"
        maxH="20"
      >
        <Image
          src={
            fulfillmentOrder.fulfillmentOrderItems[0].thumbnail ??
            productPlaceholder
          }
          w="2-"
          h="20"
          objectFit="cover"
        />
      </Flex>
      <Flex textAlign="left" direction="column" gap="2" w="full">
        {previewOrderFields.map((orderField, i) => {
          return (
            <>
              <Flex key={i}>
                <Text fontWeight="medium">
                  {orderField.label}
                  {i === 0 && ":"}
                </Text>
                {i >= 1 && <Spacer />}
                <Text
                  ml="1"
                  fontWeight="semibold"
                  color={
                    orderField.value === "status" &&
                    fulfillmentOrder[orderField.value].toLowerCase() !==
                      "complete"
                      ? "cc.greyScale.200"
                      : "black"
                  }
                >
                  {formatOrderValue(fulfillmentOrder, orderField.value)}
                </Text>
              </Flex>
            </>
          );
        })}
      </Flex>
    </Flex>
  );
};

export default DisplayOrderPreview;
