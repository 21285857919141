export const patientInfoFields = [
  {
    label: "NHI (Optional)",
    value: "nhi",
    placeholder: "Type your National Health Index here...",
  },
  {
    type: "select",
    label: "Patient's Ethnicity",
    value: "ethnicity",
    placeholder: "Choose Ethnicity",
    options: [
      {
        label: "European",
        value: "european",
      },
      {
        label: "Maori",
        value: "maori",
      },
      {
        label: "Pacifica",
        value: "pacifica",
      },
      {
        label: "Asian",
        value: "asian",
      },
      {
        label: "Middle Eastern",
        value: "middleEastern",
      },
      {
        label: "Other",
        value: "other",
      },
    ],
    required: true,
  },
  {
    type: "select",
    label: "Patient's Gender",
    value: "gender",
    placeholder: "Choose Gender",
    options: [
      {
        label: "Male",
        value: "M",
      },
      {
        label: "Female",
        value: "F",
      },
    ],
    required: true,
  },
  {
    type: "date",
    label: "Date of Birth",
    value: "dob",
    required: true,
  },
  {
    label: "Address",
    value: "streetAddress",
    placeholder: "Street Address",
    required: true,
  },
  {
    label: <br />,
    value: "suburb",
    placeholder: "Suburb",
  },
  {
    value: "region",
    placeholder: "Region",
    required: true,
  },
  {
    value: "city",
    placeholder: "City",
    required: true,
  },
  {
    value: "postCode",
    placeholder: "Post Code",
    required: true,
  },
];

export const medicalInformationFields = [
  {
    type: "select",
    label:
      "Please specify the chronic health condition you would like to discuss",
    value: "medicalCondition",
    placeholder: "Select Medical Condition",
    options: [
      {
        label: "Pain",
        value: "pain",
      },
      {
        label: "Sleep",
        value: "sleep",
      },
      {
        label: "Anxiety",
        value: "anxiety",
      },
      {
        label: "Mental Health",
        value: "mentalHealth",
      },
      {
        label: "Autoinflammatory or Autoimmune Disorder(s)",
        value: "autoinflammatoryOrAutoimmuneDisorder(s)",
      },
      {
        label: "Neurological Disorder(s)",
        value: "neurologicalDisorder(s)",
      },
      {
        label: "Digestion",
        value: "digestion",
      },
      {
        label: "Stress",
        value: "stress",
      },
      {
        label: "Cancer",
        value: "cancer",
      },
      {
        label: "Wellbeing/Lifestyle",
        value: "wellbeingLifestyle",
      },
      {
        label: "Other - space to write health condition not mentioned",
        value: "other",
      },
    ],
    required: true,
  },
  {
    type: "textarea",
    label: "Tell us a bit more of the symptoms associated with you condition",
    value: "symptoms",
    placeholder: "Type your message here...",
    required: true,
  },
  {
    type: "radio",
    label: "Do you smoke cigarettes?",
    value: "cigarettes",
    required: true,
  },
  {
    label: "How many standard drinks of alcohol do you consume per week?",
    value: "standardDrinks",
    placeholder: "Enter your amount...",
    required: true,
  },
  {
    type: "radio",
    label: "Do you have any allergies?",
    value: "allergies",
    required: true,
  },
  {
    type: "radio",
    label: "Do you have a history of cardiovascular disease?",
    value: "cardiovascularDisease",
    required: true,
  },
  {
    type: "radio",
    label: "Do you have a history of abnormal liver function?",
    value: "abnormalLiver",
    required: true,
  },
  {
    type: "radio",
    label:
      "Do you have a history of psychosis or schizophrenia? (if yes, please forward your mental health services information as soon as possible.)",
    value: "psychosisOrSchizophrenia",
    required: true,
  },
  {
    type: "radio",
    label:
      "Do you have a history of bipolar? (if yes, please forward your mental health services information as soon as possible.)",
    value: "bipolar",
    required: true,
  },
  {
    type: "radio",
    label: "Do you have a history of substance abuse?",
    value: "substanceAbuse",
    required: true,
  },
  {
    type: "radio",
    label: "Do you have a history of self-medicating with cannabis?",
    value: "selfMedicationHistory",
    required: true,
  },
];
