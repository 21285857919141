import { Box, Flex, Text } from "@chakra-ui/react";
import { FC } from "react";
import { MedusaLineItem } from "../../../../generated/graphql";
import QuantitySelect from "../product/ProductDetails/components/QuantitySelect";

const CartItemDetails: FC<{
  lineItem: MedusaLineItem;
  updateQuantity: (value: number) => void;
}> = ({ lineItem, updateQuantity }) => {
  return (
    <Box display={{ base: "block", lg: "none" }}>
      <Text>Price: ${(lineItem.unit_price / 100).toFixed(2)}</Text>
      <Flex w="full">
        <Text mr="auto">
          Subtotal:{" "}
          <Text as="span" fontWeight="600">
            ${(lineItem.subtotal / 100).toFixed(2)}
          </Text>
        </Text>
        <Box w="26" mt="1" ml="auto">
          <QuantitySelect
            quantity={lineItem.quantity}
            updateQuantity={updateQuantity}
          />
        </Box>
      </Flex>
    </Box>
  );
};

export default CartItemDetails;
